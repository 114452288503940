import React from 'react'
import { gsap } from 'gsap'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'

const SVG = styled.svg`
  position: relative;
  width: 100%;
`
const HoverCircle = styled.g`
  opacity: 0;
`
const ImageContainer = styled.g`
  cursor: pointer;
`
const Heading = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  ${down('md')} {
    display: none;
  }
`
const InteractionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export default class extends React.Component {
  componentDidMount() {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 1 })
    tl.to(
      '.image-container .pulse',
      {
        duration: 0.5,
        scale: 20,
        autoAlpha: 0,
        transformOrigin: '50% 50%',
        ease: 'power1',
      },
      '0'
    )
    tl.to(
      '.image-container .pulse',
      {
        duration: 0.001,
        scale: 1,
        autoAlpha: 1,
        transformOrigin: '50% 50%',
        ease: 'power1',
      },
      '+=0'
    )
    return tl
  }
  topMouseIn() {
    gsap.to('.top-hover', { duration: 0.3, autoAlpha: 1, ease: 'power1' })
  }
  topMouseOut() {
    gsap.to('.top-hover', { duration: 0.3, autoAlpha: 0, ease: 'power1' })
  }
  rightMouseIn() {
    gsap.to('.right-hover', { duration: 0.3, autoAlpha: 1, ease: 'power1' })
  }
  rightMouseOut() {
    gsap.to('.right-hover', { duration: 0.3, autoAlpha: 0, ease: 'power1' })
  }
  bottomMouseIn() {
    gsap.to('.bottom-hover', { duration: 0.3, autoAlpha: 1, ease: 'power1' })
  }
  bottomMouseOut() {
    gsap.to('.bottom-hover', { duration: 0.3, autoAlpha: 0, ease: 'power1' })
  }
  leftMouseIn() {
    gsap.to('.left-hover', { duration: 0.3, autoAlpha: 1, ease: 'power1' })
  }
  leftMouseOut() {
    gsap.to('.left-hover', { duration: 0.3, autoAlpha: 0, ease: 'power1' })
  }
  render() {
    return (
      <InteractionContainer>
        <Heading>I expect my bank to...</Heading>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 691.59 554.41"
        >
          <defs>
            <mask
              id="mask"
              x="183.48"
              y="105.36"
              width="423.99"
              height="283.47"
              maskUnits="userSpaceOnUse"
            >
              <g id="mask-2">
                <path
                  id="path-1"
                  d="M349.53,137.07h.94A125.88,125.88,0,0,1,476.35,263h0A125.88,125.88,0,0,1,350.47,388.83h-.94A125.88,125.88,0,0,1,223.65,263h0A125.88,125.88,0,0,1,349.53,137.07Z"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </mask>
            <mask
              id="mask-2-2"
              x="78.9"
              y="211.7"
              width="126.52"
              height="84.52"
              maskUnits="userSpaceOnUse"
            >
              <g id="mask-6">
                <path
                  id="path-5"
                  d="M162.27,214.85h.45a39.79,39.79,0,0,1,39.79,39.79h0a39.79,39.79,0,0,1-39.79,39.8h-.45a39.79,39.79,0,0,1-39.79-39.8h0A39.79,39.79,0,0,1,162.27,214.85Z"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </mask>
            <mask
              id="mask-3"
              x="295.15"
              y="372.64"
              width="127.68"
              height="127.68"
              maskUnits="userSpaceOnUse"
            >
              <g id="mask-8">
                <path
                  id="path-7"
                  d="M350.23,410.86h.44a39.8,39.8,0,0,1,39.8,39.79h0a39.8,39.8,0,0,1-39.8,39.8h-.44a39.8,39.8,0,0,1-39.8-39.8h0A39.8,39.8,0,0,1,350.23,410.86Z"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </mask>
            <mask
              id="mask-4"
              x="456.48"
              y="209.49"
              width="127.87"
              height="85.42"
              maskUnits="userSpaceOnUse"
            >
              <g id="mask-10">
                <path
                  id="path-9"
                  d="M538.18,215.3h.45a39.78,39.78,0,0,1,39.79,39.79h0a39.79,39.79,0,0,1-39.79,39.8h-.45a39.79,39.79,0,0,1-39.79-39.8h0A39.78,39.78,0,0,1,538.18,215.3Z"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </mask>
            <mask
              id="mask-5"
              x="298.37"
              y="27.38"
              width="136.53"
              height="91.2"
              maskUnits="userSpaceOnUse"
            >
              <g id="mask-4-2" data-name="mask-4">
                <path
                  id="path-3"
                  d="M350.23,35h.44a39.8,39.8,0,0,1,39.8,39.8h0a39.79,39.79,0,0,1-39.8,39.79h-.44a39.79,39.79,0,0,1-39.8-39.79h0A39.8,39.8,0,0,1,350.23,35Z"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </mask>
          </defs>
          <g mask="url(#mask)">
            <image
              id="hero-image"
              width="500"
              height="334"
              transform="translate(183.48 105.36) scale(0.85)"
              xlinkHref={require('./interaction-1-1.png')}
              style={{ isolation: 'isolate' }}
            />
          </g>
          <ImageContainer
            className="image-container container-left"
            onMouseEnter={() => this.leftMouseIn()}
            onMouseLeave={() => this.leftMouseOut()}
          >
            <g id="image">
              <g mask="url(#mask-2-2)">
                <image
                  id="anticipate-my-needs"
                  width="500"
                  height="334"
                  transform="translate(78.9 211.7) scale(0.25)"
                  xlinkHref={require('./interaction-1-2.png')}
                  style={{ isolation: 'isolate' }}
                />
              </g>
              <path
                d="M162.27,213.72h.45a40.92,40.92,0,0,1,40.92,40.92h0a40.93,40.93,0,0,1-40.92,40.93h-.45a40.93,40.93,0,0,1-40.92-40.93h0A40.92,40.92,0,0,1,162.27,213.72Z"
                fill="none"
                stroke="#fff"
                strokeWidth="2.25"
              />
            </g>
            <circle
              className="pulse"
              cx="204"
              cy="258"
              r="1"
              fill="#fff"
              opacity="1"
              style={{ isolation: 'isolate' }}
            />
            <g id="plus">
              <circle id="Oval" cx="204" cy="258" r="10.5" fill="#fff" />
              <path
                d="M203.34,257.17a.08.08,0,0,0,.08-.08V254a.07.07,0,0,1,.08-.08H205a.07.07,0,0,1,.08.08v3.11a.08.08,0,0,0,.08.08h3.12a.07.07,0,0,1,.08.08v1.44a.08.08,0,0,1-.08.08h-3.12a.07.07,0,0,0-.08.08v3.07a.07.07,0,0,1-.08.08H203.5a.07.07,0,0,1-.08-.08v-3.07a.07.07,0,0,0-.08-.08h-3a.08.08,0,0,1-.08-.08v-1.44a.07.07,0,0,1,.08-.08Z"
                fill="#8e74bf"
              />
            </g>
            <g id="copy-left">
              <g style={{ isolation: 'isolate' }}>
                <path
                  d="M20.56,240.91v4H18.22V233.55h4.44a5,5,0,0,1,2.25.47,3.41,3.41,0,0,1,1.5,1.33,3.77,3.77,0,0,1,.52,2A3.27,3.27,0,0,1,25.79,240a4.8,4.8,0,0,1-3.17,1Zm0-1.89h2.1a2,2,0,0,0,1.41-.44,1.58,1.58,0,0,0,.49-1.25,1.85,1.85,0,0,0-.49-1.35,1.81,1.81,0,0,0-1.36-.53H20.56Z"
                  fill="#fff"
                />
                <path
                  d="M33.14,238.59a5.11,5.11,0,0,0-.81-.07,1.64,1.64,0,0,0-1.68.87v5.53H28.39v-8.45h2.13l.07,1a2.09,2.09,0,0,1,1.88-1.17,2.63,2.63,0,0,1,.7.1Z"
                  fill="#fff"
                />
                <path
                  d="M33.59,240.62a4.89,4.89,0,0,1,.49-2.24,3.56,3.56,0,0,1,1.39-1.53,4.08,4.08,0,0,1,2.12-.54,3.83,3.83,0,0,1,2.79,1.05,4.25,4.25,0,0,1,1.21,2.84v.58a4.42,4.42,0,0,1-1.08,3.12,4.22,4.22,0,0,1-5.84,0,4.54,4.54,0,0,1-1.09-3.19Zm2.26.16a3.16,3.16,0,0,0,.45,1.84,1.5,1.5,0,0,0,1.3.64,1.48,1.48,0,0,0,1.28-.63,3.44,3.44,0,0,0,.46-2,3.14,3.14,0,0,0-.46-1.83,1.61,1.61,0,0,0-2.58,0A3.54,3.54,0,0,0,35.85,240.78Z"
                  fill="#fff"
                />
                <path
                  d="M47.89,244.92a2.63,2.63,0,0,1-.23-.76,2.71,2.71,0,0,1-2.13.92,3,3,0,0,1-2.06-.72,2.31,2.31,0,0,1-.81-1.81,2.4,2.4,0,0,1,1-2.07,5,5,0,0,1,2.88-.72h1v-.49a1.37,1.37,0,0,0-.3-.93,1.17,1.17,0,0,0-1-.36,1.34,1.34,0,0,0-.89.28.92.92,0,0,0-.33.75H42.84a2.23,2.23,0,0,1,.46-1.36,3,3,0,0,1,1.28-1,4.61,4.61,0,0,1,1.86-.36,3.66,3.66,0,0,1,2.48.79,2.75,2.75,0,0,1,.92,2.21V243a4,4,0,0,0,.33,1.82v.13ZM46,243.35a2,2,0,0,0,.93-.22,1.5,1.5,0,0,0,.62-.6v-1.45h-.84c-1.13,0-1.74.39-1.81,1.17v.13a.91.91,0,0,0,.3.7A1.17,1.17,0,0,0,46,243.35Z"
                  fill="#fff"
                />
                <path
                  d="M55.09,243.26a1.48,1.48,0,0,0,1-.35,1.18,1.18,0,0,0,.41-.91h2.11a2.88,2.88,0,0,1-.46,1.57,3.05,3.05,0,0,1-1.27,1.11,3.84,3.84,0,0,1-1.77.4,3.68,3.68,0,0,1-2.85-1.15,4.56,4.56,0,0,1-1.05-3.19v-.15a4.45,4.45,0,0,1,1-3.11,3.61,3.61,0,0,1,2.85-1.17,3.52,3.52,0,0,1,2.54.91,3.18,3.18,0,0,1,1,2.4H56.51a1.54,1.54,0,0,0-.41-1.07,1.49,1.49,0,0,0-2.22.16,3.29,3.29,0,0,0-.4,1.86v.24a3.34,3.34,0,0,0,.4,1.88A1.36,1.36,0,0,0,55.09,243.26Z"
                  fill="#fff"
                />
                <path
                  d="M62.62,234.39v2.08h1.44v1.66H62.62v4.21a1,1,0,0,0,.18.68.92.92,0,0,0,.68.2,3.33,3.33,0,0,0,.67-.06v1.72a4.83,4.83,0,0,1-1.37.2,2.16,2.16,0,0,1-2.42-2.4v-4.55H59.12v-1.66h1.24v-2.08Z"
                  fill="#fff"
                />
                <path
                  d="M65.3,234.28a1.11,1.11,0,0,1,.34-.83,1.29,1.29,0,0,1,.93-.33,1.26,1.26,0,0,1,.92.33,1.08,1.08,0,0,1,.35.83,1.12,1.12,0,0,1-.35.85,1.48,1.48,0,0,1-1.84,0A1.12,1.12,0,0,1,65.3,234.28Zm2.4,10.64H65.44v-8.45H67.7Z"
                  fill="#fff"
                />
                <path
                  d="M72.73,242.16l1.57-5.69h2.36l-2.86,8.45H71.65l-2.85-8.45h2.36Z"
                  fill="#fff"
                />
                <path
                  d="M81.44,245.08a4.16,4.16,0,0,1-3-1.14,4.06,4.06,0,0,1-1.17-3v-.22a5.23,5.23,0,0,1,.49-2.28,3.61,3.61,0,0,1,1.4-1.54,3.85,3.85,0,0,1,2.06-.55,3.53,3.53,0,0,1,2.73,1.1,4.46,4.46,0,0,1,1,3.1v.92H79.53a2.07,2.07,0,0,0,.66,1.33,2,2,0,0,0,1.4.5,2.41,2.41,0,0,0,2-.95l1.11,1.25a3.39,3.39,0,0,1-1.37,1.12A4.64,4.64,0,0,1,81.44,245.08Zm-.26-7a1.39,1.39,0,0,0-1.09.46,2.36,2.36,0,0,0-.54,1.3H82.7v-.18a1.71,1.71,0,0,0-.41-1.16A1.44,1.44,0,0,0,81.18,238.13Z"
                  fill="#fff"
                />
                <path d="M88.58,244.92H86.31v-12h2.27Z" fill="#fff" />
                <path
                  d="M93.59,241.73l1.57-5.26h2.42l-3.4,9.76-.19.45a2.58,2.58,0,0,1-2.5,1.66,3.52,3.52,0,0,1-1-.15v-1.71h.35a1.85,1.85,0,0,0,1-.19,1.31,1.31,0,0,0,.5-.65l.26-.69-3-8.48H92Z"
                  fill="#fff"
                />
              </g>
              <g style={{ isolation: 'isolate' }}>
                <path
                  d="M28.35,254.39v2.08H29.8v1.66H28.35v4.21a1,1,0,0,0,.18.68.93.93,0,0,0,.69.2,3.23,3.23,0,0,0,.66-.06v1.72a4.81,4.81,0,0,1-1.36.2,2.16,2.16,0,0,1-2.43-2.4v-4.55H24.86v-1.66h1.23v-2.08Z"
                  fill="#fff"
                />
                <path
                  d="M36,264.92a2.37,2.37,0,0,1-.22-.76,2.75,2.75,0,0,1-2.14.92,3,3,0,0,1-2.05-.72,2.29,2.29,0,0,1-.82-1.81,2.4,2.4,0,0,1,1-2.07,5,5,0,0,1,2.88-.72h1v-.49a1.37,1.37,0,0,0-.3-.93,1.17,1.17,0,0,0-.95-.36,1.34,1.34,0,0,0-.89.28.92.92,0,0,0-.33.75H30.91a2.29,2.29,0,0,1,.45-1.36,3,3,0,0,1,1.28-1,4.61,4.61,0,0,1,1.86-.36,3.66,3.66,0,0,1,2.48.79,2.75,2.75,0,0,1,.92,2.21V263a4.1,4.1,0,0,0,.33,1.82v.13Zm-1.86-1.57a1.92,1.92,0,0,0,.92-.22,1.44,1.44,0,0,0,.62-.6v-1.45h-.84c-1.13,0-1.73.39-1.81,1.17v.13a.93.93,0,0,0,.29.7A1.18,1.18,0,0,0,34.09,263.35Z"
                  fill="#fff"
                />
                <path
                  d="M39.62,254.28a1.07,1.07,0,0,1,.34-.83,1.45,1.45,0,0,1,1.84,0,1.08,1.08,0,0,1,.35.83,1.12,1.12,0,0,1-.35.85,1.32,1.32,0,0,1-.92.32,1.34,1.34,0,0,1-.92-.32A1.12,1.12,0,0,1,39.62,254.28ZM42,264.92H39.75v-8.45H42Z"
                  fill="#fff"
                />
                <path d="M46.26,264.92H44v-12h2.27Z" fill="#fff" />
                <path
                  d="M47.77,260.62a5,5,0,0,1,.48-2.24,3.56,3.56,0,0,1,1.39-1.53,4.1,4.1,0,0,1,2.12-.54,3.83,3.83,0,0,1,2.79,1.05,4.25,4.25,0,0,1,1.21,2.84v.58a4.42,4.42,0,0,1-1.08,3.12,4.2,4.2,0,0,1-5.83,0,4.49,4.49,0,0,1-1.09-3.19Zm2.25.16a3.09,3.09,0,0,0,.46,1.84,1.62,1.62,0,0,0,2.57,0,3.43,3.43,0,0,0,.47-2,3.13,3.13,0,0,0-.47-1.83,1.49,1.49,0,0,0-1.29-.65,1.48,1.48,0,0,0-1.28.65A3.46,3.46,0,0,0,50,260.78Z"
                  fill="#fff"
                />
                <path
                  d="M61.91,258.59a4.93,4.93,0,0,0-.81-.07,1.66,1.66,0,0,0-1.68.87v5.53H57.16v-8.45H59.3l.06,1a2.09,2.09,0,0,1,1.88-1.17,2.71,2.71,0,0,1,.71.1Z"
                  fill="#fff"
                />
                <path
                  d="M70.14,261.73l1.56-5.26h2.42l-3.39,9.76-.19.45a2.59,2.59,0,0,1-2.5,1.66,3.52,3.52,0,0,1-1-.15v-1.71h.34a1.87,1.87,0,0,0,1-.19,1.31,1.31,0,0,0,.5-.65l.26-.69-3-8.48h2.43Z"
                  fill="#fff"
                />
                <path
                  d="M74.55,260.62a5,5,0,0,1,.48-2.24,3.52,3.52,0,0,1,1.4-1.53,4,4,0,0,1,2.11-.54,3.83,3.83,0,0,1,2.79,1.05,4.21,4.21,0,0,1,1.21,2.84v.58a4.42,4.42,0,0,1-1.08,3.12,4.2,4.2,0,0,1-5.83,0,4.49,4.49,0,0,1-1.09-3.19Zm2.25.16a3.16,3.16,0,0,0,.46,1.84,1.47,1.47,0,0,0,1.29.64,1.51,1.51,0,0,0,1.29-.63,3.52,3.52,0,0,0,.46-2,3.21,3.21,0,0,0-.46-1.83,1.51,1.51,0,0,0-1.3-.65,1.48,1.48,0,0,0-1.28.65A3.54,3.54,0,0,0,76.8,260.78Z"
                  fill="#fff"
                />
                <path
                  d="M89,264.06a2.83,2.83,0,0,1-2.31,1,2.69,2.69,0,0,1-2.08-.78,3.33,3.33,0,0,1-.73-2.29v-5.54h2.26v5.47q0,1.32,1.2,1.32a1.64,1.64,0,0,0,1.58-.8v-6H91.2v8.45H89.07Z"
                  fill="#fff"
                />
                <path
                  d="M97.65,258.59a5.11,5.11,0,0,0-.81-.07,1.64,1.64,0,0,0-1.68.87v5.53H92.9v-8.45H95l.06,1A2.1,2.1,0,0,1,97,256.31a2.63,2.63,0,0,1,.7.1Z"
                  fill="#fff"
                />
              </g>
              <g style={{ isolation: 'isolate' }}>
                <path
                  d="M6.23,284.92a2.37,2.37,0,0,1-.22-.76,2.74,2.74,0,0,1-2.13.92,3,3,0,0,1-2.06-.72A2.29,2.29,0,0,1,1,282.55a2.39,2.39,0,0,1,1-2.07,4.93,4.93,0,0,1,2.88-.72h1v-.49a1.37,1.37,0,0,0-.3-.93,1.16,1.16,0,0,0-1-.36,1.35,1.35,0,0,0-.89.28.91.91,0,0,0-.32.75H1.19a2.29,2.29,0,0,1,.45-1.36,3,3,0,0,1,1.28-1,4.61,4.61,0,0,1,1.86-.36,3.66,3.66,0,0,1,2.48.79,2.75,2.75,0,0,1,.92,2.21V283a4,4,0,0,0,.34,1.82v.13Zm-1.86-1.57a1.92,1.92,0,0,0,.92-.22,1.44,1.44,0,0,0,.62-.6v-1.45H5.07q-1.7,0-1.8,1.17v.13a.93.93,0,0,0,.29.7A1.18,1.18,0,0,0,4.37,283.35Z"
                  fill="#fff"
                />
                <path
                  d="M9.56,280.63a5.14,5.14,0,0,1,.89-3.15,2.88,2.88,0,0,1,2.43-1.17,2.58,2.58,0,0,1,2,.92v-4.31h2.27v12h-2L15,284a2.64,2.64,0,0,1-2.17,1.06,2.87,2.87,0,0,1-2.4-1.18A5.25,5.25,0,0,1,9.56,280.63Zm2.26.17a3.38,3.38,0,0,0,.41,1.82,1.35,1.35,0,0,0,1.21.64,1.5,1.5,0,0,0,1.47-.89V279a1.48,1.48,0,0,0-1.46-.88C12.36,278.15,11.82,279,11.82,280.8Z"
                  fill="#fff"
                />
                <path
                  d="M22.09,282.16l1.57-5.69H26l-2.85,8.45H21l-2.86-8.45h2.36Z"
                  fill="#fff"
                />
                <path
                  d="M27,274.28a1.07,1.07,0,0,1,.34-.83,1.46,1.46,0,0,1,1.85,0,1.07,1.07,0,0,1,.34.83,1.09,1.09,0,0,1-.35.85,1.29,1.29,0,0,1-.91.32,1.32,1.32,0,0,1-.92-.32A1.12,1.12,0,0,1,27,274.28Zm2.4,10.64H27.13v-8.45H29.4Z"
                  fill="#fff"
                />
                <path
                  d="M34.77,283.26a1.49,1.49,0,0,0,1-.35,1.22,1.22,0,0,0,.41-.91h2.11a2.81,2.81,0,0,1-.47,1.57,3,3,0,0,1-1.26,1.11,3.84,3.84,0,0,1-1.77.4A3.7,3.7,0,0,1,32,283.93a4.56,4.56,0,0,1-1-3.19v-.15a4.45,4.45,0,0,1,1-3.11,3.61,3.61,0,0,1,2.85-1.17,3.53,3.53,0,0,1,2.54.91,3.18,3.18,0,0,1,1,2.4H36.2a1.59,1.59,0,0,0-.41-1.07,1.35,1.35,0,0,0-1-.42,1.33,1.33,0,0,0-1.19.58,3.28,3.28,0,0,0-.41,1.86v.24a3.43,3.43,0,0,0,.4,1.88A1.38,1.38,0,0,0,34.77,283.26Z"
                  fill="#fff"
                />
                <path
                  d="M43.49,285.08a4.16,4.16,0,0,1-3-1.14,4.09,4.09,0,0,1-1.16-3v-.22a5.09,5.09,0,0,1,.49-2.28,3.59,3.59,0,0,1,1.39-1.54,3.87,3.87,0,0,1,2.06-.55,3.5,3.5,0,0,1,2.73,1.1,4.41,4.41,0,0,1,1,3.1v.92H41.59a2.07,2.07,0,0,0,.66,1.33,2,2,0,0,0,1.39.5,2.44,2.44,0,0,0,2-.95l1.11,1.25a3.49,3.49,0,0,1-1.38,1.12A4.58,4.58,0,0,1,43.49,285.08Zm-.26-6.95a1.41,1.41,0,0,0-1.09.46,2.43,2.43,0,0,0-.53,1.3h3.14v-.18a1.66,1.66,0,0,0-.41-1.16A1.44,1.44,0,0,0,43.23,278.13Z"
                  fill="#fff"
                />
              </g>
              <g style={{ isolation: 'isolate' }}>
                <path
                  d="M78.47,276.47l.07.94a3,3,0,0,1,2.43-1.1,2.25,2.25,0,0,1,2.24,1.29,2.9,2.9,0,0,1,2.54-1.29,2.54,2.54,0,0,1,2,.8,3.74,3.74,0,0,1,.67,2.41v5.4H86.2v-5.39a1.59,1.59,0,0,0-.28-1,1.25,1.25,0,0,0-1-.33,1.41,1.41,0,0,0-1.41,1v5.8H81.27v-5.38a1.54,1.54,0,0,0-.29-1.06,1.24,1.24,0,0,0-1-.33,1.45,1.45,0,0,0-1.39.8v6H76.35v-8.45Z"
                  fill="#fff"
                />
                <path
                  d="M94.09,285.08a4.12,4.12,0,0,1-3-1.14,4.06,4.06,0,0,1-1.17-3v-.22a5.09,5.09,0,0,1,.49-2.28,3.55,3.55,0,0,1,1.4-1.54,3.81,3.81,0,0,1,2-.55,3.5,3.5,0,0,1,2.73,1.1,4.41,4.41,0,0,1,1,3.1v.92H92.19a2.07,2.07,0,0,0,.66,1.33,2,2,0,0,0,1.39.5,2.44,2.44,0,0,0,2-.95l1.11,1.25A3.53,3.53,0,0,1,96,284.68,4.66,4.66,0,0,1,94.09,285.08Zm-.25-6.95a1.38,1.38,0,0,0-1.09.46,2.36,2.36,0,0,0-.54,1.3h3.14v-.18a1.66,1.66,0,0,0-.4-1.16A1.47,1.47,0,0,0,93.84,278.13Z"
                  fill="#fff"
                />
                <path
                  d="M52.85,284.92v-6.79H51.59v-1.66h1.26v-.72a2.91,2.91,0,0,1,.82-2.21,3.15,3.15,0,0,1,2.28-.78,5.41,5.41,0,0,1,1.15.15l0,1.75a3.34,3.34,0,0,0-.69-.07c-.85,0-1.27.4-1.27,1.2v.68H56.8v1.66H55.12v6.79Z"
                  fill="#fff"
                />
                <path
                  d="M57.62,280.62a5,5,0,0,1,.48-2.24,3.52,3.52,0,0,1,1.4-1.53,4,4,0,0,1,2.11-.54,3.83,3.83,0,0,1,2.79,1.05,4.21,4.21,0,0,1,1.21,2.84v.58a4.42,4.42,0,0,1-1.08,3.12,4.2,4.2,0,0,1-5.83,0,4.49,4.49,0,0,1-1.09-3.19Zm2.26.16a3.16,3.16,0,0,0,.45,1.84,1.47,1.47,0,0,0,1.29.64,1.51,1.51,0,0,0,1.29-.63,3.52,3.52,0,0,0,.46-2,3.21,3.21,0,0,0-.46-1.83,1.51,1.51,0,0,0-1.3-.65,1.48,1.48,0,0,0-1.28.65A3.54,3.54,0,0,0,59.88,280.78Z"
                  fill="#fff"
                />
                <path
                  d="M71.77,278.59a5.13,5.13,0,0,0-.82-.07,1.66,1.66,0,0,0-1.68.87v5.53H67v-8.45h2.13l.06,1a2.09,2.09,0,0,1,1.88-1.17,2.71,2.71,0,0,1,.71.1Z"
                  fill="#fff"
                />
              </g>
            </g>
          </ImageContainer>
          <ImageContainer
            className="image-container container-bottom"
            onMouseEnter={() => this.bottomMouseIn()}
            onMouseLeave={() => this.bottomMouseOut()}
          >
            <g id="image-2" data-name="image">
              <g mask="url(#mask-3)">
                <image
                  id="gettyimages-1132115579-170667a"
                  width="555"
                  height="555"
                  transform="translate(295.15 372.64) scale(0.23)"
                  xlinkHref={require('./interaction-1-3.png')}
                  style={{ isolation: 'isolate' }}
                />
              </g>
              <path
                d="M350.23,409.73h.44a40.92,40.92,0,0,1,40.92,40.92h0a40.93,40.93,0,0,1-40.92,40.93h-.44a40.93,40.93,0,0,1-40.92-40.93h0A40.92,40.92,0,0,1,350.23,409.73Z"
                fill="none"
                stroke="#fff"
                strokeWidth="2.25"
              />
            </g>
            <circle
              className="pulse"
              cx="350"
              cy="411"
              r="1"
              fill="#fff"
              opacity="1"
              style={{ isolation: 'isolate' }}
            />
            <g id="plus-2" data-name="plus">
              <circle
                id="Oval-2"
                data-name="Oval"
                cx="350"
                cy="411"
                r="10.5"
                fill="#fff"
              />
              <path
                d="M349.34,410.17a.08.08,0,0,0,.08-.08V407a.07.07,0,0,1,.08-.08H351a.07.07,0,0,1,.08.08v3.11a.08.08,0,0,0,.08.08h3.12a.07.07,0,0,1,.08.08v1.44a.08.08,0,0,1-.08.08h-3.12a.07.07,0,0,0-.08.08v3.07a.07.07,0,0,1-.08.08H349.5a.07.07,0,0,1-.08-.08v-3.07a.07.07,0,0,0-.08-.08h-3a.08.08,0,0,1-.08-.08v-1.44a.07.07,0,0,1,.08-.08Z"
                fill="#df5d47"
              />
            </g>
            <g id="copy-bottom">
              <text
                transform="translate(393.7 531)"
                fontSize="16"
                fill="#fff"
                fontFamily="Roboto-Bold, Roboto"
                fontWeight="700"
              >
                {' '}
              </text>
              <polygon
                points="317.78 531 320.13 531 320.13 519.63 317.06 519.63 314.15 527.88 311.23 519.63 308.16 519.63 308.16 531 310.51 531 310.51 527.89 310.27 522.53 313.34 531 314.94 531 318.02 522.52 317.78 527.89 317.78 531"
                fill="#fff"
              />
              <path
                d="M325.57,525.84a4.88,4.88,0,0,0-2.88.72,2.4,2.4,0,0,0-1,2.07,2.33,2.33,0,0,0,.81,1.81,3,3,0,0,0,2.06.72,2.71,2.71,0,0,0,2.13-.92,2.73,2.73,0,0,0,.23.76h2.28v-.13a4,4,0,0,1-.33-1.82v-3.67a2.75,2.75,0,0,0-.92-2.2,3.67,3.67,0,0,0-2.48-.79,4.61,4.61,0,0,0-1.86.36,2.86,2.86,0,0,0-1.28,1,2.21,2.21,0,0,0-.46,1.36h2.26a.94.94,0,0,1,.32-.75,1.33,1.33,0,0,1,.9-.28,1.2,1.2,0,0,1,.95.35,1.41,1.41,0,0,1,.3.94v.49Zm1,2.77a1.47,1.47,0,0,1-.63.6,1.9,1.9,0,0,1-.92.22,1.17,1.17,0,0,1-.81-.27.92.92,0,0,1-.3-.7v-.13c.07-.78.68-1.17,1.81-1.17h.84Z"
                fill="#fff"
              />
              <polygon
                points="338.18 522.55 335.47 522.55 333.31 525.09 332.87 525.65 332.87 519 330.61 519 330.61 531 332.87 531 332.87 528.42 333.68 527.61 335.85 531 338.44 531 335.13 526.07 338.18 522.55"
                fill="#fff"
              />
              <path
                d="M346.19,529.63l-1.11-1.24a2.44,2.44,0,0,1-2,1,2,2,0,0,1-1.4-.5,2.2,2.2,0,0,1-.66-1.33h5.39v-.92a4.44,4.44,0,0,0-1-3.11,3.52,3.52,0,0,0-2.73-1.09,3.93,3.93,0,0,0-2.06.54,3.67,3.67,0,0,0-1.39,1.55,5.09,5.09,0,0,0-.49,2.28V527a4.05,4.05,0,0,0,1.16,3,4.16,4.16,0,0,0,3,1.14,4.5,4.5,0,0,0,1.92-.41A3.33,3.33,0,0,0,346.19,529.63Zm-4.65-5a1.39,1.39,0,0,1,1.09-.46,1.47,1.47,0,0,1,1.11.41,1.71,1.71,0,0,1,.41,1.17V526H341A2.32,2.32,0,0,1,341.54,524.67Z"
                fill="#fff"
              />
              <path
                d="M351.48,525.09h2.26a.92.92,0,0,1,.33-.75,1.28,1.28,0,0,1,.89-.28,1.2,1.2,0,0,1,1,.35,1.41,1.41,0,0,1,.3.94v.49h-1a4.9,4.9,0,0,0-2.88.72,2.4,2.4,0,0,0-1,2.07,2.33,2.33,0,0,0,.81,1.81,3,3,0,0,0,2.06.72,2.71,2.71,0,0,0,2.13-.92,2.73,2.73,0,0,0,.23.76h2.28v-.13a4,4,0,0,1-.33-1.82v-3.67a2.75,2.75,0,0,0-.92-2.2,3.66,3.66,0,0,0-2.48-.79,4.61,4.61,0,0,0-1.86.36,2.91,2.91,0,0,0-1.28,1A2.28,2.28,0,0,0,351.48,525.09Zm4.73,3.52a1.44,1.44,0,0,1-.62.6,2,2,0,0,1-.93.22,1.17,1.17,0,0,1-.81-.27.92.92,0,0,1-.3-.7v-.13c.08-.78.68-1.17,1.81-1.17h.84Z"
                fill="#fff"
              />
              <rect x="360.33" y="519" width="2.27" height="12" fill="#fff" />
              <rect x="364.57" y="519" width="2.27" height="12" fill="#fff" />
              <path
                d="M379.86,531v-5.8a1.41,1.41,0,0,1,1.41-1,1.25,1.25,0,0,1,1,.33,1.59,1.59,0,0,1,.28,1.05V531h2.27v-5.4a3.68,3.68,0,0,0-.68-2.41,2.52,2.52,0,0,0-2-.8,2.9,2.9,0,0,0-2.54,1.29,2.26,2.26,0,0,0-2.24-1.29,3,3,0,0,0-2.43,1.1l-.07-.94h-2.12V531h2.26v-6a1.45,1.45,0,0,1,1.39-.79,1.24,1.24,0,0,1,1,.33,1.58,1.58,0,0,1,.29,1.06V531Z"
                fill="#fff"
              />
              <path
                d="M390.09,532.76l.18-.45,3.4-9.76h-2.42l-1.56,5.26-1.57-5.26h-2.43l3,8.47-.27.7a1.29,1.29,0,0,1-.49.65,1.87,1.87,0,0,1-1,.19h-.34v1.71a3.83,3.83,0,0,0,1,.14A2.58,2.58,0,0,0,390.09,532.76Z"
                fill="#fff"
              />
              <rect
                x="289.8"
                y="542.55"
                width="2.27"
                height="8.45"
                fill="#fff"
              />
              <path
                d="M290.93,539.2a1.29,1.29,0,0,0-.92.32,1.11,1.11,0,0,0-.34.84,1.08,1.08,0,0,0,.35.84,1.26,1.26,0,0,0,.91.33,1.28,1.28,0,0,0,.92-.33,1.12,1.12,0,0,0,.35-.84,1.15,1.15,0,0,0-.34-.84A1.33,1.33,0,0,0,290.93,539.2Z"
                fill="#fff"
              />
              <path
                d="M298.5,542.39a3,3,0,0,0-2.43,1.13l-.07-1h-2.12V551h2.26v-6a1.54,1.54,0,0,1,1.43-.81,1.44,1.44,0,0,1,1,.32,1.5,1.5,0,0,1,.31,1V551h2.26v-5.46a3.67,3.67,0,0,0-.68-2.36A2.43,2.43,0,0,0,298.5,542.39Z"
                fill="#fff"
              />
              <path
                d="M305.77,549.09a1,1,0,0,1-.18-.67V544.2H307v-1.65h-1.45v-2.08h-2.26v2.08H302.1v1.65h1.23v4.56a2.16,2.16,0,0,0,2.42,2.4,4.51,4.51,0,0,0,1.37-.21v-1.71a3.23,3.23,0,0,1-.66.06A.89.89,0,0,1,305.77,549.09Z"
                fill="#fff"
              />
              <path
                d="M311.93,542.39a3.9,3.9,0,0,0-2.05.54,3.63,3.63,0,0,0-1.4,1.55,5.09,5.09,0,0,0-.49,2.28V547a4.06,4.06,0,0,0,1.17,3,4.12,4.12,0,0,0,3,1.14,4.51,4.51,0,0,0,1.93-.41,3.36,3.36,0,0,0,1.37-1.12l-1.11-1.24a2.44,2.44,0,0,1-2,1,2,2,0,0,1-1.39-.5,2.1,2.1,0,0,1-.66-1.33h5.38v-.92a4.44,4.44,0,0,0-1-3.11A3.52,3.52,0,0,0,311.93,542.39Zm1.51,3.58H310.3a2.33,2.33,0,0,1,.54-1.3,1.38,1.38,0,0,1,1.09-.46,1.5,1.5,0,0,1,1.11.41,1.7,1.7,0,0,1,.4,1.17Z"
                fill="#fff"
              />
              <path
                d="M319.14,543.56l-.07-1h-2.13V551h2.26v-5.53a1.64,1.64,0,0,1,1.68-.87,6.44,6.44,0,0,1,.81.06l0-2.17a2.63,2.63,0,0,0-.7-.1A2.09,2.09,0,0,0,319.14,543.56Z"
                fill="#fff"
              />
              <path
                d="M329.39,545.38a2.75,2.75,0,0,0-.92-2.2,3.66,3.66,0,0,0-2.48-.79,4.61,4.61,0,0,0-1.86.36,2.91,2.91,0,0,0-1.28,1,2.28,2.28,0,0,0-.46,1.36h2.26a.92.92,0,0,1,.33-.75,1.28,1.28,0,0,1,.89-.28,1.2,1.2,0,0,1,.95.35,1.41,1.41,0,0,1,.3.94v.49h-1a4.9,4.9,0,0,0-2.88.72,2.4,2.4,0,0,0-1,2.07,2.33,2.33,0,0,0,.81,1.81,3,3,0,0,0,2.06.72,2.71,2.71,0,0,0,2.13-.92,2.73,2.73,0,0,0,.23.76h2.28v-.13a4,4,0,0,1-.33-1.82Zm-2.27,3.23a1.44,1.44,0,0,1-.62.6,2,2,0,0,1-.93.22,1.17,1.17,0,0,1-.81-.27.92.92,0,0,1-.3-.7v-.13c.08-.78.68-1.17,1.81-1.17h.84Z"
                fill="#fff"
              />
              <path
                d="M333.43,544.79a1.34,1.34,0,0,1,1.19-.58,1.36,1.36,0,0,1,1,.41,1.58,1.58,0,0,1,.41,1.08h2.12a3.22,3.22,0,0,0-1-2.41,3.55,3.55,0,0,0-2.54-.9,3.61,3.61,0,0,0-2.85,1.17,4.5,4.5,0,0,0-1,3.11v.15a4.52,4.52,0,0,0,1.05,3.18,3.66,3.66,0,0,0,2.86,1.16,4,4,0,0,0,1.77-.4,3.08,3.08,0,0,0,1.26-1.11,3,3,0,0,0,.47-1.57h-2.12a1.2,1.2,0,0,1-.41.91,1.48,1.48,0,0,1-1,.35,1.36,1.36,0,0,1-1.21-.57,3.37,3.37,0,0,1-.4-1.88v-.24A3.29,3.29,0,0,1,333.43,544.79Z"
                fill="#fff"
              />
              <path
                d="M342.35,549.09a1,1,0,0,1-.18-.67V544.2h1.44v-1.65h-1.44v-2.08h-2.26v2.08h-1.23v1.65h1.23v4.56a2.16,2.16,0,0,0,2.42,2.4,4.51,4.51,0,0,0,1.37-.21v-1.71a3.23,3.23,0,0,1-.66.06A.89.89,0,0,1,342.35,549.09Z"
                fill="#fff"
              />
              <path
                d="M346.12,539.2a1.29,1.29,0,0,0-.92.32,1.21,1.21,0,0,0,0,1.68,1.45,1.45,0,0,0,1.84,0,1.18,1.18,0,0,0,0-1.68A1.3,1.3,0,0,0,346.12,539.2Z"
                fill="#fff"
              />
              <rect
                x="344.99"
                y="542.55"
                width="2.27"
                height="8.45"
                fill="#fff"
              />
              <path
                d="M355.55,543.44a3.86,3.86,0,0,0-2.8-1.05,4.06,4.06,0,0,0-2.11.54,3.53,3.53,0,0,0-1.39,1.52,5,5,0,0,0-.49,2.25v.1a4.47,4.47,0,0,0,1.09,3.18,4.19,4.19,0,0,0,5.83,0,4.38,4.38,0,0,0,1.09-3.12l0-.58A4.18,4.18,0,0,0,355.55,543.44Zm-1.5,5.27a1.5,1.5,0,0,1-1.28.63,1.51,1.51,0,0,1-1.3-.64,3.19,3.19,0,0,1-.45-1.84,3.52,3.52,0,0,1,.45-2,1.48,1.48,0,0,1,1.28-.65,1.51,1.51,0,0,1,1.3.65,3.09,3.09,0,0,1,.46,1.83A3.46,3.46,0,0,1,354.05,548.71Z"
                fill="#fff"
              />
              <path
                d="M362.74,542.39a3,3,0,0,0-2.43,1.13l-.07-1h-2.13V551h2.26v-6a1.56,1.56,0,0,1,1.43-.81,1.42,1.42,0,0,1,1,.32,1.45,1.45,0,0,1,.31,1V551h2.26v-5.46a3.67,3.67,0,0,0-.67-2.36A2.45,2.45,0,0,0,362.74,542.39Z"
                fill="#fff"
              />
              <path
                d="M372.11,546.3a9.65,9.65,0,0,0-1.43-.38,3.75,3.75,0,0,1-1.2-.4.71.71,0,0,1-.35-.61.8.8,0,0,1,.31-.65,1.36,1.36,0,0,1,.88-.26,1.33,1.33,0,0,1,1,.32,1.07,1.07,0,0,1,.31.8h2.26a2.38,2.38,0,0,0-1-2,4.06,4.06,0,0,0-2.58-.76,3.73,3.73,0,0,0-2.44.76A2.31,2.31,0,0,0,367,545c0,1.29,1,2.14,3,2.57a4.43,4.43,0,0,1,1.32.42.74.74,0,0,1,.41.65.77.77,0,0,1-.33.65,1.66,1.66,0,0,1-1,.25,1.71,1.71,0,0,1-1.11-.33,1.16,1.16,0,0,1-.45-.92h-2.14a2.45,2.45,0,0,0,.47,1.43,3.2,3.2,0,0,0,1.29,1,4.52,4.52,0,0,0,1.89.38,4.24,4.24,0,0,0,2.56-.72,2.2,2.2,0,0,0,1-1.87A2.32,2.32,0,0,0,372.11,546.3Z"
                fill="#fff"
              />
              <path
                d="M383,542.39a4,4,0,0,0-2.06.54,3.67,3.67,0,0,0-1.39,1.55,5.09,5.09,0,0,0-.49,2.28V547a4.06,4.06,0,0,0,1.17,3,4.12,4.12,0,0,0,3,1.14,4.43,4.43,0,0,0,1.92-.41,3.33,3.33,0,0,0,1.38-1.12l-1.11-1.24a2.44,2.44,0,0,1-2,1,2,2,0,0,1-1.39-.5,2.1,2.1,0,0,1-.66-1.33h5.38v-.92a4.44,4.44,0,0,0-1-3.11A3.52,3.52,0,0,0,383,542.39Zm1.51,3.58h-3.14a2.33,2.33,0,0,1,.54-1.3,1.37,1.37,0,0,1,1.08-.46,1.47,1.47,0,0,1,1.11.41,1.71,1.71,0,0,1,.41,1.17Z"
                fill="#fff"
              />
              <path
                d="M394.82,545.38a2.74,2.74,0,0,0-.91-2.2,3.67,3.67,0,0,0-2.48-.79,4.61,4.61,0,0,0-1.86.36,2.86,2.86,0,0,0-1.28,1,2.21,2.21,0,0,0-.46,1.36h2.26a.94.94,0,0,1,.32-.75,1.33,1.33,0,0,1,.9-.28,1.2,1.2,0,0,1,.95.35,1.41,1.41,0,0,1,.3.94v.49h-1a4.86,4.86,0,0,0-2.88.72,2.41,2.41,0,0,0-1,2.07,2.34,2.34,0,0,0,.82,1.81,3,3,0,0,0,2.06.72,2.73,2.73,0,0,0,2.13-.92,2.44,2.44,0,0,0,.23.76h2.28v-.13a4.08,4.08,0,0,1-.34-1.82Zm-2.26,3.23a1.52,1.52,0,0,1-.63.6,1.92,1.92,0,0,1-.92.22,1.17,1.17,0,0,1-.81-.27.92.92,0,0,1-.3-.7v-.13q.1-1.17,1.8-1.17h.85Z"
                fill="#fff"
              />
              <path
                d="M401.54,546.3a9.48,9.48,0,0,0-1.42-.38,3.75,3.75,0,0,1-1.2-.4.69.69,0,0,1-.35-.61.8.8,0,0,1,.31-.65,1.36,1.36,0,0,1,.88-.26,1.34,1.34,0,0,1,1,.32,1.07,1.07,0,0,1,.31.8h2.26a2.37,2.37,0,0,0-1-2,4,4,0,0,0-2.57-.76,3.71,3.71,0,0,0-2.44.76,2.32,2.32,0,0,0-.94,1.87c0,1.29,1,2.14,3,2.57a4.64,4.64,0,0,1,1.32.42.74.74,0,0,1,.41.65.75.75,0,0,1-.34.65,1.64,1.64,0,0,1-1,.25,1.75,1.75,0,0,1-1.11-.33,1.18,1.18,0,0,1-.44-.92h-2.14a2.45,2.45,0,0,0,.47,1.43,3.2,3.2,0,0,0,1.29,1,4.52,4.52,0,0,0,1.89.38,4.26,4.26,0,0,0,2.56-.72,2.2,2.2,0,0,0,1-1.87A2.32,2.32,0,0,0,401.54,546.3Z"
                fill="#fff"
              />
              <path
                d="M407.94,547.81l-1.57-5.26h-2.43l3,8.47-.26.7a1.31,1.31,0,0,1-.5.65,1.87,1.87,0,0,1-1,.19h-.34v1.71a3.89,3.89,0,0,0,1,.14,2.58,2.58,0,0,0,2.5-1.65l.19-.45,3.4-9.76H409.5Z"
                fill="#fff"
              />
            </g>
          </ImageContainer>
          <ImageContainer
            className="image-container container-right"
            onMouseEnter={() => this.rightMouseIn()}
            onMouseLeave={() => this.rightMouseOut()}
          >
            <g id="image-3" data-name="image">
              <g mask="url(#mask-4)">
                <image
                  id="Hep-me-manage-my-finances"
                  width="500"
                  height="334"
                  transform="translate(456.48 209.49) scale(0.26)"
                  xlinkHref={require('./interaction-1-4.png')}
                  style={{ isolation: 'isolate' }}
                />
              </g>
              <path
                d="M538.18,214.17h.45a40.92,40.92,0,0,1,40.92,40.92h0A40.92,40.92,0,0,1,538.63,296h-.45a40.92,40.92,0,0,1-40.92-40.92h0A40.92,40.92,0,0,1,538.18,214.17Z"
                fill="none"
                stroke="#fff"
                strokeWidth="2.25"
              />
            </g>
            <circle
              className="pulse"
              cx="498"
              cy="255"
              r="1"
              fill="#fff"
              opacity="1"
              style={{ isolation: 'isolate' }}
            />
            <g id="plus-3" data-name="plus">
              <circle cx="498.27" cy="254.95" r="10.5" fill="#fff" />
              <path
                d="M497.34,254.17a.08.08,0,0,0,.08-.08V251a.07.07,0,0,1,.08-.08H499a.07.07,0,0,1,.08.08v3.11a.08.08,0,0,0,.08.08h3.12a.07.07,0,0,1,.08.08v1.44a.08.08,0,0,1-.08.08h-3.12a.07.07,0,0,0-.08.08v3.07a.07.07,0,0,1-.08.08H497.5a.07.07,0,0,1-.08-.08v-3.07a.07.07,0,0,0-.08-.08h-3a.08.08,0,0,1-.08-.08v-1.44a.07.07,0,0,1,.08-.08Z"
                fill="#82c074"
              />
            </g>
            <g id="copy-right">
              <text
                transform="translate(668.69 242)"
                fontSize="16"
                fill="#fff"
                fontFamily="Roboto-Bold, Roboto"
                fontWeight="700"
              >
                {' '}
              </text>
              <polygon
                points="612.36 237.13 616.93 237.13 616.93 242 619.27 242 619.27 230.63 616.93 230.63 616.93 235.23 612.36 235.23 612.36 230.63 610.02 230.63 610.02 242 612.36 242 612.36 237.13"
                fill="#fff"
              />
              <path
                d="M625.06,242.16a4.43,4.43,0,0,0,1.92-.41,3.33,3.33,0,0,0,1.38-1.12l-1.11-1.24a2.44,2.44,0,0,1-2,1,2,2,0,0,1-1.39-.5,2.1,2.1,0,0,1-.66-1.33h5.38v-.92a4.44,4.44,0,0,0-1-3.11,3.49,3.49,0,0,0-2.73-1.09,4,4,0,0,0-2.06.54,3.67,3.67,0,0,0-1.39,1.55,5.09,5.09,0,0,0-.49,2.28V238a4.08,4.08,0,0,0,1.17,3A4.12,4.12,0,0,0,625.06,242.16Zm-1.35-6.49a1.41,1.41,0,0,1,1.09-.46,1.47,1.47,0,0,1,1.11.41,1.71,1.71,0,0,1,.41,1.17V237h-3.14A2.4,2.4,0,0,1,623.71,235.67Z"
                fill="#fff"
              />
              <rect x="629.94" y="230" width="2.27" height="12" fill="#fff" />
              <path
                d="M636.32,241.27a2.66,2.66,0,0,0,2.07.89,2.87,2.87,0,0,0,2.4-1.18,5.08,5.08,0,0,0,.88-3.13v-.12a5.18,5.18,0,0,0-.87-3.18,2.84,2.84,0,0,0-2.42-1.16,2.68,2.68,0,0,0-2.15,1l-.07-.82h-2.1v11.7h2.26Zm0-5.23a1.48,1.48,0,0,1,1.46-.81,1.37,1.37,0,0,1,1.22.64,3.38,3.38,0,0,1,.41,1.82c0,1.76-.53,2.65-1.61,2.65a1.51,1.51,0,0,1-1.48-.84Z"
                fill="#fff"
              />
              <path
                d="M649.31,236a1.46,1.46,0,0,1,1.39-.79,1.31,1.31,0,0,1,1,.32,1.63,1.63,0,0,1,.29,1.07V242h2.25v-5.8a1.4,1.4,0,0,1,1.4-1,1.23,1.23,0,0,1,1,.33,1.59,1.59,0,0,1,.29,1.05V242h2.26v-5.4a3.69,3.69,0,0,0-.67-2.41,2.54,2.54,0,0,0-2-.8,2.91,2.91,0,0,0-2.54,1.29,2.24,2.24,0,0,0-2.24-1.29,3,3,0,0,0-2.43,1.1l-.07-.94h-2.12V242h2.26Z"
                fill="#fff"
              />
              <path
                d="M664.8,242.16a4.5,4.5,0,0,0,1.92-.41,3.3,3.3,0,0,0,1.37-1.12L667,239.39a2.44,2.44,0,0,1-2,1,2,2,0,0,1-1.4-.5,2.1,2.1,0,0,1-.66-1.33h5.38v-.92a4.48,4.48,0,0,0-1-3.11,3.52,3.52,0,0,0-2.73-1.09,3.93,3.93,0,0,0-2.06.54,3.7,3.7,0,0,0-1.4,1.55,5.23,5.23,0,0,0-.49,2.28V238a4.08,4.08,0,0,0,1.17,3A4.16,4.16,0,0,0,664.8,242.16Zm-1.35-6.49a1.39,1.39,0,0,1,1.09-.46,1.47,1.47,0,0,1,1.11.41,1.7,1.7,0,0,1,.4,1.17V237h-3.14A2.33,2.33,0,0,1,663.45,235.67Z"
                fill="#fff"
              />
              <path
                d="M622,262v-5.4a3.69,3.69,0,0,0-.67-2.41,2.53,2.53,0,0,0-2-.8,2.9,2.9,0,0,0-2.54,1.29,2.27,2.27,0,0,0-2.25-1.29,3,3,0,0,0-2.43,1.1l-.07-.94h-2.11V262h2.25v-6a1.48,1.48,0,0,1,1.4-.79,1.24,1.24,0,0,1,1,.33,1.58,1.58,0,0,1,.29,1.06V262h2.26v-5.8a1.41,1.41,0,0,1,1.41-1,1.25,1.25,0,0,1,1,.33,1.65,1.65,0,0,1,.28,1.05V262Z"
                fill="#fff"
              />
              <path
                d="M630.9,262v-.13a4.08,4.08,0,0,1-.34-1.82v-3.67a2.71,2.71,0,0,0-.92-2.2,3.64,3.64,0,0,0-2.48-.79,4.67,4.67,0,0,0-1.86.36,3,3,0,0,0-1.28,1,2.26,2.26,0,0,0-.45,1.36h2.26a.94.94,0,0,1,.32-.75,1.33,1.33,0,0,1,.9-.28,1.22,1.22,0,0,1,1,.35,1.41,1.41,0,0,1,.3.94v.49h-1a4.86,4.86,0,0,0-2.88.72,2.39,2.39,0,0,0-1,2.07,2.31,2.31,0,0,0,.82,1.81,3,3,0,0,0,2.06.72,2.74,2.74,0,0,0,2.13-.92,2.44,2.44,0,0,0,.23.76Zm-2.6-2.39a1.52,1.52,0,0,1-.63.6,1.92,1.92,0,0,1-.92.22,1.15,1.15,0,0,1-.81-.27.92.92,0,0,1-.3-.7v-.13q.11-1.17,1.8-1.17h.85Z"
                fill="#fff"
              />
              <path
                d="M639.55,262v-5.46a3.73,3.73,0,0,0-.67-2.36,2.45,2.45,0,0,0-2-.79,3,3,0,0,0-2.43,1.13l-.07-1h-2.13V262h2.26v-6a1.55,1.55,0,0,1,1.43-.81,1.44,1.44,0,0,1,1,.32,1.5,1.5,0,0,1,.31,1V262Z"
                fill="#fff"
              />
              <path
                d="M648.44,262v-.13a4.08,4.08,0,0,1-.34-1.82v-3.67a2.75,2.75,0,0,0-.92-2.2,3.66,3.66,0,0,0-2.48-.79,4.67,4.67,0,0,0-1.86.36,2.91,2.91,0,0,0-1.28,1,2.26,2.26,0,0,0-.45,1.36h2.26a.91.91,0,0,1,.32-.75,1.33,1.33,0,0,1,.9-.28,1.22,1.22,0,0,1,.95.35,1.41,1.41,0,0,1,.3.94v.49h-1a4.86,4.86,0,0,0-2.88.72,2.39,2.39,0,0,0-1,2.07,2.31,2.31,0,0,0,.82,1.81,3,3,0,0,0,2.06.72,2.74,2.74,0,0,0,2.13-.92,2.44,2.44,0,0,0,.23.76Zm-2.6-2.39a1.52,1.52,0,0,1-.63.6,1.92,1.92,0,0,1-.92.22,1.15,1.15,0,0,1-.81-.27.92.92,0,0,1-.3-.7v-.13q.1-1.17,1.8-1.17h.85Z"
                fill="#fff"
              />
              <path
                d="M654.47,263.09a2,2,0,0,1-1.4.48,2.66,2.66,0,0,1-2.05-.94L650,264a3.31,3.31,0,0,0,1.34,1,4.76,4.76,0,0,0,1.81.37,4.93,4.93,0,0,0,2.14-.43,3.26,3.26,0,0,0,1.42-1.25,3.58,3.58,0,0,0,.5-1.93v-8.17h-2l-.1.79a2.62,2.62,0,0,0-2.16-1,3,3,0,0,0-2.5,1.19,5,5,0,0,0-.92,3.13v.1a5,5,0,0,0,.94,3.16,3,3,0,0,0,2.46,1.19,2.62,2.62,0,0,0,2.06-.89v.46A1.8,1.8,0,0,1,654.47,263.09Zm-1-2.75a1.45,1.45,0,0,1-1.26-.66,3,3,0,0,1-.46-1.8,3.51,3.51,0,0,1,.46-2,1.49,1.49,0,0,1,1.28-.66A1.55,1.55,0,0,1,655,256v3.56A1.57,1.57,0,0,1,653.49,260.34Z"
                fill="#fff"
              />
              <path
                d="M666.16,260.63l-1.11-1.24a2.44,2.44,0,0,1-2,.95,2,2,0,0,1-1.4-.5,2.2,2.2,0,0,1-.66-1.33h5.39v-.92a4.49,4.49,0,0,0-1-3.11,3.52,3.52,0,0,0-2.73-1.09,3.93,3.93,0,0,0-2.06.54,3.61,3.61,0,0,0-1.39,1.55,5.09,5.09,0,0,0-.5,2.28V258a4.06,4.06,0,0,0,1.17,3,4.16,4.16,0,0,0,3,1.14,4.5,4.5,0,0,0,1.92-.41A3.33,3.33,0,0,0,666.16,260.63Zm-4.65-5a1.39,1.39,0,0,1,1.09-.46,1.47,1.47,0,0,1,1.11.41,1.71,1.71,0,0,1,.41,1.17V257H661A2.25,2.25,0,0,1,661.51,255.67Z"
                fill="#fff"
              />
              <path
                d="M681,253.39a2.9,2.9,0,0,0-2.54,1.29,2.25,2.25,0,0,0-2.24-1.29,3,3,0,0,0-2.43,1.1l-.07-.94H671.6V262h2.26v-6a1.46,1.46,0,0,1,1.39-.79,1.24,1.24,0,0,1,1,.33,1.53,1.53,0,0,1,.29,1.06V262h2.26v-5.8a1.41,1.41,0,0,1,1.41-1,1.25,1.25,0,0,1,1,.33,1.59,1.59,0,0,1,.28,1.05V262h2.27v-5.4a3.74,3.74,0,0,0-.67-2.41A2.54,2.54,0,0,0,681,253.39Z"
                fill="#fff"
              />
              <path
                d="M690.17,253.55l-1.56,5.26L687,253.55h-2.43l3,8.47-.27.7a1.29,1.29,0,0,1-.49.65,1.87,1.87,0,0,1-1,.19h-.34v1.71a3.89,3.89,0,0,0,1,.14,2.58,2.58,0,0,0,2.5-1.65l.19-.45,3.39-9.76Z"
                fill="#fff"
              />
              <path
                d="M611.3,270.62a3,3,0,0,0-.82,2.21v.72h-1.25v1.65h1.25V282h2.27v-6.8h1.68v-1.65h-1.68v-.68c0-.8.42-1.2,1.27-1.2a3.34,3.34,0,0,1,.69.07l0-1.75a5.41,5.41,0,0,0-1.14-.15A3.18,3.18,0,0,0,611.3,270.62Z"
                fill="#fff"
              />
              <rect
                x="615.72"
                y="273.55"
                width="2.27"
                height="8.45"
                fill="#fff"
              />
              <path
                d="M616.85,270.2a1.29,1.29,0,0,0-.92.32,1.21,1.21,0,0,0,0,1.68,1.3,1.3,0,0,0,.92.33,1.28,1.28,0,0,0,.92-.33,1.18,1.18,0,0,0,0-1.68A1.3,1.3,0,0,0,616.85,270.2Z"
                fill="#fff"
              />
              <path
                d="M624.42,273.39a3,3,0,0,0-2.43,1.13l-.07-1H619.8V282h2.25v-6a1.56,1.56,0,0,1,1.43-.81,1.42,1.42,0,0,1,1,.32,1.45,1.45,0,0,1,.32,1V282h2.25v-5.46a3.67,3.67,0,0,0-.67-2.36A2.44,2.44,0,0,0,624.42,273.39Z"
                fill="#fff"
              />
              <path
                d="M635.65,276.38a2.75,2.75,0,0,0-.92-2.2,3.66,3.66,0,0,0-2.48-.79,4.61,4.61,0,0,0-1.86.36,2.91,2.91,0,0,0-1.28,1,2.26,2.26,0,0,0-.45,1.36h2.25a.92.92,0,0,1,.33-.75,1.28,1.28,0,0,1,.89-.28,1.2,1.2,0,0,1,1,.35,1.41,1.41,0,0,1,.3.94v.49h-1a4.9,4.9,0,0,0-2.88.72,2.4,2.4,0,0,0-1,2.07,2.31,2.31,0,0,0,.82,1.81,3,3,0,0,0,2.05.72,2.75,2.75,0,0,0,2.14-.92,2.44,2.44,0,0,0,.22.76H636v-.13a4.1,4.1,0,0,1-.33-1.82Zm-2.27,3.23a1.44,1.44,0,0,1-.62.6,1.92,1.92,0,0,1-.92.22,1.18,1.18,0,0,1-.82-.27,1,1,0,0,1-.29-.7v-.13c.08-.78.68-1.17,1.81-1.17h.84Z"
                fill="#fff"
              />
              <path
                d="M642,273.39a3,3,0,0,0-2.43,1.13l-.07-1h-2.12V282h2.25v-6a1.56,1.56,0,0,1,1.43-.81,1.42,1.42,0,0,1,1,.32,1.45,1.45,0,0,1,.32,1V282h2.25v-5.46a3.67,3.67,0,0,0-.67-2.36A2.44,2.44,0,0,0,642,273.39Z"
                fill="#fff"
              />
              <path
                d="M648.65,275.79a1.35,1.35,0,0,1,1.19-.58,1.4,1.4,0,0,1,1,.41,1.63,1.63,0,0,1,.4,1.08h2.12a3.25,3.25,0,0,0-1-2.41,4.06,4.06,0,0,0-5.4.27,4.5,4.5,0,0,0-1,3.11v.15a4.52,4.52,0,0,0,1,3.18,3.67,3.67,0,0,0,2.86,1.16,4,4,0,0,0,1.77-.4,3.08,3.08,0,0,0,1.26-1.11,2.89,2.89,0,0,0,.47-1.57h-2.12a1.23,1.23,0,0,1-.4.91,1.5,1.5,0,0,1-1,.35,1.37,1.37,0,0,1-1.21-.57,3.37,3.37,0,0,1-.4-1.88v-.24A3.29,3.29,0,0,1,648.65,275.79Z"
                fill="#fff"
              />
              <path
                d="M658.33,273.39a3.93,3.93,0,0,0-2.06.54,3.61,3.61,0,0,0-1.39,1.55,5.09,5.09,0,0,0-.5,2.28V278a4.06,4.06,0,0,0,1.17,3,4.16,4.16,0,0,0,3,1.14,4.5,4.5,0,0,0,1.92-.41,3.33,3.33,0,0,0,1.38-1.12l-1.11-1.24a2.44,2.44,0,0,1-2,.95,2,2,0,0,1-1.4-.5,2.1,2.1,0,0,1-.66-1.33h5.38v-.92a4.48,4.48,0,0,0-1-3.11A3.55,3.55,0,0,0,658.33,273.39Zm1.51,3.58H656.7a2.25,2.25,0,0,1,.53-1.3,1.39,1.39,0,0,1,1.09-.46,1.47,1.47,0,0,1,1.11.41,1.71,1.71,0,0,1,.41,1.17Z"
                fill="#fff"
              />
              <path
                d="M668.32,277.3a9.83,9.83,0,0,0-1.42-.38,3.75,3.75,0,0,1-1.2-.4.7.7,0,0,1-.36-.61.81.81,0,0,1,.32-.65,1.64,1.64,0,0,1,1.86.06,1.11,1.11,0,0,1,.31.8h2.26a2.39,2.39,0,0,0-1-2,4,4,0,0,0-2.57-.76,3.71,3.71,0,0,0-2.44.76,2.29,2.29,0,0,0-.94,1.87c0,1.29,1,2.14,3,2.57a4.45,4.45,0,0,1,1.31.42.72.72,0,0,1,.41.65.77.77,0,0,1-.33.65,1.66,1.66,0,0,1-1,.25,1.75,1.75,0,0,1-1.11-.33,1.18,1.18,0,0,1-.44-.92h-2.14a2.38,2.38,0,0,0,.47,1.43,3.2,3.2,0,0,0,1.29,1,4.51,4.51,0,0,0,1.88.38,4.27,4.27,0,0,0,2.57-.72,2.2,2.2,0,0,0,1-1.87A2.33,2.33,0,0,0,668.32,277.3Z"
                fill="#fff"
              />
            </g>
          </ImageContainer>
          <ImageContainer
            className="image-container container-top"
            onMouseEnter={() => this.topMouseIn()}
            onMouseLeave={() => this.topMouseOut()}
          >
            <g id="image-4">
              <g mask="url(#mask-5)">
                <image
                  id="know-who-i-am"
                  width="500"
                  height="334"
                  transform="translate(298.37 27.38) scale(0.27)"
                  xlinkHref={require('./interaction-1-5.png')}
                  style={{ isolation: 'isolate' }}
                />
              </g>
              <path
                d="M350.23,33.9h.44a40.92,40.92,0,0,1,40.92,40.92h0a40.92,40.92,0,0,1-40.92,40.92h-.44a40.92,40.92,0,0,1-40.92-40.92h0A40.92,40.92,0,0,1,350.23,33.9Z"
                fill="none"
                stroke="#fff"
                strokeWidth="2.25"
              />
            </g>
            <circle
              className="pulse"
              cx="350.5"
              cy="116"
              r="1"
              fill="#fff"
              opacity="1"
              style={{ isolation: 'isolate' }}
            />
            <g id="plus-4">
              <circle
                id="Oval"
                data-name="Oval"
                cx="350.5"
                cy="116"
                r="10.5"
                fill="#fff"
              />
              <path
                d="M349.84,115.17a.08.08,0,0,0,.08-.08V112a.07.07,0,0,1,.08-.08h1.47a.07.07,0,0,1,.08.08v3.11a.08.08,0,0,0,.08.08h3.12a.07.07,0,0,1,.08.08v1.44a.08.08,0,0,1-.08.08h-3.12a.07.07,0,0,0-.08.08v3.07a.07.07,0,0,1-.08.08H350a.07.07,0,0,1-.08-.08v-3.07a.07.07,0,0,0-.08-.08h-3a.08.08,0,0,1-.08-.08v-1.44a.07.07,0,0,1,.08-.08Z"
                fill="#46d5a3"
              />
            </g>
            <g id="copy-top">
              <polygon
                points="307.17 0.63 304.29 0.63 301.39 4.37 300.36 5.78 300.36 0.63 298.02 0.63 298.02 12 300.36 12 300.36 8.75 301.58 7.44 304.5 12 307.29 12 303.13 5.68 307.17 0.63"
                fill="#fff"
              />
              <path
                d="M310.23,6a1.56,1.56,0,0,1,1.43-.81,1.38,1.38,0,0,1,1,.32,1.45,1.45,0,0,1,.32,1V12h2.25V6.54a3.67,3.67,0,0,0-.67-2.36,2.44,2.44,0,0,0-2-.79,3,3,0,0,0-2.43,1.13l-.07-1H308V12h2.25Z"
                fill="#fff"
              />
              <path
                d="M320.64,12.16A3.77,3.77,0,0,0,323.55,11a4.38,4.38,0,0,0,1.09-3.12l0-.58a4.18,4.18,0,0,0-1.2-2.84,3.84,3.84,0,0,0-2.8-1.05,4.06,4.06,0,0,0-2.11.54,3.53,3.53,0,0,0-1.39,1.52,5,5,0,0,0-.49,2.25v.1A4.51,4.51,0,0,0,317.72,11,3.78,3.78,0,0,0,320.64,12.16Zm-1.3-6.3a1.49,1.49,0,0,1,1.28-.65,1.51,1.51,0,0,1,1.3.65,3.09,3.09,0,0,1,.46,1.83,3.46,3.46,0,0,1-.46,2,1.5,1.5,0,0,1-1.28.63,1.53,1.53,0,0,1-1.3-.64,3.19,3.19,0,0,1-.45-1.84A3.52,3.52,0,0,1,319.34,5.86Z"
                fill="#fff"
              />
              <polygon
                points="329.42 12 331.02 6.68 332.63 12 334.52 12 336.67 3.55 334.49 3.55 333.38 8.93 331.84 3.55 330.21 3.55 328.66 8.92 327.56 3.55 325.38 3.55 327.54 12 329.42 12"
                fill="#fff"
              />
              <polygon
                points="352.41 3.55 350.23 3.55 349.13 8.93 347.59 3.55 345.95 3.55 344.41 8.92 343.31 3.55 341.13 3.55 343.28 12 345.16 12 346.77 6.68 348.37 12 350.26 12 352.41 3.55"
                fill="#fff"
              />
              <path
                d="M355.73,6a1.54,1.54,0,0,1,1.44-.76,1.34,1.34,0,0,1,1,.34,1.58,1.58,0,0,1,.31,1.08V12h2.26V6.59c0-2.13-.95-3.2-2.79-3.2a2.83,2.83,0,0,0-2.26,1.08V0h-2.25V12h2.25Z"
                fill="#fff"
              />
              <path
                d="M366.14,12.16A3.77,3.77,0,0,0,369.05,11a4.38,4.38,0,0,0,1.09-3.12l0-.58a4.18,4.18,0,0,0-1.2-2.84,3.84,3.84,0,0,0-2.8-1.05,4.06,4.06,0,0,0-2.11.54,3.53,3.53,0,0,0-1.39,1.52,5,5,0,0,0-.49,2.25v.1A4.51,4.51,0,0,0,363.22,11,3.78,3.78,0,0,0,366.14,12.16Zm-1.3-6.3a1.49,1.49,0,0,1,1.28-.65,1.51,1.51,0,0,1,1.3.65,3.09,3.09,0,0,1,.46,1.83,3.46,3.46,0,0,1-.46,2,1.5,1.5,0,0,1-1.28.63,1.53,1.53,0,0,1-1.3-.64,3.19,3.19,0,0,1-.45-1.84A3.52,3.52,0,0,1,364.84,5.86Z"
                fill="#fff"
              />
              <rect
                x="375.81"
                y="0.63"
                width="2.34"
                height="11.38"
                fill="#fff"
              />
              <path
                d="M387.7,6.84a4.9,4.9,0,0,0-2.88.72,2.4,2.4,0,0,0-1,2.06,2.33,2.33,0,0,0,.81,1.82,3,3,0,0,0,2.06.72,2.75,2.75,0,0,0,2.14-.92,2.44,2.44,0,0,0,.22.76h2.28v-.13a4,4,0,0,1-.33-1.82V6.38a2.73,2.73,0,0,0-.92-2.2,3.66,3.66,0,0,0-2.48-.79,4.61,4.61,0,0,0-1.86.36,2.91,2.91,0,0,0-1.28,1A2.26,2.26,0,0,0,384,6.09h2.25a.92.92,0,0,1,.33-.75,1.28,1.28,0,0,1,.89-.28,1.2,1.2,0,0,1,.95.35,1.41,1.41,0,0,1,.3.94v.49Zm1,2.77a1.44,1.44,0,0,1-.62.6,1.92,1.92,0,0,1-.92.22,1.18,1.18,0,0,1-.82-.27.91.91,0,0,1-.29-.7V9.33c.08-.78.68-1.17,1.81-1.17h.84Z"
                fill="#fff"
              />
              <path
                d="M397.38,5.55a1.63,1.63,0,0,1,.28,1.07V12h2.26V6.2a1.41,1.41,0,0,1,1.41-1,1.25,1.25,0,0,1,1,.33,1.59,1.59,0,0,1,.28,1.05V12h2.27V6.6a3.74,3.74,0,0,0-.67-2.41,2.54,2.54,0,0,0-2.05-.8,2.91,2.91,0,0,0-2.54,1.29,2.24,2.24,0,0,0-2.24-1.29,3,3,0,0,0-2.43,1.1l-.07-.94h-2.12V12H395V6a1.46,1.46,0,0,1,1.39-.79A1.32,1.32,0,0,1,397.38,5.55Z"
                fill="#fff"
              />
            </g>
          </ImageContainer>
          <HoverCircle className="hover-circle top-hover">
            <circle
              id="top-hover"
              cx="349.76"
              cy="263.58"
              r="126.93"
              fill="#fff"
            />
            <g id="top-copy">
              <path
                d="M296.47,212.8l-1.37,1.45V218H293V205.93h2.1v5.67l1.17-1.43,3.53-4.24h2.54l-4.49,5.36L302.6,218h-2.49Z"
                fill="#46d5a3"
              />
              <path
                d="M305.4,209l.05,1a3.23,3.23,0,0,1,2.62-1.21q2.81,0,2.85,3.21V218h-2V212.2a1.91,1.91,0,0,0-.37-1.27,1.58,1.58,0,0,0-1.21-.41,2,2,0,0,0-1.82,1.11V218h-2v-9Z"
                fill="#46d5a3"
              />
              <path
                d="M312.6,213.45a5.4,5.4,0,0,1,.52-2.38,3.82,3.82,0,0,1,1.47-1.63,4.15,4.15,0,0,1,2.18-.57,3.93,3.93,0,0,1,3,1.17,4.63,4.63,0,0,1,1.22,3.11v.47a5.32,5.32,0,0,1-.51,2.37,3.77,3.77,0,0,1-1.46,1.62,4.2,4.2,0,0,1-2.2.58,3.92,3.92,0,0,1-3-1.27,4.85,4.85,0,0,1-1.14-3.38Zm2,.17a3.63,3.63,0,0,0,.57,2.17,1.86,1.86,0,0,0,1.59.78,1.82,1.82,0,0,0,1.59-.79,4,4,0,0,0,.57-2.33,3.57,3.57,0,0,0-.58-2.16,2,2,0,0,0-3.16,0A3.94,3.94,0,0,0,314.62,213.62Z"
                fill="#46d5a3"
              />
              <path
                d="M330.55,215.24,332,209h2l-2.45,9h-1.66l-1.92-6.17L326,218h-1.67l-2.45-9h2l1.45,6.13,1.84-6.13h1.52Z"
                fill="#46d5a3"
              />
              <path
                d="M347.41,215.24l1.43-6.2h2l-2.45,9H346.7l-1.93-6.17L342.88,218h-1.66l-2.45-9h2l1.46,6.13L344,209h1.52Z"
                fill="#46d5a3"
              />
              <path
                d="M354.15,210a3.14,3.14,0,0,1,2.5-1.15q2.87,0,2.91,3.28V218h-2v-5.79a1.78,1.78,0,0,0-.4-1.32,1.63,1.63,0,0,0-1.18-.39,1.93,1.93,0,0,0-1.81,1.08V218h-2V205.27h2Z"
                fill="#46d5a3"
              />
              <path
                d="M361.22,213.45a5.27,5.27,0,0,1,.52-2.38,3.82,3.82,0,0,1,1.47-1.63,4.14,4.14,0,0,1,2.17-.57,3.94,3.94,0,0,1,3,1.17,4.63,4.63,0,0,1,1.22,3.11v.47a5.32,5.32,0,0,1-.51,2.37,3.77,3.77,0,0,1-1.46,1.62,4.22,4.22,0,0,1-2.2.58,3.9,3.9,0,0,1-3-1.27,4.85,4.85,0,0,1-1.14-3.38Zm2,.17a3.63,3.63,0,0,0,.57,2.17,2,2,0,0,0,3.18,0,4,4,0,0,0,.57-2.33,3.5,3.5,0,0,0-.59-2.16,2,2,0,0,0-3.15,0A3.94,3.94,0,0,0,363.24,213.62Z"
                fill="#46d5a3"
              />
              <path d="M377.92,218h-2.09V205.93h2.09Z" fill="#46d5a3" />
              <path
                d="M389.91,218a3.11,3.11,0,0,1-.24-.84,3.12,3.12,0,0,1-2.35,1,3.16,3.16,0,0,1-2.21-.78,2.47,2.47,0,0,1-.86-1.91,2.56,2.56,0,0,1,1.07-2.2,5.18,5.18,0,0,1,3.05-.77h1.24v-.59a1.41,1.41,0,0,0-1.58-1.53,1.79,1.79,0,0,0-1.13.34,1.08,1.08,0,0,0-.44.88h-2a2.24,2.24,0,0,1,.49-1.38,3.29,3.29,0,0,1,1.34-1,4.57,4.57,0,0,1,1.88-.37,3.79,3.79,0,0,1,2.51.79,2.8,2.8,0,0,1,1,2.23v4.05a4.55,4.55,0,0,0,.35,1.94V218Zm-2.22-1.45a2.38,2.38,0,0,0,1.13-.29,2,2,0,0,0,.79-.78v-1.7h-1.09a3,3,0,0,0-1.69.39,1.28,1.28,0,0,0-.56,1.11,1.17,1.17,0,0,0,.39.92A1.48,1.48,0,0,0,387.69,216.57Z"
                fill="#46d5a3"
              />
              <path
                d="M395.63,209l.06.94a3.23,3.23,0,0,1,2.59-1.11,2.5,2.5,0,0,1,2.47,1.38,3.18,3.18,0,0,1,2.75-1.38,2.85,2.85,0,0,1,2.22.82,3.65,3.65,0,0,1,.74,2.43V218h-2v-5.84a1.81,1.81,0,0,0-.37-1.26,1.66,1.66,0,0,0-1.24-.4,1.7,1.7,0,0,0-1.12.37,1.93,1.93,0,0,0-.61,1V218h-2v-5.91a1.44,1.44,0,0,0-1.62-1.59,1.78,1.78,0,0,0-1.72,1V218h-2v-9Z"
                fill="#46d5a3"
              />
              <path d="M270.38,236.57h-5.32v4.64h6.12V242H264.1V230.64h7v.82h-6.08v4.29h5.32Z" />
              <path d="M273.88,233.57l0,1.44a3.31,3.31,0,0,1,1.19-1.19,3.09,3.09,0,0,1,1.57-.41,2.54,2.54,0,0,1,2,.77,3.42,3.42,0,0,1,.67,2.29V242h-.93v-5.56a2.51,2.51,0,0,0-.48-1.68,1.88,1.88,0,0,0-1.51-.56,2.35,2.35,0,0,0-1.54.55,3.18,3.18,0,0,0-1,1.46V242H273v-8.45Z" />
              <path d="M281.4,237.71a5,5,0,0,1,.88-3.14,2.85,2.85,0,0,1,2.39-1.16,2.94,2.94,0,0,1,2.62,1.37l0-1.21h.87v8.28a3.55,3.55,0,0,1-.91,2.58,3.27,3.27,0,0,1-2.48,1,3.78,3.78,0,0,1-1.68-.4,3.12,3.12,0,0,1-1.26-1.05l.52-.56a3,3,0,0,0,2.36,1.22,2.41,2.41,0,0,0,1.83-.69,2.78,2.78,0,0,0,.68-1.92v-1.06a3,3,0,0,1-2.62,1.25,2.82,2.82,0,0,1-2.36-1.18,5.11,5.11,0,0,1-.89-3.16Zm1,.17a4.41,4.41,0,0,0,.64,2.55,2.12,2.12,0,0,0,1.83.93,2.5,2.5,0,0,0,2.45-1.54v-3.93a2.64,2.64,0,0,0-.95-1.24,2.49,2.49,0,0,0-1.48-.43,2.13,2.13,0,0,0-1.84.93A4.71,4.71,0,0,0,282.35,237.88Z" />
              <path d="M296,242a4,4,0,0,1-.18-1.18,3.24,3.24,0,0,1-1.26,1,3.85,3.85,0,0,1-1.61.35,2.84,2.84,0,0,1-2-.68,2.21,2.21,0,0,1-.76-1.72,2.28,2.28,0,0,1,1-2,4.92,4.92,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.3,2.3,0,0,0-1.63-.52,2.59,2.59,0,0,0-1.62.5,1.49,1.49,0,0,0-.64,1.2h-.93a2.17,2.17,0,0,1,.93-1.74,3.65,3.65,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.71,2.56,2.56,0,0,1,.84,2v4a4.82,4.82,0,0,0,.26,1.84V242Zm-2.94-.67a3.06,3.06,0,0,0,1.67-.46,2.63,2.63,0,0,0,1.08-1.21v-1.86h-1.67a4.09,4.09,0,0,0-2.19.52,1.57,1.57,0,0,0-.26,2.54A2,2,0,0,0,293.05,241.35Z" />
              <path d="M298.85,237.71a5.11,5.11,0,0,1,.87-3.14,2.86,2.86,0,0,1,2.39-1.16,3,3,0,0,1,2.63,1.37l0-1.21h.88v8.28a3.55,3.55,0,0,1-.92,2.58,3.25,3.25,0,0,1-2.47,1,3.85,3.85,0,0,1-1.69-.4,3.26,3.26,0,0,1-1.26-1.05l.53-.56a3,3,0,0,0,2.36,1.22,2.37,2.37,0,0,0,1.82-.69,2.73,2.73,0,0,0,.68-1.92v-1.06a3,3,0,0,1-2.61,1.25,2.82,2.82,0,0,1-2.36-1.18,5,5,0,0,1-.89-3.16Zm.94.17a4.41,4.41,0,0,0,.65,2.55,2.1,2.1,0,0,0,1.83.93,2.49,2.49,0,0,0,2.44-1.54v-3.93a2.62,2.62,0,0,0-.94-1.24,2.52,2.52,0,0,0-1.49-.43,2.11,2.11,0,0,0-1.83.93A4.7,4.7,0,0,0,299.79,237.88Z" />
              <path d="M311.37,242.18a3.66,3.66,0,0,1-3.31-2,4.64,4.64,0,0,1-.48-2.13v-.33a5.07,5.07,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.26,3.26,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6V238a3.55,3.55,0,0,0,.82,2.4,2.62,2.62,0,0,0,2.08,1,2.84,2.84,0,0,0,2.37-1.15l.58.44A3.4,3.4,0,0,1,311.37,242.18Zm-.17-8a2.31,2.31,0,0,0-1.78.77,3.64,3.64,0,0,0-.88,2.08h5.07V237a3.16,3.16,0,0,0-.69-2A2.16,2.16,0,0,0,311.2,234.21Z" />
              <path d="M322.28,240.07l.14.73.18-.77,2-6.46h.8l2,6.41.22.89.18-.82,1.73-6.48h1L328.08,242h-.8l-2.17-6.7-.1-.47-.1.47-2.13,6.7H322l-2.45-8.45h1Z" />
              <path d="M332.26,231.12a.64.64,0,0,1,.17-.44.63.63,0,0,1,.47-.18.61.61,0,0,1,.47.18.6.6,0,0,1,.18.44.62.62,0,0,1-.65.63.63.63,0,0,1-.47-.18A.64.64,0,0,1,332.26,231.12Zm1.1,10.9h-.94v-8.45h.94Z" />
              <path d="M337.35,231.4v2.17h1.75v.76h-1.75V240a1.76,1.76,0,0,0,.26,1.05,1,1,0,0,0,.85.34,4.73,4.73,0,0,0,.75-.08l0,.76a3,3,0,0,1-1,.14,1.69,1.69,0,0,1-1.4-.56,2.66,2.66,0,0,1-.43-1.65v-5.64h-1.56v-.76h1.56V231.4Z" />
              <path d="M342,235a3.33,3.33,0,0,1,1.18-1.18,3.14,3.14,0,0,1,1.57-.41,2.52,2.52,0,0,1,2,.77,3.42,3.42,0,0,1,.68,2.29V242h-.93v-5.56a2.63,2.63,0,0,0-.48-1.68,1.9,1.9,0,0,0-1.52-.56,2.32,2.32,0,0,0-1.53.55,3.12,3.12,0,0,0-1,1.46V242H341V230H342Z" />
              <path d="M354.56,233.57l0,1.37a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.38,2.38,0,0,1,2.54,1.64,3.06,3.06,0,0,1,1.21-1.21,3.27,3.27,0,0,1,1.67-.43c1.81,0,2.74,1,2.78,3V242h-.94v-5.57a2.53,2.53,0,0,0-.49-1.68,2,2,0,0,0-1.57-.55,2.5,2.5,0,0,0-1.7.65,2.34,2.34,0,0,0-.77,1.56V242h-.94v-5.64a2.34,2.34,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.35,2.35,0,0,0-1.52.51,3,3,0,0,0-.94,1.49V242h-.93v-8.45Z" />
              <path d="M371.17,242.18a3.62,3.62,0,0,1-1.95-.54,3.55,3.55,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a4.93,4.93,0,0,1,.47-2.2,3.73,3.73,0,0,1,1.32-1.53,3.25,3.25,0,0,1,1.82-.56,3.08,3.08,0,0,1,2.45,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6V238a3.55,3.55,0,0,0,.82,2.4,2.62,2.62,0,0,0,2.08,1,2.8,2.8,0,0,0,2.36-1.15l.59.44A3.41,3.41,0,0,1,371.17,242.18Zm-.18-8a2.31,2.31,0,0,0-1.77.77,3.58,3.58,0,0,0-.88,2.08h5.07V237a3.11,3.11,0,0,0-.7-2A2.14,2.14,0,0,0,371,234.21Z" />
              <path d="M385.49,241a3,3,0,0,1-2.7,1.23,2.62,2.62,0,0,1-2.05-.79,3.42,3.42,0,0,1-.72-2.32v-5.5H381V239c0,1.61.65,2.41,1.95,2.41a2.4,2.4,0,0,0,2.57-1.68v-6.11h.94V242h-.91Z" />
              <path d="M393.9,239.87a1.35,1.35,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,8.15,8.15,0,0,1-1.75-.54,2.37,2.37,0,0,1-.92-.76,1.86,1.86,0,0,1-.3-1.08,2,2,0,0,1,.84-1.65,3.39,3.39,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.24,2.24,0,0,1,.85,1.82h-.94a1.5,1.5,0,0,0-.62-1.23,2.8,2.8,0,0,0-3-.08,1.24,1.24,0,0,0-.56,1.06,1.15,1.15,0,0,0,.46,1,4.73,4.73,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.38,2.38,0,0,1,.91.79,2,2,0,0,1,.3,1.11,2,2,0,0,1-.87,1.71,3.64,3.64,0,0,1-2.25.65,3.76,3.76,0,0,1-2.39-.72,2.19,2.19,0,0,1-.92-1.81h.94a1.6,1.6,0,0,0,.69,1.28,2.83,2.83,0,0,0,1.68.46,2.71,2.71,0,0,0,1.58-.43A1.3,1.3,0,0,0,393.9,239.87Z" />
              <path d="M396.88,231.12a.6.6,0,0,1,.18-.44.61.61,0,0,1,.47-.18.64.64,0,0,1,.47.18.6.6,0,0,1,.17.44.6.6,0,0,1-.17.45.64.64,0,0,1-.47.18.62.62,0,0,1-.65-.63ZM398,242h-.94v-8.45H398Z" />
              <path d="M401.42,233.57l0,1.44a3.28,3.28,0,0,1,1.18-1.19,3.15,3.15,0,0,1,1.58-.41,2.54,2.54,0,0,1,2,.77,3.42,3.42,0,0,1,.67,2.29V242H406v-5.56a2.51,2.51,0,0,0-.48-1.68,1.89,1.89,0,0,0-1.51-.56,2.35,2.35,0,0,0-1.54.55,3.18,3.18,0,0,0-1,1.46V242h-.93v-8.45Z" />
              <path d="M408.94,237.71a5,5,0,0,1,.88-3.14,2.84,2.84,0,0,1,2.39-1.16,2.94,2.94,0,0,1,2.62,1.37l.05-1.21h.87v8.28a3.55,3.55,0,0,1-.91,2.58,3.27,3.27,0,0,1-2.48,1,3.78,3.78,0,0,1-1.68-.4,3.12,3.12,0,0,1-1.26-1.05l.52-.56a3,3,0,0,0,2.36,1.22,2.41,2.41,0,0,0,1.83-.69,2.83,2.83,0,0,0,.68-1.92v-1.06a3,3,0,0,1-2.62,1.25,2.82,2.82,0,0,1-2.36-1.18,5.11,5.11,0,0,1-.89-3.16Zm.94.17a4.41,4.41,0,0,0,.65,2.55,2.12,2.12,0,0,0,1.83.93,2.5,2.5,0,0,0,2.45-1.54v-3.93a2.64,2.64,0,0,0-.95-1.24,2.49,2.49,0,0,0-1.48-.43,2.13,2.13,0,0,0-1.84.93A4.7,4.7,0,0,0,409.88,237.88Z" />
              <path d="M427.42,242a4,4,0,0,1-.18-1.18,3.24,3.24,0,0,1-1.26,1,3.85,3.85,0,0,1-1.61.35,2.84,2.84,0,0,1-2-.68,2.21,2.21,0,0,1-.76-1.72,2.28,2.28,0,0,1,1-2,4.92,4.92,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.3,2.3,0,0,0-1.63-.52,2.59,2.59,0,0,0-1.62.5,1.49,1.49,0,0,0-.64,1.2h-.93a2.17,2.17,0,0,1,.93-1.74,3.65,3.65,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.71,2.56,2.56,0,0,1,.84,2v4a4.82,4.82,0,0,0,.26,1.84V242Zm-2.94-.67a3.06,3.06,0,0,0,1.67-.46,2.63,2.63,0,0,0,1.08-1.21v-1.86h-1.67a4.09,4.09,0,0,0-2.19.52,1.57,1.57,0,0,0-.26,2.54A2,2,0,0,0,424.48,241.35Z" />
              <path d="M431.7,242h-.94V230h.94Z" />
              <path d="M435.28,242h-.93V230h.93Z" />
              <path d="M254.36,264.68l2.47-7.11h1l-3.61,9.89-.18.43a2.27,2.27,0,0,1-2.15,1.54,2.34,2.34,0,0,1-.72-.11v-.77l.49.05a1.77,1.77,0,0,0,1.11-.34,2.67,2.67,0,0,0,.72-1.17l.42-1.14-3.19-8.38h1Z" />
              <path d="M258.65,261.67a4.9,4.9,0,0,1,.47-2.2,3.55,3.55,0,0,1,3.29-2.06,3.48,3.48,0,0,1,2.74,1.18,4.61,4.61,0,0,1,1,3.14v.2a4.93,4.93,0,0,1-.47,2.2,3.45,3.45,0,0,1-1.33,1.51,3.61,3.61,0,0,1-2,.54A3.48,3.48,0,0,1,259.7,265a4.55,4.55,0,0,1-1.05-3.14Zm.93.26a3.82,3.82,0,0,0,.79,2.48,2.64,2.64,0,0,0,4.11,0,4,4,0,0,0,.78-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.89,2.89,0,0,0-1-1.24,2.56,2.56,0,0,0-1.49-.44,2.49,2.49,0,0,0-2,1,4,4,0,0,0-.79,2.56Z" />
              <path d="M273.56,265a3,3,0,0,1-2.69,1.23,2.63,2.63,0,0,1-2.06-.79,3.42,3.42,0,0,1-.72-2.32v-5.5H269V263c0,1.61.65,2.41,2,2.41a2.38,2.38,0,0,0,2.57-1.68v-6.11h.94V266h-.9Z" />
              <path d="M282.81,261.76l-1.07,1V266h-.94V254h.94v7.68l.88-.95,3.24-3.18H287l-3.59,3.56,3.9,4.89h-1.12Z" />
              <path d="M289.53,257.57l0,1.44a3.31,3.31,0,0,1,1.19-1.19,3.12,3.12,0,0,1,1.57-.41,2.5,2.5,0,0,1,2,.77,3.42,3.42,0,0,1,.67,2.29V266h-.93v-5.56a2.51,2.51,0,0,0-.48-1.68,1.88,1.88,0,0,0-1.51-.56,2.3,2.3,0,0,0-1.53.55,3.12,3.12,0,0,0-1,1.46V266h-.93v-8.45Z" />
              <path d="M296.91,261.67a5,5,0,0,1,.47-2.2,3.68,3.68,0,0,1,1.34-1.52,3.63,3.63,0,0,1,2-.54,3.47,3.47,0,0,1,2.73,1.18,4.57,4.57,0,0,1,1,3.14v.2a5.07,5.07,0,0,1-.47,2.2,3.47,3.47,0,0,1-1.34,1.51,3.58,3.58,0,0,1-2,.54A3.48,3.48,0,0,1,298,265a4.55,4.55,0,0,1-1-3.14Zm.94.26a3.81,3.81,0,0,0,.78,2.48,2.53,2.53,0,0,0,2.06,1,2.5,2.5,0,0,0,2.05-1,3.9,3.9,0,0,0,.79-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.53,2.53,0,0,0-1.48-.44,2.47,2.47,0,0,0-2,1,3.93,3.93,0,0,0-.79,2.56Z" />
              <path d="M308.41,264.07l.14.73.19-.77,2-6.46h.8l2,6.41.21.89.18-.82,1.74-6.48h1L314.22,266h-.8l-2.17-6.7-.1-.47-.11.47-2.13,6.7h-.79l-2.46-8.45h1Z" />
              <path d="M317.4,265.46a.7.7,0,0,1,.17-.47.73.73,0,0,1,1,0,.66.66,0,0,1,.18.47.61.61,0,0,1-.18.45.79.79,0,0,1-1,0A.64.64,0,0,1,317.4,265.46Z" />
              <path d="M331.85,263.22a1.82,1.82,0,0,0-.66-1.49,7.07,7.07,0,0,0-2.43-1.05,8.48,8.48,0,0,1-2.58-1.08,2.62,2.62,0,0,1-.08-4.3,5,5,0,0,1,4.77-.37,3.19,3.19,0,0,1,1.88,3h-1A2.4,2.4,0,0,0,331,256a3.1,3.1,0,0,0-2.14-.71,3.38,3.38,0,0,0-2.08.58,1.84,1.84,0,0,0-.77,1.52,1.71,1.71,0,0,0,.7,1.42,6.3,6.3,0,0,0,2.22,1,10.37,10.37,0,0,1,2.32.87,3.29,3.29,0,0,1,1.19,1.07,2.67,2.67,0,0,1,.4,1.48,2.56,2.56,0,0,1-1.08,2.15,4.57,4.57,0,0,1-2.83.82,5.6,5.6,0,0,1-2.2-.43,3.41,3.41,0,0,1-1.53-1.21,3,3,0,0,1-.52-1.77h1a2.28,2.28,0,0,0,.9,1.9,3.83,3.83,0,0,0,2.39.69,3.52,3.52,0,0,0,2.13-.58A1.83,1.83,0,0,0,331.85,263.22Z" />
              <path d="M335.6,259a3.33,3.33,0,0,1,1.18-1.18,3.14,3.14,0,0,1,1.57-.41,2.49,2.49,0,0,1,2,.77,3.37,3.37,0,0,1,.67,2.29V266h-.92v-5.56a2.57,2.57,0,0,0-.49-1.68,1.86,1.86,0,0,0-1.51-.56,2.3,2.3,0,0,0-1.53.55,3.12,3.12,0,0,0-1,1.46V266h-.93V254h.93Z" />
              <path d="M342.94,261.67a4.9,4.9,0,0,1,.47-2.2,3.59,3.59,0,0,1,1.33-1.52,3.63,3.63,0,0,1,2-.54,3.48,3.48,0,0,1,2.74,1.18,4.61,4.61,0,0,1,1,3.14v.2a5.07,5.07,0,0,1-.47,2.2,3.51,3.51,0,0,1-1.33,1.51,3.63,3.63,0,0,1-2,.54A3.48,3.48,0,0,1,344,265a4.55,4.55,0,0,1-1.05-3.14Zm.93.26a3.76,3.76,0,0,0,.79,2.48,2.64,2.64,0,0,0,4.11,0,4,4,0,0,0,.78-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.89,2.89,0,0,0-1-1.24,2.56,2.56,0,0,0-1.49-.44,2.49,2.49,0,0,0-2,1,4,4,0,0,0-.79,2.56Z" />
              <path d="M354.44,264.07l.14.73.18-.77,2-6.46h.8l2,6.41.22.89.18-.82,1.73-6.48h1L360.24,266h-.8l-2.17-6.7-.1-.47-.1.47-2.13,6.7h-.8l-2.45-8.45h1Z" />
              <path d="M369.22,257.57l0,1.37a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.37,2.37,0,0,1,2.54,1.64,3.13,3.13,0,0,1,1.21-1.21,3.3,3.3,0,0,1,1.67-.43c1.82,0,2.74,1,2.78,3V266h-.94v-5.57a2.48,2.48,0,0,0-.49-1.68,2,2,0,0,0-1.56-.55,2.52,2.52,0,0,0-1.71.65,2.34,2.34,0,0,0-.77,1.56V266h-.94v-5.64a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.36,2.36,0,0,0-1.52.51,2.9,2.9,0,0,0-.93,1.49V266h-.94v-8.45Z" />
              <path d="M385.82,266.18a3.66,3.66,0,0,1-3.31-2A4.64,4.64,0,0,1,382,262v-.33a5.07,5.07,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.26,3.26,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1,4.27,4.27,0,0,1,.9,2.87v.52h-6V262a3.55,3.55,0,0,0,.82,2.4,2.62,2.62,0,0,0,2.08,1,2.8,2.8,0,0,0,2.36-1.15l.59.44A3.4,3.4,0,0,1,385.82,266.18Zm-.17-8a2.31,2.31,0,0,0-1.78.77,3.58,3.58,0,0,0-.88,2.08h5.07V261a3.16,3.16,0,0,0-.69-2A2.17,2.17,0,0,0,385.65,258.21Z" />
              <path d="M398.61,258.36a4.26,4.26,0,0,0-.64-.05,2.28,2.28,0,0,0-1.48.49,2.64,2.64,0,0,0-.86,1.41V266h-.93v-8.45h.92v1.34a2.49,2.49,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M403.21,266.18a3.66,3.66,0,0,1-3.31-2,4.64,4.64,0,0,1-.48-2.13v-.33a5.07,5.07,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.26,3.26,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1,4.27,4.27,0,0,1,.9,2.87v.52h-6V262a3.55,3.55,0,0,0,.82,2.4,2.64,2.64,0,0,0,2.08,1,2.84,2.84,0,0,0,2.37-1.15l.58.44A3.4,3.4,0,0,1,403.21,266.18Zm-.17-8a2.31,2.31,0,0,0-1.78.77,3.64,3.64,0,0,0-.88,2.08h5.07V261a3.16,3.16,0,0,0-.69-2A2.16,2.16,0,0,0,403,258.21Z" />
              <path d="M409.25,266h-.94V254h.94Z" />
              <path d="M415.06,266.18a3.62,3.62,0,0,1-1.95-.54,3.52,3.52,0,0,1-1.35-1.48,4.52,4.52,0,0,1-.49-2.13v-.33a5.07,5.07,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.26,3.26,0,0,1,1.83-.56,3,3,0,0,1,2.44,1,4.27,4.27,0,0,1,.9,2.87v.52h-6V262a3.56,3.56,0,0,0,.83,2.4,2.62,2.62,0,0,0,2.07,1,2.84,2.84,0,0,0,2.37-1.15l.58.44A3.39,3.39,0,0,1,415.06,266.18Zm-.17-8a2.33,2.33,0,0,0-1.78.77,3.64,3.64,0,0,0-.88,2.08h5.07V261a3.11,3.11,0,0,0-.69-2A2.16,2.16,0,0,0,414.89,258.21Z" />
              <path d="M422.58,264.73l2.57-7.16h1L423,266h-.76L419,257.57h1Z" />
              <path d="M432.87,266a4,4,0,0,1-.18-1.18,3.24,3.24,0,0,1-1.26,1,3.85,3.85,0,0,1-1.61.35,2.84,2.84,0,0,1-2-.68,2.21,2.21,0,0,1-.76-1.72,2.26,2.26,0,0,1,1-2,4.92,4.92,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.3,2.3,0,0,0-1.63-.52,2.59,2.59,0,0,0-1.62.5,1.49,1.49,0,0,0-.64,1.2h-.94a2.18,2.18,0,0,1,.94-1.74,3.65,3.65,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.71,2.56,2.56,0,0,1,.84,2v4a4.82,4.82,0,0,0,.26,1.84V266Zm-2.94-.67a3.06,3.06,0,0,0,1.67-.46,2.63,2.63,0,0,0,1.08-1.21v-1.86H431a4.07,4.07,0,0,0-2.19.52,1.57,1.57,0,0,0-.26,2.54A2,2,0,0,0,429.93,265.35Z" />
              <path d="M437,257.57l0,1.44a3.39,3.39,0,0,1,1.19-1.19,3.12,3.12,0,0,1,1.57-.41,2.49,2.49,0,0,1,2,.77,3.37,3.37,0,0,1,.67,2.29V266h-.93v-5.56a2.51,2.51,0,0,0-.48-1.68,1.86,1.86,0,0,0-1.51-.56,2.3,2.3,0,0,0-1.53.55,3.12,3.12,0,0,0-1,1.46V266h-.93v-8.45Z" />
              <path d="M446.34,255.4v2.17h1.75v.76h-1.75V264a1.76,1.76,0,0,0,.26,1,1,1,0,0,0,.84.34,4.72,4.72,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.14,1.68,1.68,0,0,1-1.4-.56,2.66,2.66,0,0,1-.44-1.65v-5.64h-1.55v-.76h1.55V255.4Z" />
              <path d="M270.17,289.39a2.49,2.49,0,0,0,1.63-.55,2,2,0,0,0,.72-1.43h.9a2.63,2.63,0,0,1-.49,1.41,3.11,3.11,0,0,1-1.18,1,3.55,3.55,0,0,1-1.58.36,3.28,3.28,0,0,1-2.64-1.16,4.67,4.67,0,0,1-1-3.11v-.28a5.31,5.31,0,0,1,.43-2.21,3.31,3.31,0,0,1,3.17-2,3.27,3.27,0,0,1,2.29.83,3.06,3.06,0,0,1,1,2.18h-.9a2.27,2.27,0,0,0-2.36-2.21,2.33,2.33,0,0,0-2,.91,4.17,4.17,0,0,0-.7,2.56V286a4.08,4.08,0,0,0,.7,2.52A2.38,2.38,0,0,0,270.17,289.39Z" />
              <path d="M274.77,285.67a4.9,4.9,0,0,1,.47-2.2,3.61,3.61,0,0,1,1.34-1.52,3.57,3.57,0,0,1,1.95-.54,3.48,3.48,0,0,1,2.74,1.18,4.56,4.56,0,0,1,1,3.14v.2a4.93,4.93,0,0,1-.47,2.2,3.45,3.45,0,0,1-1.33,1.51,3.61,3.61,0,0,1-2,.54,3.48,3.48,0,0,1-2.73-1.19,4.55,4.55,0,0,1-1.05-3.14Zm.94.26a3.81,3.81,0,0,0,.78,2.48,2.64,2.64,0,0,0,4.11,0,4,4,0,0,0,.78-2.56v-.18a4.49,4.49,0,0,0-.35-1.78,2.91,2.91,0,0,0-1-1.24,2.56,2.56,0,0,0-1.49-.44,2.49,2.49,0,0,0-2,1,4,4,0,0,0-.78,2.56Z" />
              <path d="M285.13,281.57l0,1.44a3.28,3.28,0,0,1,1.18-1.19,3.14,3.14,0,0,1,1.57-.41,2.53,2.53,0,0,1,2,.77,3.47,3.47,0,0,1,.67,2.29V290h-.93v-5.56a2.63,2.63,0,0,0-.48-1.68,1.89,1.89,0,0,0-1.51-.56,2.35,2.35,0,0,0-1.54.55,3.27,3.27,0,0,0-1,1.46V290h-.93v-8.45Z" />
              <path d="M294.49,279.4v2.17h1.75v.76h-1.75V288a1.77,1.77,0,0,0,.25,1,1,1,0,0,0,.85.34,4.83,4.83,0,0,0,.76-.08l0,.76a2.92,2.92,0,0,1-1,.14,1.66,1.66,0,0,1-1.39-.56,2.6,2.6,0,0,1-.44-1.65v-5.64H292v-.76h1.56V279.4Z" />
              <path d="M301.45,290.18a3.66,3.66,0,0,1-3.31-2,4.64,4.64,0,0,1-.48-2.13v-.33a5.07,5.07,0,0,1,.47-2.2,3.73,3.73,0,0,1,1.32-1.53,3.26,3.26,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1,4.27,4.27,0,0,1,.9,2.87v.52h-6V286a3.55,3.55,0,0,0,.82,2.4,2.62,2.62,0,0,0,2.08,1,2.8,2.8,0,0,0,2.36-1.15l.59.44A3.41,3.41,0,0,1,301.45,290.18Zm-.17-8a2.31,2.31,0,0,0-1.78.77,3.58,3.58,0,0,0-.88,2.08h5.07V285a3.11,3.11,0,0,0-.7-2A2.13,2.13,0,0,0,301.28,282.21Z" />
              <path d="M307.33,281.57l0,1.44a3.31,3.31,0,0,1,1.19-1.19,3.12,3.12,0,0,1,1.57-.41,2.49,2.49,0,0,1,2,.77,3.42,3.42,0,0,1,.67,2.29V290h-.93v-5.56a2.51,2.51,0,0,0-.48-1.68,1.88,1.88,0,0,0-1.51-.56,2.3,2.3,0,0,0-1.53.55,3.12,3.12,0,0,0-1,1.46V290h-.93v-8.45Z" />
              <path d="M316.68,279.4v2.17h1.75v.76h-1.75V288a1.76,1.76,0,0,0,.26,1,1,1,0,0,0,.84.34,4.72,4.72,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.14,1.68,1.68,0,0,1-1.4-.56,2.66,2.66,0,0,1-.44-1.65v-5.64h-1.55v-.76h1.55V279.4Z" />
              <path d="M320.28,289.46a.66.66,0,0,1,.18-.47.73.73,0,0,1,1,0,.66.66,0,0,1,.18.47.61.61,0,0,1-.18.45.79.79,0,0,1-1,0A.61.61,0,0,1,320.28,289.46Z" />
              <path d="M329.26,285.39V290h-1V278.64h3.87a4.08,4.08,0,0,1,2.8.91,3.56,3.56,0,0,1,0,5,4.2,4.2,0,0,1-2.86.87Zm0-.82h2.91a3.1,3.1,0,0,0,2.14-.66,2.37,2.37,0,0,0,.73-1.86,2.49,2.49,0,0,0-.73-1.88,3,3,0,0,0-2.07-.71h-3Z" />
              <path d="M341.1,290.18a3.62,3.62,0,0,1-2-.54,3.52,3.52,0,0,1-1.35-1.48,4.64,4.64,0,0,1-.49-2.13v-.33a4.94,4.94,0,0,1,.48-2.2A3.7,3.7,0,0,1,339.1,282a3.31,3.31,0,0,1,1.83-.56,3,3,0,0,1,2.44,1,4.28,4.28,0,0,1,.91,2.87v.52h-6V286a3.56,3.56,0,0,0,.83,2.4,2.62,2.62,0,0,0,2.07,1,3,3,0,0,0,1.33-.28,2.9,2.9,0,0,0,1-.87l.59.44A3.42,3.42,0,0,1,341.1,290.18Zm-.17-8a2.34,2.34,0,0,0-1.78.77,3.57,3.57,0,0,0-.87,2.08h5.07V285a3.11,3.11,0,0,0-.7-2A2.16,2.16,0,0,0,340.93,282.21Z" />
              <path d="M350,282.36a4.23,4.23,0,0,0-.63-.05,2.26,2.26,0,0,0-1.48.49,2.58,2.58,0,0,0-.86,1.41V290h-.93v-8.45H347l0,1.34a2.47,2.47,0,0,1,2.38-1.5,1.55,1.55,0,0,1,.62.1Z" />
              <path d="M356.54,287.87a1.35,1.35,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,8.15,8.15,0,0,1-1.75-.54,2.37,2.37,0,0,1-.92-.76,1.86,1.86,0,0,1-.3-1.08,2,2,0,0,1,.84-1.65,3.39,3.39,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.22,2.22,0,0,1,.85,1.82h-.94a1.5,1.5,0,0,0-.62-1.23,2.8,2.8,0,0,0-3-.08,1.24,1.24,0,0,0-.56,1.06,1.15,1.15,0,0,0,.46,1,4.73,4.73,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.38,2.38,0,0,1,.91.79,2,2,0,0,1,.3,1.11,2,2,0,0,1-.86,1.71,3.69,3.69,0,0,1-2.26.65,3.76,3.76,0,0,1-2.39-.72,2.19,2.19,0,0,1-.92-1.81H352a1.6,1.6,0,0,0,.69,1.28,2.83,2.83,0,0,0,1.68.46,2.71,2.71,0,0,0,1.58-.43A1.3,1.3,0,0,0,356.54,287.87Z" />
              <path d="M359.06,285.67a4.9,4.9,0,0,1,.48-2.2,3.59,3.59,0,0,1,1.33-1.52,3.63,3.63,0,0,1,2-.54,3.44,3.44,0,0,1,2.73,1.18,4.57,4.57,0,0,1,1.05,3.14v.2a5.07,5.07,0,0,1-.47,2.2,3.51,3.51,0,0,1-1.33,1.51,3.63,3.63,0,0,1-2,.54,3.48,3.48,0,0,1-2.73-1.19,4.51,4.51,0,0,1-1.06-3.14Zm.94.26a3.76,3.76,0,0,0,.79,2.48,2.64,2.64,0,0,0,4.11,0,4,4,0,0,0,.78-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.84,2.84,0,0,0-1-1.24,2.65,2.65,0,0,0-3.52.54,3.93,3.93,0,0,0-.79,2.56Z" />
              <path d="M369.43,281.57l0,1.44a3.39,3.39,0,0,1,1.19-1.19,3.12,3.12,0,0,1,1.57-.41,2.49,2.49,0,0,1,2,.77,3.42,3.42,0,0,1,.68,2.29V290H374v-5.56a2.63,2.63,0,0,0-.48-1.68,1.9,1.9,0,0,0-1.52-.56,2.32,2.32,0,0,0-1.53.55,3.12,3.12,0,0,0-1,1.46V290h-.93v-8.45Z" />
              <path d="M382.67,290a4,4,0,0,1-.18-1.18,3.24,3.24,0,0,1-1.26,1,3.85,3.85,0,0,1-1.61.35,2.84,2.84,0,0,1-2-.68,2.21,2.21,0,0,1-.76-1.72,2.28,2.28,0,0,1,1-2,4.92,4.92,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.3,2.3,0,0,0-1.63-.52,2.59,2.59,0,0,0-1.62.5,1.49,1.49,0,0,0-.64,1.2h-.93a2.17,2.17,0,0,1,.93-1.74,3.65,3.65,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.71,2.56,2.56,0,0,1,.84,2v4a4.82,4.82,0,0,0,.26,1.84V290Zm-2.94-.67a3.06,3.06,0,0,0,1.67-.46,2.63,2.63,0,0,0,1.08-1.21v-1.86h-1.67a4.09,4.09,0,0,0-2.19.52,1.57,1.57,0,0,0-.26,2.54A2,2,0,0,0,379.73,289.35Z" />
              <path d="M387,290H386V278H387Z" />
              <path d="M389.43,279.12a.64.64,0,0,1,.17-.44.63.63,0,0,1,.47-.18.61.61,0,0,1,.47.18.6.6,0,0,1,.18.44.62.62,0,0,1-.65.63.63.63,0,0,1-.47-.18A.64.64,0,0,1,389.43,279.12Zm1.1,10.9h-.93v-8.45h.93Z" />
              <path d="M404.19,290.18a3.61,3.61,0,0,1-1.94-.54,3.48,3.48,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a4.93,4.93,0,0,1,.47-2.2,3.7,3.7,0,0,1,1.31-1.53,3.31,3.31,0,0,1,1.83-.56,3,3,0,0,1,2.44,1,4.22,4.22,0,0,1,.91,2.87v.52h-6V286a3.55,3.55,0,0,0,.82,2.4,2.62,2.62,0,0,0,2.07,1,3,3,0,0,0,1.33-.28,2.9,2.9,0,0,0,1-.87l.59.44A3.42,3.42,0,0,1,404.19,290.18Zm-.17-8a2.31,2.31,0,0,0-1.77.77,3.51,3.51,0,0,0-.88,2.08h5.07V285a3.11,3.11,0,0,0-.7-2A2.16,2.16,0,0,0,404,282.21Z" />
              <path d="M413.74,281.57l0,1.37a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.38,2.38,0,0,1,2.54,1.64,3.13,3.13,0,0,1,1.21-1.21,3.3,3.3,0,0,1,1.67-.43c1.82,0,2.74,1,2.78,3V290h-.94v-5.57a2.53,2.53,0,0,0-.49-1.68,2,2,0,0,0-1.56-.55,2.52,2.52,0,0,0-1.71.65,2.34,2.34,0,0,0-.77,1.56V290h-.94v-5.64a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.35,2.35,0,0,0-1.52.51,2.9,2.9,0,0,0-.93,1.49V290h-.94v-8.45Z" />
              <path d="M429.74,288.68l2.47-7.11h1l-3.61,9.89-.19.43a2.26,2.26,0,0,1-2.14,1.54,2.34,2.34,0,0,1-.72-.11v-.77l.48.05a1.8,1.8,0,0,0,1.12-.34,2.67,2.67,0,0,0,.72-1.17l.41-1.14-3.18-8.38h1Z" />
              <path d="M299.91,309.88A5.07,5.07,0,0,1,299,313a2.78,2.78,0,0,1-2.34,1.16A3.15,3.15,0,0,1,294,313v4.31h-.93v-11.7H294l0,1.19a3,3,0,0,1,2.65-1.35,2.78,2.78,0,0,1,2.37,1.15,5.17,5.17,0,0,1,.87,3.19Zm-.94-.17a4.36,4.36,0,0,0-.66-2.55,2.14,2.14,0,0,0-1.85-.94,2.5,2.5,0,0,0-2.43,1.62v4.05a2.5,2.5,0,0,0,1,1.11,2.75,2.75,0,0,0,1.48.39,2.12,2.12,0,0,0,1.84-.95A4.7,4.7,0,0,0,299,309.71Z" />
              <path d="M305.88,306.36a4,4,0,0,0-.63-.05,2.26,2.26,0,0,0-1.48.49,2.64,2.64,0,0,0-.86,1.41V314H302v-8.45h.91l0,1.34a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M306.69,309.67a5,5,0,0,1,.47-2.2,3.55,3.55,0,0,1,3.3-2.06,3.47,3.47,0,0,1,2.73,1.18,4.57,4.57,0,0,1,1.05,3.14v.2a4.94,4.94,0,0,1-.48,2.2,3.45,3.45,0,0,1-1.33,1.51,3.58,3.58,0,0,1-2,.54,3.48,3.48,0,0,1-2.73-1.19,4.55,4.55,0,0,1-1.05-3.14Zm.94.26a3.81,3.81,0,0,0,.78,2.48,2.64,2.64,0,0,0,4.11,0,4,4,0,0,0,.79-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.53,2.53,0,0,0-1.48-.44,2.47,2.47,0,0,0-2,1,3.93,3.93,0,0,0-.79,2.56Z" />
              <path d="M315.79,309.71a5.06,5.06,0,0,1,.88-3.13,2.83,2.83,0,0,1,2.39-1.17,2.94,2.94,0,0,1,2.61,1.35V302h.93v12h-.88l0-1.13a3,3,0,0,1-2.64,1.29,2.82,2.82,0,0,1-2.35-1.18,5.09,5.09,0,0,1-.9-3.18Zm.95.17a4.41,4.41,0,0,0,.64,2.55,2.12,2.12,0,0,0,1.83.93,2.54,2.54,0,0,0,2.46-1.52v-4a2.51,2.51,0,0,0-2.44-1.65,2.13,2.13,0,0,0-1.84.93A4.78,4.78,0,0,0,316.74,309.88Z" />
              <path d="M330.49,313a3,3,0,0,1-2.69,1.23,2.64,2.64,0,0,1-2.06-.79,3.47,3.47,0,0,1-.71-2.32v-5.5H326V311c0,1.61.65,2.41,2,2.41a2.4,2.4,0,0,0,2.57-1.68v-6.11h.94V314h-.91Z" />
              <path d="M337,313.39a2.52,2.52,0,0,0,1.64-.55,1.93,1.93,0,0,0,.71-1.43h.9a2.71,2.71,0,0,1-.48,1.41,3.15,3.15,0,0,1-1.19,1,3.55,3.55,0,0,1-1.58.36,3.28,3.28,0,0,1-2.64-1.16,4.72,4.72,0,0,1-1-3.11v-.28a5.31,5.31,0,0,1,.44-2.21,3.41,3.41,0,0,1,1.25-1.49,3.46,3.46,0,0,1,1.91-.52,3.3,3.3,0,0,1,2.3.83,3,3,0,0,1,1,2.18h-.9a2.3,2.3,0,0,0-.71-1.6,2.55,2.55,0,0,0-3.61.3,4.1,4.1,0,0,0-.7,2.56V310a4,4,0,0,0,.7,2.52A2.35,2.35,0,0,0,337,313.39Z" />
              <path d="M343.54,303.4v2.17h1.75v.76h-1.75V312a1.85,1.85,0,0,0,.25,1,1,1,0,0,0,.85.34,4.72,4.72,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.14,1.68,1.68,0,0,1-1.4-.56,2.66,2.66,0,0,1-.44-1.65v-5.64h-1.55v-.76h1.55V303.4Z" />
              <path d="M351.79,314v-7.69h-1.4v-.76h1.4v-1a2.78,2.78,0,0,1,.66-2,2.43,2.43,0,0,1,1.84-.7,3.43,3.43,0,0,1,1,.14l-.07.77a3.76,3.76,0,0,0-.85-.09,1.52,1.52,0,0,0-1.2.48,2,2,0,0,0-.43,1.37v1.05h2v.76h-2V314Z" />
              <path d="M359.5,314.18a3.62,3.62,0,0,1-1.95-.54,3.52,3.52,0,0,1-1.35-1.48,4.52,4.52,0,0,1-.49-2.13v-.33a4.94,4.94,0,0,1,.48-2.2A3.7,3.7,0,0,1,357.5,306a3.26,3.26,0,0,1,1.83-.56,3,3,0,0,1,2.44,1,4.27,4.27,0,0,1,.9,2.87v.52h-6V310a3.56,3.56,0,0,0,.83,2.4,2.62,2.62,0,0,0,2.07,1,3,3,0,0,0,1.33-.28,2.9,2.9,0,0,0,1-.87l.58.44A3.39,3.39,0,0,1,359.5,314.18Zm-.17-8a2.33,2.33,0,0,0-1.78.77,3.64,3.64,0,0,0-.88,2.08h5.07V309a3.05,3.05,0,0,0-.69-2A2.16,2.16,0,0,0,359.33,306.21Z" />
              <path d="M369.83,314a4.81,4.81,0,0,1-.18-1.18,3.28,3.28,0,0,1-1.25,1,3.9,3.9,0,0,1-1.62.35,2.84,2.84,0,0,1-2-.68,2.24,2.24,0,0,1-.75-1.72,2.27,2.27,0,0,1,1-2,5,5,0,0,1,2.87-.72h1.69v-1a1.81,1.81,0,0,0-.56-1.42,2.27,2.27,0,0,0-1.62-.52,2.55,2.55,0,0,0-1.62.5,1.47,1.47,0,0,0-.64,1.2h-.94a2.15,2.15,0,0,1,.94-1.74,3.6,3.6,0,0,1,2.3-.74,3.3,3.3,0,0,1,2.23.71,2.5,2.5,0,0,1,.84,2v4a5,5,0,0,0,.26,1.84V314Zm-2.94-.67a3.07,3.07,0,0,0,1.68-.46,2.61,2.61,0,0,0,1.07-1.21v-1.86H368a4.12,4.12,0,0,0-2.19.52,1.54,1.54,0,0,0-.79,1.36,1.52,1.52,0,0,0,.53,1.18A2,2,0,0,0,366.89,313.35Z" />
              <path d="M374.52,303.4v2.17h1.75v.76h-1.75V312a1.77,1.77,0,0,0,.25,1,1,1,0,0,0,.85.34,4.72,4.72,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.14,1.68,1.68,0,0,1-1.4-.56,2.66,2.66,0,0,1-.44-1.65v-5.64H372v-.76h1.55V303.4Z" />
              <path d="M383.64,313a3,3,0,0,1-2.69,1.23,2.64,2.64,0,0,1-2.06-.79,3.42,3.42,0,0,1-.72-2.32v-5.5h.93V311c0,1.61.65,2.41,2,2.41a2.4,2.4,0,0,0,2.57-1.68v-6.11h.93V314h-.9Z" />
              <path d="M390.9,306.36a4.23,4.23,0,0,0-.63-.05,2.24,2.24,0,0,0-1.48.49,2.65,2.65,0,0,0-.87,1.41V314H387v-8.45h.92v1.34a2.49,2.49,0,0,1,2.39-1.5,1.46,1.46,0,0,1,.61.1Z" />
              <path d="M395.5,314.18a3.62,3.62,0,0,1-1.95-.54,3.52,3.52,0,0,1-1.35-1.48,4.52,4.52,0,0,1-.49-2.13v-.33a4.94,4.94,0,0,1,.48-2.2A3.7,3.7,0,0,1,393.5,306a3.26,3.26,0,0,1,1.83-.56,3,3,0,0,1,2.44,1,4.27,4.27,0,0,1,.9,2.87v.52h-6V310a3.56,3.56,0,0,0,.83,2.4,2.62,2.62,0,0,0,2.07,1,3,3,0,0,0,1.33-.28,2.9,2.9,0,0,0,1-.87l.58.44A3.39,3.39,0,0,1,395.5,314.18Zm-.17-8a2.33,2.33,0,0,0-1.78.77,3.64,3.64,0,0,0-.88,2.08h5.07V309a3.05,3.05,0,0,0-.69-2A2.16,2.16,0,0,0,395.33,306.21Z" />
              <path d="M405.56,311.87a1.35,1.35,0,0,0-.56-1.12,4.36,4.36,0,0,0-1.69-.66,7.86,7.86,0,0,1-1.74-.54,2.29,2.29,0,0,1-.92-.76,1.86,1.86,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.41,3.41,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.23,2.23,0,0,1,.86,1.82h-.94a1.52,1.52,0,0,0-.62-1.23,2.45,2.45,0,0,0-1.57-.49,2.4,2.4,0,0,0-1.48.41,1.27,1.27,0,0,0-.56,1.06,1.16,1.16,0,0,0,.47,1,4.66,4.66,0,0,0,1.69.64,7.86,7.86,0,0,1,1.85.62,2.38,2.38,0,0,1,.91.79,2.18,2.18,0,0,1-.57,2.82,3.66,3.66,0,0,1-2.25.65,3.77,3.77,0,0,1-2.4-.72,2.21,2.21,0,0,1-.92-1.81H401a1.66,1.66,0,0,0,.69,1.28,2.89,2.89,0,0,0,1.69.46A2.73,2.73,0,0,0,405,313,1.3,1.3,0,0,0,405.56,311.87Z" />
              <path d="M398,287.87a1.35,1.35,0,0,0-.56-1.12,4.36,4.36,0,0,0-1.69-.66,7.86,7.86,0,0,1-1.74-.54,2.29,2.29,0,0,1-.92-.76,1.86,1.86,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.41,3.41,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.23,2.23,0,0,1,.86,1.82H398a1.52,1.52,0,0,0-.62-1.23,2.45,2.45,0,0,0-1.57-.49,2.4,2.4,0,0,0-1.48.41,1.27,1.27,0,0,0-.56,1.06,1.16,1.16,0,0,0,.47,1,4.66,4.66,0,0,0,1.69.64,7.86,7.86,0,0,1,1.85.62,2.38,2.38,0,0,1,.91.79,2.18,2.18,0,0,1-.57,2.82,3.66,3.66,0,0,1-2.25.65,3.77,3.77,0,0,1-2.4-.72,2.21,2.21,0,0,1-.92-1.81h.94a1.66,1.66,0,0,0,.69,1.28,2.89,2.89,0,0,0,1.69.46,2.73,2.73,0,0,0,1.58-.43A1.3,1.3,0,0,0,398,287.87Z" />
            </g>
          </HoverCircle>
          <HoverCircle className="hover-circle right-hover">
            <circle
              id="right-hover"
              cx="348.76"
              cy="263.58"
              r="126.93"
              fill="#fff"
            />
            <g id="right-copy">
              <path
                d="M295,209h-2.09v-5.36h-5.4V209h-2.1V196.93h2.1v5h5.4v-5H295Z"
                fill="#82c074"
              />
              <path
                d="M301.25,209.19a4.18,4.18,0,0,1-3.1-1.21,4.38,4.38,0,0,1-1.2-3.22v-.25a5.46,5.46,0,0,1,.52-2.4,3.88,3.88,0,0,1,3.55-2.24,3.55,3.55,0,0,1,2.84,1.17,5,5,0,0,1,1,3.31v.82H299a2.71,2.71,0,0,0,.74,1.76,2.24,2.24,0,0,0,1.64.64,2.73,2.73,0,0,0,2.26-1.12l1.09,1a3.61,3.61,0,0,1-1.45,1.25A4.44,4.44,0,0,1,301.25,209.19Zm-.24-7.7a1.69,1.69,0,0,0-1.34.58,3.08,3.08,0,0,0-.65,1.62h3.84v-.15a2.39,2.39,0,0,0-.54-1.53A1.66,1.66,0,0,0,301,201.49Z"
                fill="#82c074"
              />
              <path d="M308.57,209h-2V196.27h2Z" fill="#82c074" />
              <path
                d="M318.63,204.62a5.32,5.32,0,0,1-.95,3.32,3.26,3.26,0,0,1-4.9.27v4.26h-2V200h1.86l.08.91a3,3,0,0,1,2.42-1.08,3,3,0,0,1,2.57,1.23,5.43,5.43,0,0,1,.94,3.4Zm-2-.17a3.75,3.75,0,0,0-.54-2.14,1.74,1.74,0,0,0-1.53-.79,1.85,1.85,0,0,0-1.77,1v4a1.86,1.86,0,0,0,1.79,1,1.72,1.72,0,0,0,1.5-.77A4.06,4.06,0,0,0,316.62,204.45Z"
                fill="#82c074"
              />
              <path
                d="M326.46,200l.06.94a3.23,3.23,0,0,1,2.59-1.11,2.5,2.5,0,0,1,2.47,1.38,3.18,3.18,0,0,1,2.75-1.38,2.82,2.82,0,0,1,2.21.82,3.6,3.6,0,0,1,.75,2.43V209h-2v-5.84a1.81,1.81,0,0,0-.37-1.26,1.66,1.66,0,0,0-1.24-.4,1.7,1.7,0,0,0-1.12.37,1.93,1.93,0,0,0-.61,1V209h-2v-5.91a1.44,1.44,0,0,0-1.62-1.59,1.78,1.78,0,0,0-1.72,1V209h-2v-9Z"
                fill="#82c074"
              />
              <path
                d="M343.31,209.19a4.18,4.18,0,0,1-3.1-1.21,4.38,4.38,0,0,1-1.2-3.22v-.25a5.46,5.46,0,0,1,.52-2.4,3.88,3.88,0,0,1,3.55-2.24,3.55,3.55,0,0,1,2.84,1.17,5,5,0,0,1,1,3.31v.82h-5.87a2.71,2.71,0,0,0,.74,1.76,2.24,2.24,0,0,0,1.64.64,2.73,2.73,0,0,0,2.26-1.12l1.09,1a3.61,3.61,0,0,1-1.45,1.25A4.44,4.44,0,0,1,343.31,209.19Zm-.24-7.7a1.69,1.69,0,0,0-1.34.58,3.08,3.08,0,0,0-.65,1.62h3.84v-.15a2.39,2.39,0,0,0-.54-1.53A1.66,1.66,0,0,0,343.07,201.49Z"
                fill="#82c074"
              />
              <path
                d="M354.61,200l.06.94a3.22,3.22,0,0,1,2.59-1.11,2.47,2.47,0,0,1,2.46,1.38,3.2,3.2,0,0,1,2.76-1.38,2.82,2.82,0,0,1,2.21.82,3.65,3.65,0,0,1,.75,2.43V209h-2v-5.84a1.81,1.81,0,0,0-.38-1.26,1.63,1.63,0,0,0-1.23-.4,1.73,1.73,0,0,0-1.13.37,2,2,0,0,0-.61,1V209h-2v-5.91a1.43,1.43,0,0,0-1.61-1.59,1.77,1.77,0,0,0-1.72,1V209h-2v-9Z"
                fill="#82c074"
              />
              <path
                d="M372.87,209a3.18,3.18,0,0,1-.23-.84,3.38,3.38,0,0,1-4.57.23,2.46,2.46,0,0,1-.85-1.91,2.56,2.56,0,0,1,1.07-2.2,5.16,5.16,0,0,1,3-.77h1.23v-.59a1.57,1.57,0,0,0-.39-1.11,1.53,1.53,0,0,0-1.18-.42,1.77,1.77,0,0,0-1.13.34,1,1,0,0,0-.44.88h-2a2.18,2.18,0,0,1,.49-1.38,3.25,3.25,0,0,1,1.33-1,4.57,4.57,0,0,1,1.88-.37,3.84,3.84,0,0,1,2.52.79,2.87,2.87,0,0,1,1,2.23v4.05a4.56,4.56,0,0,0,.34,1.94V209Zm-2.21-1.45a2.34,2.34,0,0,0,1.12-.29,2,2,0,0,0,.79-.78v-1.7h-1.08a3,3,0,0,0-1.69.39,1.26,1.26,0,0,0-.56,1.11,1.2,1.2,0,0,0,.38.92A1.5,1.5,0,0,0,370.66,207.57Z"
                fill="#82c074"
              />
              <path
                d="M378.58,200l.05,1a3.23,3.23,0,0,1,2.62-1.21q2.81,0,2.85,3.21V209h-2V203.2a1.91,1.91,0,0,0-.37-1.27,1.58,1.58,0,0,0-1.21-.41,2,2,0,0,0-1.82,1.11V209h-2v-9Z"
                fill="#82c074"
              />
              <path
                d="M391.53,209a3.11,3.11,0,0,1-.24-.84,3.37,3.37,0,0,1-4.56.23,2.47,2.47,0,0,1-.86-1.91,2.56,2.56,0,0,1,1.07-2.2,5.18,5.18,0,0,1,3.05-.77h1.24v-.59a1.41,1.41,0,0,0-1.58-1.53,1.77,1.77,0,0,0-1.13.34,1.08,1.08,0,0,0-.44.88h-2a2.24,2.24,0,0,1,.49-1.38,3.25,3.25,0,0,1,1.33-1,4.62,4.62,0,0,1,1.89-.37,3.79,3.79,0,0,1,2.51.79,2.8,2.8,0,0,1,1,2.23v4.05a4.72,4.72,0,0,0,.34,1.94V209Zm-2.22-1.45a2.31,2.31,0,0,0,1.12-.29,2,2,0,0,0,.8-.78v-1.7h-1.09a3,3,0,0,0-1.69.39,1.28,1.28,0,0,0-.56,1.11,1.17,1.17,0,0,0,.39.92A1.48,1.48,0,0,0,389.31,207.57Z"
                fill="#82c074"
              />
              <path
                d="M395,204.46a5.23,5.23,0,0,1,1-3.34,3.15,3.15,0,0,1,2.61-1.25,3,3,0,0,1,2.41,1.07l.09-.9h1.82v8.71a3.59,3.59,0,0,1-1.1,2.78,4.19,4.19,0,0,1-3,1,4.73,4.73,0,0,1-1.93-.42,3.48,3.48,0,0,1-1.43-1.07l1-1.21a2.89,2.89,0,0,0,2.29,1.1,2.21,2.21,0,0,0,1.58-.54,2.08,2.08,0,0,0,.58-1.6v-.61a3,3,0,0,1-2.32,1,3.15,3.15,0,0,1-2.58-1.26A5.45,5.45,0,0,1,395,204.46Zm2,.18a3.69,3.69,0,0,0,.55,2.13,1.8,1.8,0,0,0,1.54.77,1.94,1.94,0,0,0,1.8-1v-4a1.9,1.9,0,0,0-1.79-1,1.81,1.81,0,0,0-1.55.79A4.13,4.13,0,0,0,397,204.64Z"
                fill="#82c074"
              />
              <path
                d="M409,209.19a4.19,4.19,0,0,1-3.11-1.21,4.37,4.37,0,0,1-1.19-3.22v-.25a5.32,5.32,0,0,1,.52-2.4,3.85,3.85,0,0,1,3.54-2.24,3.53,3.53,0,0,1,2.84,1.17,4.94,4.94,0,0,1,1,3.31v.82h-5.87a2.71,2.71,0,0,0,.74,1.76,2.25,2.25,0,0,0,1.64.64,2.72,2.72,0,0,0,2.26-1.12l1.09,1a3.64,3.64,0,0,1-1.44,1.25A4.44,4.44,0,0,1,409,209.19Zm-.24-7.7a1.68,1.68,0,0,0-1.34.58,3.08,3.08,0,0,0-.66,1.62h3.85v-.15A2.51,2.51,0,0,0,410,202,1.67,1.67,0,0,0,408.71,201.49Z"
                fill="#82c074"
              />
              <path
                d="M305.28,220.44l.06.94a3.22,3.22,0,0,1,2.59-1.11,2.47,2.47,0,0,1,2.46,1.38,3.19,3.19,0,0,1,2.76-1.38,2.82,2.82,0,0,1,2.21.82,3.6,3.6,0,0,1,.74,2.43v5.9h-2v-5.84a1.81,1.81,0,0,0-.38-1.26,1.65,1.65,0,0,0-1.23-.4,1.73,1.73,0,0,0-1.13.37,2,2,0,0,0-.61,1v6.16h-2v-5.91a1.44,1.44,0,0,0-1.62-1.59,1.76,1.76,0,0,0-1.71,1v6.51h-2v-9Z"
                fill="#82c074"
              />
              <path
                d="M321.32,226.53l1.82-6.09h2.15l-3.57,10.34a2.89,2.89,0,0,1-2.79,2.27,3.65,3.65,0,0,1-1-.15v-1.57l.39,0a2,2,0,0,0,1.15-.27,1.85,1.85,0,0,0,.6-.94l.3-.77-3.16-8.93h2.18Z"
                fill="#82c074"
              />
              <path
                d="M331.39,229.42v-7.49H330v-1.49h1.37v-.82a3.09,3.09,0,0,1,.83-2.31,3.21,3.21,0,0,1,2.33-.82,4.69,4.69,0,0,1,1.13.15l-.05,1.58a4.31,4.31,0,0,0-.77-.07,1.3,1.3,0,0,0-1.45,1.49v.8h1.83v1.49h-1.83v7.49Z"
                fill="#82c074"
              />
              <path
                d="M336.71,218.1a1.06,1.06,0,0,1,.3-.77,1.34,1.34,0,0,1,1.69,0,1.06,1.06,0,0,1,.3.77,1,1,0,0,1-.3.76,1.31,1.31,0,0,1-1.69,0A1,1,0,0,1,336.71,218.1Zm2.15,11.32h-2v-9h2Z"
                fill="#82c074"
              />
              <path
                d="M342.92,220.44l.06,1a3.23,3.23,0,0,1,2.62-1.21q2.81,0,2.85,3.21v5.94h-2V223.6a1.91,1.91,0,0,0-.37-1.27,1.58,1.58,0,0,0-1.21-.41A1.94,1.94,0,0,0,343,223v6.39h-2v-9Z"
                fill="#82c074"
              />
              <path
                d="M355.87,229.42a3.18,3.18,0,0,1-.23-.84,3.4,3.4,0,0,1-4.56.23,2.47,2.47,0,0,1-.86-1.91,2.56,2.56,0,0,1,1.07-2.2,5.16,5.16,0,0,1,3-.77h1.23v-.59a1.57,1.57,0,0,0-.39-1.11,1.53,1.53,0,0,0-1.18-.42,1.77,1.77,0,0,0-1.13.34,1,1,0,0,0-.44.88h-2a2.18,2.18,0,0,1,.49-1.38,3.25,3.25,0,0,1,1.33-1,4.57,4.57,0,0,1,1.88-.37,3.84,3.84,0,0,1,2.52.79,2.87,2.87,0,0,1,1,2.23v4.05a4.56,4.56,0,0,0,.34,1.94v.14ZM353.66,228a2.31,2.31,0,0,0,1.12-.29,2,2,0,0,0,.79-.78v-1.7h-1.08a3,3,0,0,0-1.69.39,1.28,1.28,0,0,0-.56,1.11,1.2,1.2,0,0,0,.38.92A1.52,1.52,0,0,0,353.66,228Z"
                fill="#82c074"
              />
              <path
                d="M361.58,220.44l.05,1a3.23,3.23,0,0,1,2.62-1.21q2.81,0,2.85,3.21v5.94h-2V223.6a1.91,1.91,0,0,0-.37-1.27,1.58,1.58,0,0,0-1.21-.41,2,2,0,0,0-1.82,1.11v6.39h-2v-9Z"
                fill="#82c074"
              />
              <path
                d="M372.85,228a1.79,1.79,0,0,0,1.25-.44,1.48,1.48,0,0,0,.53-1.08h1.9A2.88,2.88,0,0,1,376,228a3.39,3.39,0,0,1-1.33,1.15,4,4,0,0,1-1.81.42,3.83,3.83,0,0,1-3-1.22,4.85,4.85,0,0,1-1.1-3.37v-.2a4.79,4.79,0,0,1,1.1-3.29,3.79,3.79,0,0,1,3-1.23,3.69,3.69,0,0,1,2.61.94,3.33,3.33,0,0,1,1,2.45h-1.9a1.86,1.86,0,0,0-.52-1.27,1.71,1.71,0,0,0-1.26-.5,1.78,1.78,0,0,0-1.51.71,3.53,3.53,0,0,0-.54,2.15v.33a3.63,3.63,0,0,0,.53,2.18A1.75,1.75,0,0,0,372.85,228Z"
                fill="#82c074"
              />
              <path
                d="M382,229.58a4.2,4.2,0,0,1-3.11-1.2,4.41,4.41,0,0,1-1.19-3.22v-.25a5.46,5.46,0,0,1,.52-2.4,3.91,3.91,0,0,1,1.46-1.65,3.86,3.86,0,0,1,2.09-.59,3.51,3.51,0,0,1,2.83,1.17,4.94,4.94,0,0,1,1,3.31v.82h-5.87a2.66,2.66,0,0,0,.74,1.76,2.22,2.22,0,0,0,1.64.64,2.74,2.74,0,0,0,2.26-1.12l1.08,1a3.64,3.64,0,0,1-1.44,1.25A4.44,4.44,0,0,1,382,229.58Zm-.24-7.69a1.69,1.69,0,0,0-1.34.58,3,3,0,0,0-.65,1.62h3.84v-.15a2.39,2.39,0,0,0-.54-1.53A1.66,1.66,0,0,0,381.77,221.89Z"
                fill="#82c074"
              />
              <path
                d="M392.21,227a.89.89,0,0,0-.44-.82,4.64,4.64,0,0,0-1.47-.5,7.92,7.92,0,0,1-1.72-.55,2.29,2.29,0,0,1-1.51-2.12,2.37,2.37,0,0,1,1-1.94,3.86,3.86,0,0,1,2.49-.78,4,4,0,0,1,2.6.8,2.53,2.53,0,0,1,1,2.07h-2a1.25,1.25,0,0,0-.44-1,1.63,1.63,0,0,0-1.14-.39,1.76,1.76,0,0,0-1.09.31,1,1,0,0,0-.41.82.81.81,0,0,0,.39.72,5.22,5.22,0,0,0,1.57.52,7.82,7.82,0,0,1,1.87.62,2.71,2.71,0,0,1,1,.87,2.22,2.22,0,0,1,.33,1.23,2.34,2.34,0,0,1-1,2,4.27,4.27,0,0,1-2.63.75,4.7,4.7,0,0,1-2-.39,3.22,3.22,0,0,1-1.35-1.1,2.6,2.6,0,0,1-.48-1.5h2a1.45,1.45,0,0,0,.54,1.1,2.53,2.53,0,0,0,2.53.08A.91.91,0,0,0,392.21,227Z"
                fill="#82c074"
              />
              <path d="M265.49,241.33v8.25a4.26,4.26,0,0,1-.54,2.13,3.6,3.6,0,0,1-1.5,1.39,5,5,0,0,1-2.22.49,4.36,4.36,0,0,1-3.06-1.05,3.79,3.79,0,0,1-1.19-2.88v-8.33h1v8.18a3.14,3.14,0,0,0,.87,2.37,3.75,3.75,0,0,0,4.73,0,3.16,3.16,0,0,0,.87-2.36v-8.19Z" />
              <path d="M269.05,244.44l0,1.53a3.62,3.62,0,0,1,1.26-1.26,3.31,3.31,0,0,1,1.67-.44,2.68,2.68,0,0,1,2.15.82,3.63,3.63,0,0,1,.71,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M277,248.85a5.45,5.45,0,0,1,.93-3.34,3,3,0,0,1,2.54-1.24,3.11,3.11,0,0,1,2.77,1.44v-5h1v12.75h-.93l0-1.2a3.21,3.21,0,0,1-2.81,1.37,3,3,0,0,1-2.5-1.25A5.49,5.49,0,0,1,277,249Zm1,.17a4.67,4.67,0,0,0,.69,2.71,2.24,2.24,0,0,0,1.95,1,2.66,2.66,0,0,0,2.6-1.62v-4.21a2.65,2.65,0,0,0-2.59-1.75,2.25,2.25,0,0,0-1.95,1A5,5,0,0,0,278,249Z" />
              <path d="M290.36,253.59a3.87,3.87,0,0,1-3.51-2.14,4.94,4.94,0,0,1-.51-2.26v-.36a5.21,5.21,0,0,1,.5-2.33,3.86,3.86,0,0,1,1.4-1.63,3.42,3.42,0,0,1,1.94-.6,3.21,3.21,0,0,1,2.59,1.12,4.51,4.51,0,0,1,1,3.05V249h-6.4v.19a3.78,3.78,0,0,0,.87,2.54,2.78,2.78,0,0,0,2.2,1,3.29,3.29,0,0,0,1.41-.29,3.21,3.21,0,0,0,1.11-.93l.62.47A3.62,3.62,0,0,1,290.36,253.59Zm-.18-8.47a2.47,2.47,0,0,0-1.89.82,3.8,3.8,0,0,0-.93,2.21h5.39V248a3.34,3.34,0,0,0-.74-2.11A2.31,2.31,0,0,0,290.18,245.12Z" />
              <path d="M299.82,245.28a4.8,4.8,0,0,0-.67-.05,2.42,2.42,0,0,0-1.58.52,2.76,2.76,0,0,0-.91,1.5v6.17h-1v-9h1l0,1.43a2.62,2.62,0,0,1,2.53-1.6,1.65,1.65,0,0,1,.65.11Z" />
              <path d="M306.77,251.14a1.41,1.41,0,0,0-.6-1.19,4.53,4.53,0,0,0-1.79-.7,8.13,8.13,0,0,1-1.85-.58,2.51,2.51,0,0,1-1-.8,2,2,0,0,1-.32-1.15,2.1,2.1,0,0,1,.89-1.75,3.53,3.53,0,0,1,2.27-.7,3.66,3.66,0,0,1,2.41.75,2.36,2.36,0,0,1,.91,1.93h-1a1.62,1.62,0,0,0-.66-1.31,3,3,0,0,0-3.24-.09,1.35,1.35,0,0,0-.59,1.14,1.22,1.22,0,0,0,.49,1,5.25,5.25,0,0,0,1.8.68,8.53,8.53,0,0,1,2,.65,2.53,2.53,0,0,1,1,.84,2.15,2.15,0,0,1,.31,1.19,2.11,2.11,0,0,1-.92,1.81,3.87,3.87,0,0,1-2.39.69,4,4,0,0,1-2.54-.76,2.36,2.36,0,0,1-1-1.93h1a1.69,1.69,0,0,0,.73,1.36,3,3,0,0,0,1.79.49,2.81,2.81,0,0,0,1.68-.46A1.38,1.38,0,0,0,306.77,251.14Z" />
              <path d="M311.54,242.14v2.3h1.86v.81h-1.86v6a1.91,1.91,0,0,0,.27,1.12,1.05,1.05,0,0,0,.9.36,7,7,0,0,0,.8-.08l.05.81a3.38,3.38,0,0,1-1.07.14A1.75,1.75,0,0,1,311,253a2.79,2.79,0,0,1-.47-1.75v-6h-1.65v-.81h1.65v-2.3Z" />
              <path d="M321.13,253.42a4.37,4.37,0,0,1-.19-1.25,3.57,3.57,0,0,1-1.34,1.05,4.17,4.17,0,0,1-1.71.37,3,3,0,0,1-2.1-.73A2.36,2.36,0,0,1,315,251a2.42,2.42,0,0,1,1.09-2.08,5.27,5.27,0,0,1,3.05-.76h1.8v-1a2,2,0,0,0-.6-1.52,2.43,2.43,0,0,0-1.73-.55,2.75,2.75,0,0,0-1.72.53,1.6,1.6,0,0,0-.68,1.28h-1a2.31,2.31,0,0,1,1-1.85,3.81,3.81,0,0,1,2.45-.79A3.51,3.51,0,0,1,321,245a2.67,2.67,0,0,1,.89,2.1v4.25a5.29,5.29,0,0,0,.28,1.95v.1Zm-3.13-.71a3.3,3.3,0,0,0,1.79-.49,2.87,2.87,0,0,0,1.14-1.28v-2h-1.78a4.34,4.34,0,0,0-2.32.55A1.62,1.62,0,0,0,316,251a1.59,1.59,0,0,0,.56,1.26A2.11,2.11,0,0,0,318,252.71Z" />
              <path d="M325.51,244.44l0,1.53a3.62,3.62,0,0,1,1.26-1.26,3.31,3.31,0,0,1,1.67-.44,2.66,2.66,0,0,1,2.15.82,3.68,3.68,0,0,1,.72,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M333.5,248.85a5.38,5.38,0,0,1,.94-3.34,3,3,0,0,1,2.53-1.24,3.11,3.11,0,0,1,2.77,1.44v-5h1v12.75h-.93l0-1.2a3.21,3.21,0,0,1-2.8,1.37,3,3,0,0,1-2.51-1.25,5.49,5.49,0,0,1-.95-3.38Zm1,.17a4.67,4.67,0,0,0,.69,2.71,2.22,2.22,0,0,0,1.94,1,2.66,2.66,0,0,0,2.6-1.62v-4.21a2.64,2.64,0,0,0-2.59-1.75,2.25,2.25,0,0,0-1.95,1A5,5,0,0,0,334.51,249Z" />
              <path d="M348.39,244.44l0,1.46a3.36,3.36,0,0,1,1.24-1.22,3.46,3.46,0,0,1,1.66-.41A2.51,2.51,0,0,1,354,246a3.43,3.43,0,0,1,1.29-1.3,3.58,3.58,0,0,1,1.77-.45q2.9,0,2.95,3.16v6h-1V247.5a2.68,2.68,0,0,0-.52-1.78,2.11,2.11,0,0,0-1.67-.58,2.66,2.66,0,0,0-1.81.69,2.48,2.48,0,0,0-.82,1.65v5.94h-1v-6a2.49,2.49,0,0,0-.55-1.73,2.16,2.16,0,0,0-1.65-.56,2.5,2.5,0,0,0-1.61.53,3.18,3.18,0,0,0-1,1.59v6.16h-1v-9Z" />
              <path d="M365.39,252l2.63-7.55h1.07L365.25,255l-.2.46a2.41,2.41,0,0,1-2.28,1.64,2.66,2.66,0,0,1-.76-.12v-.82l.51.05a1.87,1.87,0,0,0,1.19-.36,2.78,2.78,0,0,0,.76-1.24l.44-1.21-3.38-8.91h1.09Z" />
              <path d="M380.14,251.14a1.41,1.41,0,0,0-.6-1.19,4.53,4.53,0,0,0-1.79-.7,8.13,8.13,0,0,1-1.85-.58,2.51,2.51,0,0,1-1-.8,2,2,0,0,1-.32-1.15,2.13,2.13,0,0,1,.89-1.75,3.53,3.53,0,0,1,2.27-.7,3.66,3.66,0,0,1,2.41.75,2.36,2.36,0,0,1,.91,1.93h-1a1.62,1.62,0,0,0-.66-1.31,3,3,0,0,0-3.24-.09,1.36,1.36,0,0,0-.6,1.14,1.23,1.23,0,0,0,.5,1,5.25,5.25,0,0,0,1.8.68,8.36,8.36,0,0,1,2,.65,2.53,2.53,0,0,1,1,.84,2.15,2.15,0,0,1,.31,1.19,2.11,2.11,0,0,1-.92,1.81,3.87,3.87,0,0,1-2.39.69,4,4,0,0,1-2.54-.76,2.33,2.33,0,0,1-1-1.93h1a1.69,1.69,0,0,0,.73,1.36,3,3,0,0,0,1.79.49,2.81,2.81,0,0,0,1.68-.46A1.38,1.38,0,0,0,380.14,251.14Z" />
              <path d="M390.59,249a5.4,5.4,0,0,1-.93,3.33,3,3,0,0,1-2.49,1.24,3.36,3.36,0,0,1-2.83-1.3v4.58h-1V244.44h.92l.05,1.27a3.18,3.18,0,0,1,2.81-1.44,3,3,0,0,1,2.53,1.22,5.57,5.57,0,0,1,.92,3.39Zm-1-.17a4.57,4.57,0,0,0-.71-2.72,2.26,2.26,0,0,0-2-1,2.71,2.71,0,0,0-1.57.44,2.76,2.76,0,0,0-1,1.27v4.31a2.59,2.59,0,0,0,1,1.18,2.93,2.93,0,0,0,1.56.41,2.28,2.28,0,0,0,2-1A5,5,0,0,0,389.59,248.85Z" />
              <path d="M396.27,253.59a3.87,3.87,0,0,1-3.52-2.14,4.94,4.94,0,0,1-.51-2.26v-.36a5.35,5.35,0,0,1,.5-2.33,3.93,3.93,0,0,1,1.4-1.63,3.42,3.42,0,0,1,1.94-.6,3.24,3.24,0,0,1,2.6,1.12,4.51,4.51,0,0,1,.95,3.05V249h-6.4v.19a3.78,3.78,0,0,0,.87,2.54,2.8,2.8,0,0,0,2.21,1,2.95,2.95,0,0,0,2.51-1.22l.62.47A3.61,3.61,0,0,1,396.27,253.59Zm-.19-8.47a2.5,2.5,0,0,0-1.89.82,3.8,3.8,0,0,0-.93,2.21h5.39V248a3.34,3.34,0,0,0-.74-2.11A2.31,2.31,0,0,0,396.08,245.12Z" />
              <path d="M402.52,244.44l0,1.53a3.62,3.62,0,0,1,1.26-1.26,3.28,3.28,0,0,1,1.67-.44,2.68,2.68,0,0,1,2.15.82,3.63,3.63,0,0,1,.71,2.44v5.89h-1v-5.9a2.72,2.72,0,0,0-.51-1.8,2,2,0,0,0-1.6-.58,2.51,2.51,0,0,0-1.63.57,3.33,3.33,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M410.51,248.85a5.45,5.45,0,0,1,.93-3.34,3,3,0,0,1,2.54-1.24,3.11,3.11,0,0,1,2.77,1.44v-5h1v12.75h-.93l0-1.2a3.21,3.21,0,0,1-2.81,1.37,3,3,0,0,1-2.5-1.25,5.49,5.49,0,0,1-.95-3.38Zm1,.17a4.67,4.67,0,0,0,.69,2.71,2.24,2.24,0,0,0,1.95,1,2.66,2.66,0,0,0,2.6-1.62v-4.21a2.65,2.65,0,0,0-2.59-1.75,2.25,2.25,0,0,0-2,1A5,5,0,0,0,411.51,249Z" />
              <path d="M420.3,241.84a.67.67,0,0,1,.18-.47.65.65,0,0,1,.5-.19.66.66,0,0,1,.5.19.68.68,0,0,1,.19.47.65.65,0,0,1-.19.47.66.66,0,0,1-.5.19.65.65,0,0,1-.5-.19A.64.64,0,0,1,420.3,241.84Zm1.17,11.58h-1v-9h1Z" />
              <path d="M425.11,244.44l0,1.53a3.56,3.56,0,0,1,1.27-1.26,3.24,3.24,0,0,1,1.66-.44,2.66,2.66,0,0,1,2.15.82,3.68,3.68,0,0,1,.72,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M433.1,248.85a5.46,5.46,0,0,1,.94-3.35,3,3,0,0,1,2.53-1.23,3.12,3.12,0,0,1,2.79,1.46l.05-1.29h.93v8.8a3.78,3.78,0,0,1-1,2.74,3.46,3.46,0,0,1-2.63,1,4,4,0,0,1-1.79-.42,3.45,3.45,0,0,1-1.34-1.12l.56-.6a3.12,3.12,0,0,0,2.5,1.3,2.58,2.58,0,0,0,1.95-.74,2.94,2.94,0,0,0,.72-2v-1.13a3.24,3.24,0,0,1-2.78,1.33,3,3,0,0,1-2.51-1.25,5.43,5.43,0,0,1-.95-3.36Zm1,.17a4.67,4.67,0,0,0,.69,2.71,2.22,2.22,0,0,0,1.94,1,2.67,2.67,0,0,0,2.6-1.64V246.9a2.81,2.81,0,0,0-1-1.31,2.69,2.69,0,0,0-1.57-.45,2.26,2.26,0,0,0-2,1A5,5,0,0,0,434.11,249Z" />
              <path d="M247.21,270a3.65,3.65,0,0,1,1.25-1.25,3.33,3.33,0,0,1,1.67-.44,2.66,2.66,0,0,1,2.15.82,3.68,3.68,0,0,1,.72,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1,1.56v6.15h-1V264.67h1Z" />
              <path d="M261.22,277.42a4.76,4.76,0,0,1-.19-1.25,3.54,3.54,0,0,1-1.33,1.05,4.17,4.17,0,0,1-1.71.37,3,3,0,0,1-2.1-.73,2.36,2.36,0,0,1-.8-1.82,2.4,2.4,0,0,1,1.09-2.08,5.24,5.24,0,0,1,3-.76H261v-1a2,2,0,0,0-.59-1.52,2.45,2.45,0,0,0-1.73-.55,2.75,2.75,0,0,0-1.72.53,1.6,1.6,0,0,0-.68,1.28h-1a2.32,2.32,0,0,1,1-1.85,3.79,3.79,0,0,1,2.45-.79,3.51,3.51,0,0,1,2.37.75,2.67,2.67,0,0,1,.89,2.1v4.25a5.09,5.09,0,0,0,.28,1.95v.1Zm-3.12-.71a3.24,3.24,0,0,0,1.78-.49,2.76,2.76,0,0,0,1.14-1.28v-2h-1.77a4.38,4.38,0,0,0-2.33.55,1.62,1.62,0,0,0-.84,1.44,1.6,1.6,0,0,0,.57,1.26A2.09,2.09,0,0,0,258.1,276.71Z" />
              <path d="M271.89,273a5.4,5.4,0,0,1-.93,3.33,3,3,0,0,1-2.5,1.24,3.23,3.23,0,0,1-2.84-1.4l0,1.23h-.93V264.67h1v5.07a3.12,3.12,0,0,1,2.8-1.47,3,3,0,0,1,2.52,1.22,5.45,5.45,0,0,1,.93,3.38Zm-1-.17a4.69,4.69,0,0,0-.69-2.75,2.23,2.23,0,0,0-1.94-1,2.78,2.78,0,0,0-1.62.46,2.9,2.9,0,0,0-1,1.38v4a2.66,2.66,0,0,0,2.64,1.69,2.22,2.22,0,0,0,1.92-1A5,5,0,0,0,270.89,272.85Z" />
              <path d="M274,265.84a.64.64,0,0,1,.19-.47.63.63,0,0,1,.49-.19.69.69,0,0,1,.51.19.67.67,0,0,1,.18.47.64.64,0,0,1-.18.47.69.69,0,0,1-.51.19.63.63,0,0,1-.49-.19A.61.61,0,0,1,274,265.84Zm1.17,11.58h-1v-9h1Z" />
              <path d="M279.44,266.14v2.3h1.85v.81h-1.85v6a1.91,1.91,0,0,0,.26,1.12,1.07,1.07,0,0,0,.91.36,7.18,7.18,0,0,0,.8-.08l0,.81a3.31,3.31,0,0,1-1.06.14,1.77,1.77,0,0,1-1.49-.6,2.79,2.79,0,0,1-.46-1.75v-6h-1.65v-.81h1.65v-2.3Z" />
              <path d="M288.73,275.14a1.42,1.42,0,0,0-.59-1.19,4.59,4.59,0,0,0-1.79-.7,8.07,8.07,0,0,1-1.86-.58,2.47,2.47,0,0,1-1-.8,2,2,0,0,1-.32-1.15,2.12,2.12,0,0,1,.88-1.75,3.56,3.56,0,0,1,2.28-.7,3.68,3.68,0,0,1,2.41.75,2.36,2.36,0,0,1,.91,1.93h-1a1.62,1.62,0,0,0-.66-1.31,2.57,2.57,0,0,0-1.66-.52,2.64,2.64,0,0,0-1.58.43,1.37,1.37,0,0,0-.59,1.14,1.23,1.23,0,0,0,.5,1,5.09,5.09,0,0,0,1.8.68,8.53,8.53,0,0,1,2,.65,2.5,2.5,0,0,1,1,.84,2.29,2.29,0,0,1-.6,3,3.87,3.87,0,0,1-2.39.69,4.07,4.07,0,0,1-2.55-.76,2.35,2.35,0,0,1-1-1.93h1a1.72,1.72,0,0,0,.74,1.36,2.94,2.94,0,0,0,1.79.49,2.75,2.75,0,0,0,1.67-.46A1.36,1.36,0,0,0,288.73,275.14Z" />
              <path d="M291.87,276.82a.67.67,0,0,1,.19-.49.64.64,0,0,1,.52-.21.68.68,0,0,1,.71.7.63.63,0,0,1-.19.48.74.74,0,0,1-.52.19.7.7,0,0,1-.52-.19A.63.63,0,0,1,291.87,276.82Z" />
              <path d="M307.23,274.45a2,2,0,0,0-.71-1.59,7.42,7.42,0,0,0-2.58-1.11,9.48,9.48,0,0,1-2.74-1.15,2.8,2.8,0,0,1-.09-4.57,4.75,4.75,0,0,1,2.92-.86,4.82,4.82,0,0,1,2.15.46,3.48,3.48,0,0,1,1.48,1.3,3.38,3.38,0,0,1,.52,1.85h-1a2.54,2.54,0,0,0-.85-2A3.29,3.29,0,0,0,304,266a3.62,3.62,0,0,0-2.21.62,1.92,1.92,0,0,0-.82,1.62,1.84,1.84,0,0,0,.74,1.5,6.85,6.85,0,0,0,2.36,1,11.19,11.19,0,0,1,2.47.92,3.52,3.52,0,0,1,1.26,1.14,2.91,2.91,0,0,1,.43,1.57,2.71,2.71,0,0,1-1.15,2.29,4.83,4.83,0,0,1-3,.87,6,6,0,0,1-2.34-.46,3.63,3.63,0,0,1-1.62-1.28,3.29,3.29,0,0,1-.55-1.88h1a2.41,2.41,0,0,0,.95,2,4,4,0,0,0,2.54.74,3.72,3.72,0,0,0,2.27-.62A2,2,0,0,0,307.23,274.45Z" />
              <path d="M316,276.28a3.23,3.23,0,0,1-2.86,1.31,2.78,2.78,0,0,1-2.19-.84,3.7,3.7,0,0,1-.76-2.47v-5.84h1v5.72c0,1.71.69,2.56,2.07,2.56a2.54,2.54,0,0,0,2.73-1.78v-6.5h1v9h-1Z" />
              <path d="M326.78,273a5.4,5.4,0,0,1-.93,3.33,3,3,0,0,1-2.49,1.24,3.33,3.33,0,0,1-2.82-1.3v4.58h-1V268.44h.92l0,1.27a3.2,3.2,0,0,1,2.82-1.44,3,3,0,0,1,2.53,1.22,5.57,5.57,0,0,1,.91,3.39Zm-1-.17a4.64,4.64,0,0,0-.71-2.72,2.27,2.27,0,0,0-2-1,2.71,2.71,0,0,0-1.57.44,2.74,2.74,0,0,0-1,1.27v4.31a2.63,2.63,0,0,0,1,1.18,3,3,0,0,0,1.57.41,2.28,2.28,0,0,0,2-1A5.11,5.11,0,0,0,325.79,272.85Z" />
              <path d="M336.2,273a5.4,5.4,0,0,1-.93,3.33,3,3,0,0,1-2.49,1.24,3.34,3.34,0,0,1-2.82-1.3v4.58h-1V268.44h.93l.05,1.27a3.17,3.17,0,0,1,2.81-1.44,3,3,0,0,1,2.53,1.22,5.57,5.57,0,0,1,.91,3.39Zm-1-.17a4.64,4.64,0,0,0-.71-2.72,2.27,2.27,0,0,0-2-1,2.64,2.64,0,0,0-2.58,1.71v4.31a2.63,2.63,0,0,0,1,1.18,3,3,0,0,0,1.57.41,2.28,2.28,0,0,0,2-1A5.11,5.11,0,0,0,335.21,272.85Z" />
              <path d="M337.86,272.8a5.22,5.22,0,0,1,.5-2.34,3.79,3.79,0,0,1,3.5-2.19,3.69,3.69,0,0,1,2.9,1.26,4.85,4.85,0,0,1,1.11,3.33v.21a5.22,5.22,0,0,1-.5,2.34A3.65,3.65,0,0,1,344,277a3.88,3.88,0,0,1-2.09.57,3.7,3.7,0,0,1-2.9-1.26,4.85,4.85,0,0,1-1.11-3.33Zm1,.27a4.05,4.05,0,0,0,.84,2.64,2.8,2.8,0,0,0,4.36,0,4.17,4.17,0,0,0,.84-2.72v-.19a4.5,4.5,0,0,0-.39-1.89,3,3,0,0,0-1.07-1.32,2.81,2.81,0,0,0-3.74.57,4.2,4.2,0,0,0-.84,2.73Z" />
              <path d="M352.08,269.28a4.94,4.94,0,0,0-.68,0,2.4,2.4,0,0,0-1.57.52,2.83,2.83,0,0,0-.92,1.5v6.17h-1v-9h1v1.43a2.64,2.64,0,0,1,2.53-1.6,1.67,1.67,0,0,1,.66.11Z" />
              <path d="M355.6,266.14v2.3h1.86v.81H355.6v6a1.91,1.91,0,0,0,.27,1.12,1.05,1.05,0,0,0,.9.36,7.08,7.08,0,0,0,.81-.08l0,.81a3.31,3.31,0,0,1-1.06.14,1.77,1.77,0,0,1-1.49-.6,2.79,2.79,0,0,1-.46-1.75v-6H353v-.81h1.65v-2.3Z" />
              <path d="M364.56,268.44l0,1.46a3.32,3.32,0,0,1,1.25-1.22,3.43,3.43,0,0,1,1.66-.41,2.53,2.53,0,0,1,2.7,1.75,3.47,3.47,0,0,1,1.28-1.3,3.59,3.59,0,0,1,1.78-.45c1.92,0,2.91,1.05,2.95,3.16v6h-1V271.5a2.68,2.68,0,0,0-.52-1.78,2.1,2.1,0,0,0-1.66-.58,2.63,2.63,0,0,0-1.81.69,2.44,2.44,0,0,0-.83,1.65v5.94h-1v-6a2.49,2.49,0,0,0-.55-1.73,2.13,2.13,0,0,0-1.64-.56,2.46,2.46,0,0,0-1.61.53,3.19,3.19,0,0,0-1,1.59v6.16h-1v-9Z" />
              <path d="M381.57,276l2.62-7.55h1.07L381.43,279l-.2.46a2.42,2.42,0,0,1-2.28,1.64,2.73,2.73,0,0,1-.77-.12v-.82l.52.05a1.84,1.84,0,0,0,1.18-.36,2.78,2.78,0,0,0,.77-1.24l.44-1.21-3.39-8.91h1.09Z" />
              <path d="M391.65,277.42v-8.17h-1.49v-.81h1.49v-1.09a3,3,0,0,1,.7-2.12,2.56,2.56,0,0,1,1.95-.73,3.62,3.62,0,0,1,1.06.14l-.08.82a3.71,3.71,0,0,0-.9-.1,1.62,1.62,0,0,0-1.28.51,2.09,2.09,0,0,0-.46,1.45v1.12h2.14v.81h-2.14v8.17Z" />
              <path d="M396.5,265.84a.67.67,0,0,1,.18-.47.66.66,0,0,1,.5-.19.65.65,0,0,1,.5.19.64.64,0,0,1,.19.47.61.61,0,0,1-.19.47.65.65,0,0,1-.5.19.66.66,0,0,1-.5-.19A.64.64,0,0,1,396.5,265.84Zm1.17,11.58h-1v-9h1Z" />
              <path d="M401.32,268.44l0,1.53a3.62,3.62,0,0,1,1.26-1.26,3.31,3.31,0,0,1,1.67-.44,2.68,2.68,0,0,1,2.15.82,3.63,3.63,0,0,1,.71,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.51,2.51,0,0,0-1.63.57,3.33,3.33,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M415.38,277.42a4.37,4.37,0,0,1-.19-1.25,3.57,3.57,0,0,1-1.34,1.05,4.17,4.17,0,0,1-1.71.37,3,3,0,0,1-2.1-.73,2.36,2.36,0,0,1-.8-1.82,2.42,2.42,0,0,1,1.09-2.08,5.27,5.27,0,0,1,3.05-.76h1.8v-1a2,2,0,0,0-.6-1.52,2.43,2.43,0,0,0-1.73-.55,2.7,2.7,0,0,0-1.71.53,1.57,1.57,0,0,0-.68,1.28h-1a2.29,2.29,0,0,1,1-1.85,3.78,3.78,0,0,1,2.44-.79,3.47,3.47,0,0,1,2.37.75,2.71,2.71,0,0,1,.9,2.1v4.25a5.33,5.33,0,0,0,.27,1.95v.1Zm-3.12-.71a3.29,3.29,0,0,0,1.78-.49,2.87,2.87,0,0,0,1.14-1.28v-2H413.4a4.34,4.34,0,0,0-2.32.55,1.67,1.67,0,0,0-.28,2.7A2.12,2.12,0,0,0,412.26,276.71Z" />
              <path d="M419.76,268.44l0,1.53a3.62,3.62,0,0,1,1.26-1.26,3.31,3.31,0,0,1,1.67-.44,2.66,2.66,0,0,1,2.15.82,3.68,3.68,0,0,1,.72,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M431.47,276.75a2.64,2.64,0,0,0,1.74-.59,2,2,0,0,0,.76-1.51h.95a2.76,2.76,0,0,1-.51,1.5,3.17,3.17,0,0,1-1.26,1.05,3.7,3.7,0,0,1-1.68.39,3.46,3.46,0,0,1-2.8-1.23,5,5,0,0,1-1-3.31v-.3a5.59,5.59,0,0,1,.46-2.34,3.58,3.58,0,0,1,1.33-1.58,3.7,3.7,0,0,1,2-.56,3.45,3.45,0,0,1,2.44.88,3.2,3.2,0,0,1,1,2.32H434a2.58,2.58,0,0,0-4.59-1.38,4.38,4.38,0,0,0-.75,2.72v.29a4.25,4.25,0,0,0,.75,2.68A2.46,2.46,0,0,0,431.47,276.75Z" />
              <path d="M436.85,265.84a.67.67,0,0,1,.18-.47.66.66,0,0,1,.5-.19.65.65,0,0,1,.5.19.64.64,0,0,1,.19.47.61.61,0,0,1-.19.47.65.65,0,0,1-.5.19.66.66,0,0,1-.5-.19A.64.64,0,0,1,436.85,265.84ZM438,277.42h-1v-9h1Z" />
              <path d="M446.39,277.42a4.76,4.76,0,0,1-.19-1.25,3.54,3.54,0,0,1-1.33,1.05,4.22,4.22,0,0,1-1.72.37,3,3,0,0,1-2.09-.73,2.33,2.33,0,0,1-.81-1.82,2.41,2.41,0,0,1,1.1-2.08,5.22,5.22,0,0,1,3-.76h1.8v-1a2,2,0,0,0-.59-1.52,2.46,2.46,0,0,0-1.73-.55,2.71,2.71,0,0,0-1.72.53,1.57,1.57,0,0,0-.68,1.28h-1a2.29,2.29,0,0,1,1-1.85,3.79,3.79,0,0,1,2.45-.79,3.51,3.51,0,0,1,2.37.75,2.74,2.74,0,0,1,.89,2.1v4.25a5.33,5.33,0,0,0,.27,1.95v.1Zm-3.12-.71a3.26,3.26,0,0,0,1.78-.49,2.87,2.87,0,0,0,1.14-1.28v-2h-1.78a4.34,4.34,0,0,0-2.32.55,1.67,1.67,0,0,0-.28,2.7A2.12,2.12,0,0,0,443.27,276.71Z" />
              <path d="M450.94,277.42h-1V264.67h1Z" />
              <path d="M262.86,299.35l.15.77.2-.81,2.16-6.87h.84l2.14,6.81.23.95.19-.87,1.84-6.89h1l-2.61,9h-.85l-2.31-7.12-.11-.49-.1.5-2.27,7.11h-.85l-2.6-9h1Z" />
              <path d="M277,301.59a3.87,3.87,0,0,1-3.51-2.14,4.94,4.94,0,0,1-.51-2.26v-.36a5.35,5.35,0,0,1,.5-2.33,3.92,3.92,0,0,1,1.4-1.63,3.42,3.42,0,0,1,1.94-.6,3.24,3.24,0,0,1,2.6,1.12,4.56,4.56,0,0,1,.95,3.05V297H274v.19a3.78,3.78,0,0,0,.87,2.54,2.78,2.78,0,0,0,2.2,1,3.29,3.29,0,0,0,1.41-.29,3.21,3.21,0,0,0,1.11-.93l.62.47A3.62,3.62,0,0,1,277,301.59Zm-.18-8.47a2.47,2.47,0,0,0-1.89.82,3.8,3.8,0,0,0-.93,2.21h5.39V296a3.34,3.34,0,0,0-.74-2.11A2.31,2.31,0,0,0,276.82,293.12Z" />
              <path d="M283.42,301.42h-1V288.67h1Z" />
              <path d="M287.23,301.42h-1V288.67h1Z" />
              <path d="M297.16,297a5.4,5.4,0,0,1-.93,3.33,3,3,0,0,1-2.5,1.24,3.23,3.23,0,0,1-2.84-1.4l0,1.23h-.93V288.67h1v5.07a3.11,3.11,0,0,1,2.8-1.47,3,3,0,0,1,2.52,1.22,5.45,5.45,0,0,1,.93,3.38Zm-1-.17a4.69,4.69,0,0,0-.69-2.75,2.24,2.24,0,0,0-1.94-1,2.74,2.74,0,0,0-1.62.46,2.84,2.84,0,0,0-1,1.38v4a2.66,2.66,0,0,0,2.64,1.69,2.22,2.22,0,0,0,1.92-1A5,5,0,0,0,296.16,296.85Z" />
              <path d="M302.83,301.59a3.83,3.83,0,0,1-2.07-.57,3.77,3.77,0,0,1-1.44-1.57,4.82,4.82,0,0,1-.52-2.26v-.36a5.35,5.35,0,0,1,.5-2.33,4,4,0,0,1,1.4-1.63,3.44,3.44,0,0,1,1.94-.6,3.24,3.24,0,0,1,2.6,1.12,4.51,4.51,0,0,1,1,3.05V297h-6.41v.19a3.78,3.78,0,0,0,.87,2.54,2.8,2.8,0,0,0,2.21,1,3.32,3.32,0,0,0,1.41-.29,3.28,3.28,0,0,0,1.1-.93l.63.47A3.62,3.62,0,0,1,302.83,301.59Zm-.19-8.47a2.46,2.46,0,0,0-1.88.82,3.74,3.74,0,0,0-.94,2.21h5.39V296a3.29,3.29,0,0,0-.74-2.11A2.28,2.28,0,0,0,302.64,293.12Z" />
              <path d="M308.07,289.84a.64.64,0,0,1,.19-.47.63.63,0,0,1,.49-.19.69.69,0,0,1,.51.19.67.67,0,0,1,.18.47.64.64,0,0,1-.18.47.69.69,0,0,1-.51.19.63.63,0,0,1-.49-.19A.61.61,0,0,1,308.07,289.84Zm1.17,11.58h-1v-9h1Z" />
              <path d="M312.89,292.44l0,1.53a3.62,3.62,0,0,1,1.26-1.26,3.31,3.31,0,0,1,1.67-.44,2.68,2.68,0,0,1,2.15.82,3.63,3.63,0,0,1,.71,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M320.88,296.85a5.46,5.46,0,0,1,.93-3.35,3,3,0,0,1,2.54-1.23,3.14,3.14,0,0,1,2.79,1.46l.05-1.29h.93v8.8a3.78,3.78,0,0,1-1,2.74,3.47,3.47,0,0,1-2.63,1,4,4,0,0,1-1.79-.42,3.38,3.38,0,0,1-1.34-1.12l.55-.6a3.15,3.15,0,0,0,2.51,1.3,2.54,2.54,0,0,0,1.94-.74,2.9,2.9,0,0,0,.73-2v-1.13a3.24,3.24,0,0,1-2.79,1.33,3,3,0,0,1-2.5-1.25,5.43,5.43,0,0,1-.95-3.36Zm1,.17a4.74,4.74,0,0,0,.68,2.71,2.24,2.24,0,0,0,1.95,1,2.67,2.67,0,0,0,2.6-1.64V294.9a2.88,2.88,0,0,0-1-1.31,2.72,2.72,0,0,0-1.58-.45,2.25,2.25,0,0,0-1.95,1A5,5,0,0,0,321.89,297Z" />
              <path d="M330.62,300.82a.67.67,0,0,1,.19-.49.64.64,0,0,1,.51-.21.69.69,0,0,1,.53.21.71.71,0,0,1,.19.49.67.67,0,0,1-.19.48.76.76,0,0,1-.53.19.69.69,0,0,1-.51-.19A.63.63,0,0,1,330.62,300.82Z" />
              <path d="M348.09,301.42h-1v-5.79h-6.9v5.79h-1V289.33h1v5.43h6.9v-5.43h1Z" />
              <path d="M354.41,301.59a3.83,3.83,0,0,1-2.07-.57,3.77,3.77,0,0,1-1.44-1.57,4.82,4.82,0,0,1-.52-2.26v-.36a5.35,5.35,0,0,1,.5-2.33,4.07,4.07,0,0,1,1.4-1.63,3.45,3.45,0,0,1,2-.6,3.22,3.22,0,0,1,2.59,1.12,4.51,4.51,0,0,1,1,3.05V297h-6.41v.19a3.74,3.74,0,0,0,.88,2.54,2.76,2.76,0,0,0,2.2,1,3.32,3.32,0,0,0,1.41-.29,3.28,3.28,0,0,0,1.1-.93l.63.47A3.62,3.62,0,0,1,354.41,301.59Zm-.18-8.47a2.47,2.47,0,0,0-1.89.82,3.8,3.8,0,0,0-.94,2.21h5.39V296a3.29,3.29,0,0,0-.74-2.11A2.28,2.28,0,0,0,354.23,293.12Z" />
              <path d="M360.82,301.42h-1V288.67h1Z" />
              <path d="M370.74,297a5.4,5.4,0,0,1-.93,3.33,3,3,0,0,1-2.49,1.24,3.35,3.35,0,0,1-2.82-1.3v4.58h-1V292.44h.93l.05,1.27a3.17,3.17,0,0,1,2.81-1.44,3,3,0,0,1,2.53,1.22,5.57,5.57,0,0,1,.91,3.39Zm-1-.17a4.64,4.64,0,0,0-.71-2.72,2.27,2.27,0,0,0-2-1,2.64,2.64,0,0,0-2.58,1.71v4.31a2.63,2.63,0,0,0,1,1.18,3,3,0,0,0,1.57.41,2.28,2.28,0,0,0,2-1A5.11,5.11,0,0,0,369.75,296.85Z" />
              <path d="M379.23,299.35l.15.77.2-.81,2.15-6.87h.85l2.13,6.81.24.95.19-.87,1.84-6.89h1l-2.62,9h-.84l-2.31-7.12-.11-.49-.11.5-2.26,7.11h-.85l-2.6-9h1Z" />
              <path d="M389.83,289.84a.68.68,0,0,1,.19-.47.65.65,0,0,1,.5-.19.66.66,0,0,1,.5.19.68.68,0,0,1,.19.47.65.65,0,0,1-.19.47.66.66,0,0,1-.5.19.65.65,0,0,1-.69-.66ZM391,301.42h-1v-9h1Z" />
              <path d="M395.25,290.14v2.3h1.86v.81h-1.86v6a1.91,1.91,0,0,0,.27,1.12,1.05,1.05,0,0,0,.9.36,7.18,7.18,0,0,0,.8-.08l0,.81a3.31,3.31,0,0,1-1.06.14,1.75,1.75,0,0,1-1.48-.6,2.79,2.79,0,0,1-.47-1.75v-6H392.6v-.81h1.65v-2.3Z" />
              <path d="M400.15,294a3.59,3.59,0,0,1,1.26-1.25,3.31,3.31,0,0,1,1.67-.44,2.65,2.65,0,0,1,2.14.82,3.63,3.63,0,0,1,.72,2.44v5.89h-1v-5.9a2.72,2.72,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.55,2.55,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1V288.67h1Z" />
              <path d="M418.3,301.42a4.37,4.37,0,0,1-.19-1.25,3.61,3.61,0,0,1-1.33,1.05,4.22,4.22,0,0,1-1.72.37,3,3,0,0,1-2.1-.73,2.36,2.36,0,0,1-.8-1.82,2.41,2.41,0,0,1,1.1-2.08,5.22,5.22,0,0,1,3-.76h1.8v-1a2,2,0,0,0-.59-1.52,2.46,2.46,0,0,0-1.73-.55,2.71,2.71,0,0,0-1.72.53,1.57,1.57,0,0,0-.68,1.28h-1a2.29,2.29,0,0,1,1-1.85,3.78,3.78,0,0,1,2.44-.79,3.47,3.47,0,0,1,2.37.75,2.71,2.71,0,0,1,.9,2.1v4.25a5.33,5.33,0,0,0,.27,1.95v.1Zm-3.12-.71a3.29,3.29,0,0,0,1.78-.49,2.87,2.87,0,0,0,1.14-1.28v-2h-1.78a4.34,4.34,0,0,0-2.32.55,1.67,1.67,0,0,0-.28,2.7A2.12,2.12,0,0,0,415.18,300.71Z" />
              <path d="M422.68,292.44l0,1.53a3.56,3.56,0,0,1,1.27-1.26,3.27,3.27,0,0,1,1.66-.44,2.66,2.66,0,0,1,2.15.82,3.68,3.68,0,0,1,.72,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.55,2.55,0,0,0-1.63.57,3.48,3.48,0,0,0-1,1.56v6.15h-1v-9Z" />
              <path d="M433.92,300l2.62-7.55h1.07L433.78,303l-.2.46a2.41,2.41,0,0,1-2.28,1.64,2.73,2.73,0,0,1-.77-.12v-.82l.52.05a1.83,1.83,0,0,0,1.18-.36,2.78,2.78,0,0,0,.77-1.24l.44-1.21L430,292.44h1.09Z" />
              <path d="M284.92,325.42v-8.17h-1.49v-.81h1.49v-1.09a3,3,0,0,1,.7-2.12,2.57,2.57,0,0,1,1.95-.73,3.62,3.62,0,0,1,1.06.14l-.08.82a3.59,3.59,0,0,0-.9-.1,1.62,1.62,0,0,0-1.28.51,2.14,2.14,0,0,0-.46,1.45v1.12h2.15v.81h-2.15v8.17Z" />
              <path d="M289.77,313.84a.64.64,0,0,1,.19-.47.63.63,0,0,1,.49-.19.64.64,0,0,1,.69.66.64.64,0,0,1-.18.47.67.67,0,0,1-.51.19.63.63,0,0,1-.49-.19A.61.61,0,0,1,289.77,313.84Zm1.17,11.58h-1v-9h1Z" />
              <path d="M294.59,316.44l0,1.53a3.62,3.62,0,0,1,1.26-1.26,3.31,3.31,0,0,1,1.67-.44,2.68,2.68,0,0,1,2.15.82,3.68,3.68,0,0,1,.72,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M308.65,325.42a4.76,4.76,0,0,1-.19-1.25,3.54,3.54,0,0,1-1.33,1.05,4.18,4.18,0,0,1-1.72.37,3,3,0,0,1-2.09-.73,2.33,2.33,0,0,1-.8-1.82,2.4,2.4,0,0,1,1.09-2.08,5.22,5.22,0,0,1,3-.76h1.8v-1a2,2,0,0,0-.59-1.52,2.46,2.46,0,0,0-1.73-.55,2.71,2.71,0,0,0-1.72.53,1.57,1.57,0,0,0-.68,1.28h-1a2.29,2.29,0,0,1,1-1.85,3.79,3.79,0,0,1,2.45-.79,3.51,3.51,0,0,1,2.37.75,2.74,2.74,0,0,1,.89,2.1v4.25a5.33,5.33,0,0,0,.27,1.95v.1Zm-3.12-.71a3.26,3.26,0,0,0,1.78-.49,2.87,2.87,0,0,0,1.14-1.28v-2h-1.78a4.34,4.34,0,0,0-2.32.55,1.67,1.67,0,0,0-.28,2.7A2.14,2.14,0,0,0,305.53,324.71Z" />
              <path d="M313,316.44l0,1.53a3.46,3.46,0,0,1,1.26-1.26,3.28,3.28,0,0,1,1.67-.44,2.68,2.68,0,0,1,2.15.82,3.68,3.68,0,0,1,.71,2.44v5.89h-1v-5.9a2.72,2.72,0,0,0-.51-1.8,2,2,0,0,0-1.6-.58,2.56,2.56,0,0,0-1.64.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1v-9Z" />
              <path d="M324.75,324.75a2.59,2.59,0,0,0,1.73-.59,2,2,0,0,0,.76-1.51h1a2.85,2.85,0,0,1-.52,1.5,3.14,3.14,0,0,1-1.25,1.05,3.77,3.77,0,0,1-1.68.39,3.48,3.48,0,0,1-2.81-1.23,5,5,0,0,1-1-3.31v-.3a5.43,5.43,0,0,1,.47-2.34,3.52,3.52,0,0,1,1.33-1.58,3.68,3.68,0,0,1,2-.56,3.44,3.44,0,0,1,2.43.88,3.24,3.24,0,0,1,1,2.32h-1a2.41,2.41,0,0,0-2.5-2.35,2.46,2.46,0,0,0-2.09,1,4.38,4.38,0,0,0-.74,2.72v.29a4.25,4.25,0,0,0,.74,2.68A2.47,2.47,0,0,0,324.75,324.75Z" />
              <path d="M330.12,313.84a.68.68,0,0,1,.19-.47.63.63,0,0,1,.49-.19.64.64,0,0,1,.69.66.64.64,0,0,1-.18.47.67.67,0,0,1-.51.19.63.63,0,0,1-.49-.19A.65.65,0,0,1,330.12,313.84Zm1.17,11.58h-1v-9h1Z" />
              <path d="M339.66,325.42a4.76,4.76,0,0,1-.19-1.25,3.54,3.54,0,0,1-1.33,1.05,4.18,4.18,0,0,1-1.72.37,3,3,0,0,1-2.09-.73,2.33,2.33,0,0,1-.8-1.82,2.4,2.4,0,0,1,1.09-2.08,5.24,5.24,0,0,1,3-.76h1.8v-1a2,2,0,0,0-.59-1.52,2.45,2.45,0,0,0-1.73-.55,2.73,2.73,0,0,0-1.72.53,1.6,1.6,0,0,0-.68,1.28h-1a2.32,2.32,0,0,1,1-1.85,3.79,3.79,0,0,1,2.45-.79,3.51,3.51,0,0,1,2.37.75,2.7,2.7,0,0,1,.89,2.1v4.25a5.33,5.33,0,0,0,.27,1.95v.1Zm-3.12-.71a3.26,3.26,0,0,0,1.78-.49,2.87,2.87,0,0,0,1.14-1.28v-2h-1.77a4.38,4.38,0,0,0-2.33.55,1.67,1.67,0,0,0-.28,2.7A2.14,2.14,0,0,0,336.54,324.71Z" />
              <path d="M344.21,325.42h-1V312.67h1Z" />
              <path d="M352,318a3.56,3.56,0,0,1,1.25-1.25,3.33,3.33,0,0,1,1.67-.44,2.68,2.68,0,0,1,2.15.82,3.68,3.68,0,0,1,.72,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1V312.67h1Z" />
              <path d="M366,325.42a4.76,4.76,0,0,1-.19-1.25,3.54,3.54,0,0,1-1.33,1.05,4.18,4.18,0,0,1-1.72.37,3,3,0,0,1-2.09-.73,2.33,2.33,0,0,1-.8-1.82A2.4,2.4,0,0,1,361,321a5.24,5.24,0,0,1,3-.76h1.8v-1a2,2,0,0,0-.59-1.52,2.45,2.45,0,0,0-1.73-.55,2.73,2.73,0,0,0-1.72.53,1.6,1.6,0,0,0-.68,1.28h-1a2.32,2.32,0,0,1,1-1.85,3.79,3.79,0,0,1,2.45-.79,3.51,3.51,0,0,1,2.37.75,2.7,2.7,0,0,1,.89,2.1v4.25a5.33,5.33,0,0,0,.27,1.95v.1Zm-3.12-.71a3.26,3.26,0,0,0,1.78-.49,2.87,2.87,0,0,0,1.14-1.28v-2h-1.77a4.38,4.38,0,0,0-2.33.55,1.67,1.67,0,0,0-.28,2.7A2.14,2.14,0,0,0,362.92,324.71Z" />
              <path d="M373.63,317.28a4.8,4.8,0,0,0-.67,0,2.42,2.42,0,0,0-1.58.52,2.82,2.82,0,0,0-.91,1.5v6.17h-1v-9h1l0,1.43a2.63,2.63,0,0,1,2.53-1.6,1.65,1.65,0,0,1,.65.11Z" />
              <path d="M374.64,320.85a5.45,5.45,0,0,1,.93-3.34,3,3,0,0,1,2.54-1.24,3.11,3.11,0,0,1,2.77,1.44v-5h1v12.75h-.93l0-1.2a3.21,3.21,0,0,1-2.81,1.37,3,3,0,0,1-2.5-1.25,5.49,5.49,0,0,1-.95-3.38Zm1,.17a4.74,4.74,0,0,0,.69,2.71,2.24,2.24,0,0,0,1.95,1,2.66,2.66,0,0,0,2.6-1.62v-4.21a2.65,2.65,0,0,0-2.59-1.75,2.25,2.25,0,0,0-2,1A5,5,0,0,0,375.64,321Z" />
              <path d="M389.87,323.14a1.41,1.41,0,0,0-.6-1.19,4.53,4.53,0,0,0-1.79-.7,8.13,8.13,0,0,1-1.85-.58,2.51,2.51,0,0,1-1-.8,2,2,0,0,1-.32-1.15,2.1,2.1,0,0,1,.89-1.75,3.53,3.53,0,0,1,2.27-.7,3.66,3.66,0,0,1,2.41.75,2.36,2.36,0,0,1,.91,1.93h-1a1.62,1.62,0,0,0-.66-1.31,3,3,0,0,0-3.24-.09,1.36,1.36,0,0,0-.6,1.14,1.23,1.23,0,0,0,.5,1,5.25,5.25,0,0,0,1.8.68,8.53,8.53,0,0,1,2,.65,2.53,2.53,0,0,1,1,.84,2.15,2.15,0,0,1,.31,1.19,2.11,2.11,0,0,1-.92,1.81,3.87,3.87,0,0,1-2.39.69,4.09,4.09,0,0,1-2.55-.76,2.35,2.35,0,0,1-1-1.93h1a1.69,1.69,0,0,0,.73,1.36,3,3,0,0,0,1.79.49,2.81,2.81,0,0,0,1.68-.46A1.38,1.38,0,0,0,389.87,323.14Z" />
              <path d="M394.08,318a3.65,3.65,0,0,1,1.25-1.25,3.33,3.33,0,0,1,1.67-.44,2.66,2.66,0,0,1,2.15.82,3.68,3.68,0,0,1,.72,2.44v5.89h-1v-5.9a2.84,2.84,0,0,0-.51-1.8,2,2,0,0,0-1.61-.58,2.53,2.53,0,0,0-1.63.57,3.48,3.48,0,0,0-1.05,1.56v6.15h-1V312.67h1Z" />
              <path d="M402.37,313.84a.67.67,0,0,1,.18-.47.65.65,0,0,1,.5-.19.66.66,0,0,1,.5.19.68.68,0,0,1,.19.47.65.65,0,0,1-.19.47.66.66,0,0,1-.5.19.65.65,0,0,1-.5-.19A.64.64,0,0,1,402.37,313.84Zm1.17,11.58h-1v-9h1Z" />
              <path d="M413.46,321a5.4,5.4,0,0,1-.93,3.33,3,3,0,0,1-2.49,1.24,3.36,3.36,0,0,1-2.83-1.3v4.58h-1V316.44h.92l.05,1.27a3.17,3.17,0,0,1,2.81-1.44,3,3,0,0,1,2.53,1.22,5.57,5.57,0,0,1,.92,3.39Zm-1-.17a4.64,4.64,0,0,0-.71-2.72,2.27,2.27,0,0,0-2-1,2.71,2.71,0,0,0-1.57.44,2.76,2.76,0,0,0-1,1.27v4.31a2.63,2.63,0,0,0,1,1.18,3,3,0,0,0,1.57.41,2.28,2.28,0,0,0,2-1A5,5,0,0,0,412.46,320.85Z" />
            </g>
          </HoverCircle>
          <HoverCircle className="hover-circle bottom-hover">
            <circle
              id="bottom-hover"
              cx="348.76"
              cy="263.58"
              r="126.93"
              fill="#fff"
            />
            <g id="bottom-copy">
              <path
                d="M306.53,190.29l3.49,9.27,3.48-9.27h2.71v12.09h-2.09v-4l.21-5.33-3.57,9.32h-1.5l-3.57-9.31.21,5.32v4h-2.09V190.29Z"
                fill="#df5d47"
              />
              <path
                d="M323.86,202.38a3.62,3.62,0,0,1-.23-.84,3.4,3.4,0,0,1-4.56.23,2.47,2.47,0,0,1-.86-1.91,2.54,2.54,0,0,1,1.07-2.2,5.18,5.18,0,0,1,3.05-.77h1.24v-.59a1.41,1.41,0,0,0-1.58-1.53,1.77,1.77,0,0,0-1.13.34,1.08,1.08,0,0,0-.44.88h-2a2.29,2.29,0,0,1,.49-1.39,3.23,3.23,0,0,1,1.33-1,4.61,4.61,0,0,1,1.88-.37,3.77,3.77,0,0,1,2.52.79,2.79,2.79,0,0,1,1,2.23v4.05a4.75,4.75,0,0,0,.34,1.94v.14Zm-2.21-1.46a2.2,2.2,0,0,0,1.12-.29,1.94,1.94,0,0,0,.8-.78v-1.69h-1.09a3,3,0,0,0-1.69.39,1.27,1.27,0,0,0-.56,1.1,1.18,1.18,0,0,0,.39.93A1.47,1.47,0,0,0,321.65,200.92Z"
                fill="#df5d47"
              />
              <path
                d="M330.61,198.53l-.89.92v2.93h-2V189.63h2V197l.63-.79,2.48-2.79h2.42l-3.33,3.74,3.69,5.24h-2.33Z"
                fill="#df5d47"
              />
              <path
                d="M340.36,202.54a4.18,4.18,0,0,1-3.11-1.2,4.41,4.41,0,0,1-1.19-3.22v-.25a5.29,5.29,0,0,1,.52-2.4,3.85,3.85,0,0,1,3.55-2.24A3.52,3.52,0,0,1,343,194.4a4.92,4.92,0,0,1,1,3.31v.82h-5.87a2.69,2.69,0,0,0,.75,1.76,2.22,2.22,0,0,0,1.63.64,2.72,2.72,0,0,0,2.26-1.12l1.09,1a3.64,3.64,0,0,1-1.44,1.25A4.44,4.44,0,0,1,340.36,202.54Zm-.24-7.69a1.68,1.68,0,0,0-1.34.58,3,3,0,0,0-.65,1.62H342v-.15a2.49,2.49,0,0,0-.54-1.53A1.67,1.67,0,0,0,340.12,194.85Z"
                fill="#df5d47"
              />
              <path
                d="M355.13,202.38a3.11,3.11,0,0,1-.24-.84,3.39,3.39,0,0,1-4.56.23,2.47,2.47,0,0,1-.86-1.91,2.54,2.54,0,0,1,1.07-2.2,5.18,5.18,0,0,1,3-.77h1.24v-.59a1.41,1.41,0,0,0-1.58-1.53,1.77,1.77,0,0,0-1.13.34,1.08,1.08,0,0,0-.44.88h-2a2.29,2.29,0,0,1,.49-1.39,3.2,3.2,0,0,1,1.34-1,4.54,4.54,0,0,1,1.88-.37,3.74,3.74,0,0,1,2.51.79,2.79,2.79,0,0,1,1,2.23v4.05a4.75,4.75,0,0,0,.34,1.94v.14Zm-2.22-1.46a2.2,2.2,0,0,0,1.12-.29,1.88,1.88,0,0,0,.8-.78v-1.69h-1.09a3,3,0,0,0-1.69.39,1.27,1.27,0,0,0-.56,1.1,1.18,1.18,0,0,0,.39.93A1.47,1.47,0,0,0,352.91,200.92Z"
                fill="#df5d47"
              />
              <path d="M361.1,202.38h-2V189.63h2Z" fill="#df5d47" />
              <path d="M365.44,202.38h-2V189.63h2Z" fill="#df5d47" />
              <path
                d="M373.77,193.4l.06.93a3.24,3.24,0,0,1,2.59-1.1,2.47,2.47,0,0,1,2.46,1.38,3.19,3.19,0,0,1,2.76-1.38,2.78,2.78,0,0,1,2.21.82,3.65,3.65,0,0,1,.74,2.43v5.9h-2v-5.85a1.78,1.78,0,0,0-.38-1.25,1.61,1.61,0,0,0-1.23-.4,1.67,1.67,0,0,0-1.13.37,2,2,0,0,0-.61,1v6.16h-2v-5.91a1.44,1.44,0,0,0-1.62-1.59,1.76,1.76,0,0,0-1.71,1v6.51h-2v-9Z"
                fill="#df5d47"
              />
              <path
                d="M389.81,199.49l1.82-6.09h2.15l-3.57,10.34a2.88,2.88,0,0,1-2.79,2.26,3.65,3.65,0,0,1-1-.15v-1.56l.38,0A1.91,1.91,0,0,0,388,204a1.73,1.73,0,0,0,.61-.93l.29-.77-3.15-8.93h2.18Z"
                fill="#df5d47"
              />
              <path
                d="M284.33,211.46a1.06,1.06,0,0,1,.3-.77,1.11,1.11,0,0,1,.84-.31,1.16,1.16,0,0,1,.85.31,1.06,1.06,0,0,1,.29.77,1,1,0,0,1-.29.76,1.16,1.16,0,0,1-.85.31,1.11,1.11,0,0,1-.84-.31A1,1,0,0,1,284.33,211.46Zm2.14,11.32h-2v-9h2Z"
                fill="#df5d47"
              />
              <path
                d="M290.54,213.8l.06,1a3.22,3.22,0,0,1,2.61-1.2c1.87,0,2.83,1.07,2.86,3.21v5.94h-2V217a1.85,1.85,0,0,0-.37-1.27,1.55,1.55,0,0,0-1.2-.41,1.94,1.94,0,0,0-1.82,1.11v6.39h-2v-9Z"
                fill="#df5d47"
              />
              <path
                d="M300.65,211.61v2.19h1.59v1.49h-1.59v5a1.16,1.16,0,0,0,.2.75,1,1,0,0,0,.73.22,2.92,2.92,0,0,0,.71-.08v1.56a4.91,4.91,0,0,1-1.33.19c-1.55,0-2.33-.85-2.33-2.56v-5.09h-1.47V213.8h1.47v-2.19Z"
                fill="#df5d47"
              />
              <path
                d="M307.73,222.94a4.2,4.2,0,0,1-3.11-1.2,4.41,4.41,0,0,1-1.19-3.22v-.25a5.29,5.29,0,0,1,.52-2.4,3.91,3.91,0,0,1,1.46-1.65,3.86,3.86,0,0,1,2.09-.59,3.51,3.51,0,0,1,2.83,1.17,4.92,4.92,0,0,1,1,3.31v.82h-5.86a2.69,2.69,0,0,0,.74,1.76,2.22,2.22,0,0,0,1.64.64,2.74,2.74,0,0,0,2.26-1.12l1.08,1a3.64,3.64,0,0,1-1.44,1.25A4.44,4.44,0,0,1,307.73,222.94Zm-.24-7.69a1.69,1.69,0,0,0-1.34.58,3,3,0,0,0-.65,1.62h3.84v-.15a2.37,2.37,0,0,0-.54-1.53A1.66,1.66,0,0,0,307.49,215.25Z"
                fill="#df5d47"
              />
              <path
                d="M317.6,215.64a4.39,4.39,0,0,0-.82-.07,1.87,1.87,0,0,0-1.87,1.06v6.15h-2v-9h1.92l.05,1a2.28,2.28,0,0,1,2-1.17,1.85,1.85,0,0,1,.71.12Z"
                fill="#df5d47"
              />
              <path
                d="M324,222.78a3.18,3.18,0,0,1-.23-.84,3.4,3.4,0,0,1-4.56.23,2.47,2.47,0,0,1-.86-1.91,2.54,2.54,0,0,1,1.07-2.2,5.16,5.16,0,0,1,3-.77h1.23v-.59a1.59,1.59,0,0,0-.39-1.12,1.56,1.56,0,0,0-1.18-.41,1.77,1.77,0,0,0-1.13.34,1,1,0,0,0-.44.88h-2A2.23,2.23,0,0,1,319,215a3.23,3.23,0,0,1,1.33-1,4.61,4.61,0,0,1,1.88-.37,3.78,3.78,0,0,1,2.52.79,2.85,2.85,0,0,1,1,2.23v4a4.59,4.59,0,0,0,.34,1.94v.14Zm-2.21-1.46a2.2,2.2,0,0,0,1.12-.29,1.86,1.86,0,0,0,.79-.78v-1.69h-1.08a3,3,0,0,0-1.69.39,1.27,1.27,0,0,0-.56,1.1,1.21,1.21,0,0,0,.38.93A1.52,1.52,0,0,0,321.78,221.32Z"
                fill="#df5d47"
              />
              <path
                d="M331.51,221.33a1.79,1.79,0,0,0,1.26-.44,1.48,1.48,0,0,0,.53-1.08h1.9a3,3,0,0,1-.52,1.56,3.47,3.47,0,0,1-1.33,1.15,4,4,0,0,1-1.81.42,3.81,3.81,0,0,1-3-1.22,4.8,4.8,0,0,1-1.1-3.37v-.2a4.77,4.77,0,0,1,1.09-3.29,3.8,3.8,0,0,1,3-1.23,3.69,3.69,0,0,1,2.61.93A3.36,3.36,0,0,1,335.2,217h-1.9a1.82,1.82,0,0,0-.53-1.27,1.67,1.67,0,0,0-1.26-.5A1.79,1.79,0,0,0,330,216a3.67,3.67,0,0,0-.54,2.15v.33a3.71,3.71,0,0,0,.53,2.18A1.79,1.79,0,0,0,331.51,221.33Z"
                fill="#df5d47"
              />
              <path
                d="M339.25,211.61v2.19h1.59v1.49h-1.59v5a1.16,1.16,0,0,0,.2.75,1,1,0,0,0,.73.22,2.92,2.92,0,0,0,.71-.08v1.56a4.91,4.91,0,0,1-1.33.19c-1.55,0-2.33-.85-2.33-2.56v-5.09h-1.47V213.8h1.47v-2.19Z"
                fill="#df5d47"
              />
              <path
                d="M342.38,211.46a1.1,1.1,0,0,1,.29-.77,1.31,1.31,0,0,1,1.69,0,1.06,1.06,0,0,1,.3.77,1,1,0,0,1-.3.76,1.31,1.31,0,0,1-1.69,0A1.06,1.06,0,0,1,342.38,211.46Zm2.14,11.32h-2v-9h2Z"
                fill="#df5d47"
              />
              <path
                d="M346.34,218.2a5.22,5.22,0,0,1,.52-2.37,3.82,3.82,0,0,1,1.47-1.63,4.15,4.15,0,0,1,2.18-.57,3.93,3.93,0,0,1,2.95,1.17,4.63,4.63,0,0,1,1.22,3.1v.48a5.35,5.35,0,0,1-.51,2.37,3.77,3.77,0,0,1-1.46,1.62,4.22,4.22,0,0,1-2.2.57,3.91,3.91,0,0,1-3-1.26,4.87,4.87,0,0,1-1.14-3.38Zm2,.18a3.63,3.63,0,0,0,.57,2.17,2,2,0,0,0,3.18,0,4,4,0,0,0,.57-2.34,3.56,3.56,0,0,0-.58-2.15,2,2,0,0,0-3.16,0A3.94,3.94,0,0,0,348.36,218.38Z"
                fill="#df5d47"
              />
              <path
                d="M358.27,213.8l.06,1a3.22,3.22,0,0,1,2.61-1.2q2.81,0,2.86,3.21v5.94h-2V217a1.85,1.85,0,0,0-.37-1.27,1.58,1.58,0,0,0-1.21-.41,2,2,0,0,0-1.82,1.11v6.39h-2v-9Z"
                fill="#df5d47"
              />
              <path
                d="M370.88,220.34a.89.89,0,0,0-.44-.82A4.64,4.64,0,0,0,369,219a7.92,7.92,0,0,1-1.72-.55,2.29,2.29,0,0,1-1.51-2.12,2.37,2.37,0,0,1,1-1.94,3.86,3.86,0,0,1,2.49-.78,4,4,0,0,1,2.6.8,2.52,2.52,0,0,1,1,2.06h-2a1.23,1.23,0,0,0-.44-1,1.63,1.63,0,0,0-1.14-.39,1.76,1.76,0,0,0-1.09.31,1,1,0,0,0-.41.82.79.79,0,0,0,.39.72,5.22,5.22,0,0,0,1.57.52,7.54,7.54,0,0,1,1.87.62,2.62,2.62,0,0,1,1,.87,2.22,2.22,0,0,1,.33,1.23,2.34,2.34,0,0,1-1,2,4.27,4.27,0,0,1-2.63.75,4.55,4.55,0,0,1-2-.4,3.2,3.2,0,0,1-1.35-1.09,2.62,2.62,0,0,1-.48-1.5h2a1.45,1.45,0,0,0,.54,1.1,2.13,2.13,0,0,0,1.32.38,2.06,2.06,0,0,0,1.21-.3A.93.93,0,0,0,370.88,220.34Z"
                fill="#df5d47"
              />
              <path
                d="M382.81,222.94a4.2,4.2,0,0,1-3.11-1.2,4.41,4.41,0,0,1-1.19-3.22v-.25a5.43,5.43,0,0,1,.52-2.4,3.91,3.91,0,0,1,1.46-1.65,3.86,3.86,0,0,1,2.09-.59,3.55,3.55,0,0,1,2.84,1.17,5,5,0,0,1,1,3.31v.82h-5.87a2.74,2.74,0,0,0,.74,1.76,2.22,2.22,0,0,0,1.64.64,2.73,2.73,0,0,0,2.26-1.12l1.08,1a3.64,3.64,0,0,1-1.44,1.25A4.44,4.44,0,0,1,382.81,222.94Zm-.24-7.69a1.69,1.69,0,0,0-1.34.58,3,3,0,0,0-.65,1.62h3.84v-.15a2.37,2.37,0,0,0-.54-1.53A1.66,1.66,0,0,0,382.57,215.25Z"
                fill="#df5d47"
              />
              <path
                d="M393.35,222.78a3.11,3.11,0,0,1-.24-.84,3.39,3.39,0,0,1-4.56.23,2.47,2.47,0,0,1-.86-1.91,2.54,2.54,0,0,1,1.07-2.2,5.18,5.18,0,0,1,3.05-.77h1.24v-.59a1.4,1.4,0,0,0-1.58-1.53,1.77,1.77,0,0,0-1.13.34,1.08,1.08,0,0,0-.44.88h-2a2.29,2.29,0,0,1,.49-1.39,3.2,3.2,0,0,1,1.34-1,4.54,4.54,0,0,1,1.88-.37,3.74,3.74,0,0,1,2.51.79,2.79,2.79,0,0,1,1,2.23v4a4.58,4.58,0,0,0,.35,1.94v.14Zm-2.22-1.46a2.2,2.2,0,0,0,1.12-.29,1.88,1.88,0,0,0,.8-.78v-1.69H392a2.94,2.94,0,0,0-1.68.39,1.26,1.26,0,0,0-.57,1.1,1.18,1.18,0,0,0,.39.93A1.47,1.47,0,0,0,391.13,221.32Z"
                fill="#df5d47"
              />
              <path
                d="M402.21,220.34a.91.91,0,0,0-.44-.82,4.62,4.62,0,0,0-1.48-.5,8,8,0,0,1-1.71-.55,2.29,2.29,0,0,1-1.51-2.12,2.39,2.39,0,0,1,1-1.94,3.91,3.91,0,0,1,2.5-.78,4,4,0,0,1,2.6.8,2.52,2.52,0,0,1,1,2.06h-2a1.22,1.22,0,0,0-.43-1,1.64,1.64,0,0,0-1.14-.39,1.76,1.76,0,0,0-1.09.31,1,1,0,0,0-.42.82.81.81,0,0,0,.39.72,5.31,5.31,0,0,0,1.58.52,7.3,7.3,0,0,1,1.86.62,2.66,2.66,0,0,1,1,.87,2.22,2.22,0,0,1,.33,1.23,2.34,2.34,0,0,1-1,2,4.29,4.29,0,0,1-2.63.75,4.59,4.59,0,0,1-2-.4,3.23,3.23,0,0,1-1.34-1.09,2.62,2.62,0,0,1-.48-1.5h2a1.41,1.41,0,0,0,.53,1.1,2.17,2.17,0,0,0,1.32.38,2.07,2.07,0,0,0,1.22-.3A1,1,0,0,0,402.21,220.34Z"
                fill="#df5d47"
              />
              <path
                d="M409.09,219.89l1.83-6.09h2.15l-3.57,10.34a2.9,2.9,0,0,1-2.79,2.26,3.59,3.59,0,0,1-1-.15v-1.56l.38,0a1.93,1.93,0,0,0,1.15-.28,1.78,1.78,0,0,0,.61-.93l.29-.77L405,213.8h2.17Z"
                fill="#df5d47"
              />
              <path d="M260.44,246.78V235.4h3.18a4.87,4.87,0,0,1,2.5.64,4.35,4.35,0,0,1,1.73,1.82,5.73,5.73,0,0,1,.62,2.71v1a5.79,5.79,0,0,1-.61,2.71,4.43,4.43,0,0,1-1.74,1.82,5.19,5.19,0,0,1-2.58.64Zm1-10.57V246h2.15a3.71,3.71,0,0,0,2.89-1.19,4.61,4.61,0,0,0,1.08-3.22v-1a4.54,4.54,0,0,0-1.07-3.15,3.68,3.68,0,0,0-2.82-1.19Z" />
              <path d="M274,246.93a3.63,3.63,0,0,1-3.31-2,4.64,4.64,0,0,1-.48-2.13v-.33a4.9,4.9,0,0,1,.47-2.2,3.73,3.73,0,0,1,1.32-1.53,3.17,3.17,0,0,1,1.82-.56,3.08,3.08,0,0,1,2.45,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08.95,3.05,3.05,0,0,0,1.32-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.4,3.4,0,0,1,274,246.93Zm-.18-8a2.31,2.31,0,0,0-1.77.78,3.55,3.55,0,0,0-.88,2.08h5.07v-.11a3.11,3.11,0,0,0-.7-2A2.15,2.15,0,0,0,273.79,239Z" />
              <path d="M282.08,246.14a2.46,2.46,0,0,0,1.64-.55,1.94,1.94,0,0,0,.72-1.42h.89a2.6,2.6,0,0,1-.48,1.41,3.09,3.09,0,0,1-1.18,1,3.6,3.6,0,0,1-1.59.36,3.25,3.25,0,0,1-2.63-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.44-2.21,3.36,3.36,0,0,1,1.26-1.49,3.44,3.44,0,0,1,1.91-.52,3.23,3.23,0,0,1,2.29.83,3,3,0,0,1,1,2.18h-.89a2.43,2.43,0,0,0-4.33-1.3,4.17,4.17,0,0,0-.7,2.56v.27a4.11,4.11,0,0,0,.7,2.53A2.35,2.35,0,0,0,282.08,246.14Z" />
              <path d="M287.15,235.88a.6.6,0,0,1,.17-.44.58.58,0,0,1,.47-.19.61.61,0,0,1,.47.19.6.6,0,0,1,.18.44.61.61,0,0,1-.18.45.68.68,0,0,1-.47.17.64.64,0,0,1-.47-.17A.6.6,0,0,1,287.15,235.88Zm1.1,10.9h-.94v-8.46h.94Z" />
              <path d="M297.58,242.64a5.1,5.1,0,0,1-.87,3.13,2.77,2.77,0,0,1-2.34,1.16,3.11,3.11,0,0,1-2.66-1.22V250h-.93V238.32h.87l0,1.2a3,3,0,0,1,2.65-1.35,2.8,2.8,0,0,1,2.38,1.15,5.14,5.14,0,0,1,.86,3.18Zm-.93-.17a4.34,4.34,0,0,0-.67-2.55,2.13,2.13,0,0,0-1.85-.94,2.67,2.67,0,0,0-1.48.41,2.63,2.63,0,0,0-.94,1.21v4.05a2.39,2.39,0,0,0,1,1.11,2.74,2.74,0,0,0,1.48.38,2.12,2.12,0,0,0,1.84-.94A4.7,4.7,0,0,0,296.65,242.47Z" />
              <path d="M300.58,239.75a3.39,3.39,0,0,1,1.18-1.17,3.17,3.17,0,0,1,1.57-.41,2.55,2.55,0,0,1,2,.76,3.5,3.5,0,0,1,.67,2.3v5.55h-.93v-5.56a2.63,2.63,0,0,0-.48-1.68,1.89,1.89,0,0,0-1.51-.56,2.39,2.39,0,0,0-1.54.54,3.31,3.31,0,0,0-1,1.47v5.79h-.93v-12h.93Z" />
              <path d="M311.71,246.93a3.61,3.61,0,0,1-1.95-.53,3.67,3.67,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08.95,3.05,3.05,0,0,0,1.32-.27,2.93,2.93,0,0,0,1.05-.87l.58.44A3.39,3.39,0,0,1,311.71,246.93Zm-.17-8a2.32,2.32,0,0,0-1.78.78,3.55,3.55,0,0,0-.88,2.08H314v-.11a3.16,3.16,0,0,0-.69-2A2.16,2.16,0,0,0,311.54,239Z" />
              <path d="M320.61,239.12a3.26,3.26,0,0,0-.64-.05,2.33,2.33,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.92v1.35a2.49,2.49,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M326.83,238.32l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.37,2.37,0,0,1,2.54,1.64,3.22,3.22,0,0,1,1.21-1.22,3.4,3.4,0,0,1,1.67-.42c1.82,0,2.74,1,2.78,3v5.64h-.94v-5.57a2.53,2.53,0,0,0-.49-1.68,2,2,0,0,0-1.57-.55,2.51,2.51,0,0,0-1.7.65,2.32,2.32,0,0,0-.77,1.55v5.6h-.94v-5.64a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.4,2.4,0,0,0-1.52.5,3,3,0,0,0-.93,1.5v5.8h-.94v-8.46Z" />
              <path d="M342.83,245.43l2.47-7.11h1l-3.61,9.89-.19.44a2.26,2.26,0,0,1-2.14,1.54,2.68,2.68,0,0,1-.72-.11v-.77l.48,0a1.8,1.8,0,0,0,1.12-.33,2.67,2.67,0,0,0,.72-1.17l.41-1.14-3.18-8.39h1Z" />
              <path d="M353.1,236.16v2.16h1.75v.77H353.1v5.64a1.77,1.77,0,0,0,.25,1.05,1,1,0,0,0,.85.34A4.72,4.72,0,0,0,355,246l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.44-1.64v-5.64h-1.55v-.77h1.55v-2.16Z" />
              <path d="M360.69,239.12a3.16,3.16,0,0,0-.63-.05,2.31,2.31,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.91l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M367.19,246.78a4.81,4.81,0,0,1-.18-1.18,3.32,3.32,0,0,1-1.25,1,3.88,3.88,0,0,1-1.61.34,2.82,2.82,0,0,1-2-.68,2.2,2.2,0,0,1-.75-1.71,2.26,2.26,0,0,1,1-2,4.92,4.92,0,0,1,2.86-.72H367v-1a1.84,1.84,0,0,0-.56-1.42,2.3,2.3,0,0,0-1.63-.52,2.53,2.53,0,0,0-1.62.5,1.47,1.47,0,0,0-.64,1.2h-.94a2.18,2.18,0,0,1,.94-1.74,3.57,3.57,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a5,5,0,0,0,.25,1.83v.1Zm-2.93-.67a3.15,3.15,0,0,0,1.67-.46,2.69,2.69,0,0,0,1.08-1.21v-1.86h-1.68a4.15,4.15,0,0,0-2.18.51,1.59,1.59,0,0,0-.27,2.55A2,2,0,0,0,364.26,246.11Z" />
              <path d="M371.32,238.32l0,1.45a3.31,3.31,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.53,2.53,0,0,1,2,.76,3.45,3.45,0,0,1,.67,2.3v5.55h-.93v-5.56a2.51,2.51,0,0,0-.48-1.68,1.88,1.88,0,0,0-1.51-.56,2.35,2.35,0,0,0-1.53.54,3.15,3.15,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M384.28,244.63a1.35,1.35,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.56,7.56,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.94,3.94,0,0,1,4.41.05,2.23,2.23,0,0,1,.86,1.82h-.94a1.5,1.5,0,0,0-.62-1.23A2.44,2.44,0,0,0,382,239a2.49,2.49,0,0,0-1.48.41,1.27,1.27,0,0,0-.55,1.07,1.15,1.15,0,0,0,.46,1,4.92,4.92,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.37,2.37,0,0,1,.91.78,2.16,2.16,0,0,1-.57,2.83,3.64,3.64,0,0,1-2.25.64,3.76,3.76,0,0,1-2.39-.71,2.19,2.19,0,0,1-.92-1.81h.93a1.64,1.64,0,0,0,.7,1.28,2.83,2.83,0,0,0,1.68.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,384.28,244.63Z" />
              <path d="M392.65,246.78a4.81,4.81,0,0,1-.18-1.18,3.32,3.32,0,0,1-1.25,1,3.88,3.88,0,0,1-1.61.34,2.82,2.82,0,0,1-2-.68,2.2,2.2,0,0,1-.75-1.71,2.26,2.26,0,0,1,1-2,4.92,4.92,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.3,2.3,0,0,0-1.63-.52,2.53,2.53,0,0,0-1.62.5,1.49,1.49,0,0,0-.64,1.2h-.94a2.18,2.18,0,0,1,.94-1.74,3.59,3.59,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a5,5,0,0,0,.25,1.83v.1Zm-2.93-.67a3.15,3.15,0,0,0,1.67-.46,2.69,2.69,0,0,0,1.08-1.21v-1.86h-1.68a4.15,4.15,0,0,0-2.18.51,1.54,1.54,0,0,0-.79,1.37,1.5,1.5,0,0,0,.53,1.18A2,2,0,0,0,389.72,246.11Z" />
              <path d="M399,246.14a2.45,2.45,0,0,0,1.64-.55,1.94,1.94,0,0,0,.72-1.42h.89a2.6,2.6,0,0,1-.48,1.41,3.09,3.09,0,0,1-1.18,1,3.6,3.6,0,0,1-1.59.36,3.25,3.25,0,0,1-2.63-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.44-2.21,3.36,3.36,0,0,1,1.26-1.49,3.44,3.44,0,0,1,1.91-.52,3.23,3.23,0,0,1,2.29.83,3,3,0,0,1,1,2.18h-.89a2.37,2.37,0,0,0-.72-1.61,2.54,2.54,0,0,0-3.61.31,4.17,4.17,0,0,0-.7,2.56v.27a4.11,4.11,0,0,0,.7,2.53A2.36,2.36,0,0,0,399,246.14Z" />
              <path d="M405.58,236.16v2.16h1.75v.77h-1.75v5.64a1.76,1.76,0,0,0,.26,1.05,1,1,0,0,0,.85.34,4.73,4.73,0,0,0,.75-.08l0,.76a3,3,0,0,1-1,.13,1.66,1.66,0,0,1-1.4-.56,2.63,2.63,0,0,1-.43-1.64v-5.64h-1.56v-.77h1.56v-2.16Z" />
              <path d="M409.22,235.88a.6.6,0,0,1,.17-.44.58.58,0,0,1,.47-.19.6.6,0,0,1,.47.19.6.6,0,0,1,.18.44.61.61,0,0,1-.18.45.66.66,0,0,1-.47.17.64.64,0,0,1-.47-.17A.6.6,0,0,1,409.22,235.88Zm1.1,10.9h-.94v-8.46h.94Z" />
              <path d="M412.34,242.43a5,5,0,0,1,.47-2.2,3.68,3.68,0,0,1,1.34-1.52,3.63,3.63,0,0,1,2-.54,3.47,3.47,0,0,1,2.73,1.18,4.57,4.57,0,0,1,1.05,3.14v.19a5.08,5.08,0,0,1-.47,2.21,3.52,3.52,0,0,1-1.34,1.51,3.58,3.58,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.55,4.55,0,0,1-1.05-3.14Zm.94.25a3.86,3.86,0,0,0,.78,2.49,2.52,2.52,0,0,0,2.06,1,2.49,2.49,0,0,0,2.05-1,3.92,3.92,0,0,0,.79-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.52,2.52,0,0,0-1.48-.45,2.47,2.47,0,0,0-2,1,3.92,3.92,0,0,0-.79,2.56Z" />
              <path d="M422.71,238.32l0,1.45a3.31,3.31,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.53,2.53,0,0,1,2,.76,3.45,3.45,0,0,1,.67,2.3v5.55h-.93v-5.56a2.51,2.51,0,0,0-.48-1.68,1.88,1.88,0,0,0-1.51-.56,2.35,2.35,0,0,0-1.53.54,3.15,3.15,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M435.67,244.63a1.35,1.35,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.56,7.56,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.44,3.44,0,0,1,2.15-.65,3.48,3.48,0,0,1,2.27.7,2.22,2.22,0,0,1,.85,1.82h-.94a1.5,1.5,0,0,0-.62-1.23,2.41,2.41,0,0,0-1.56-.5,2.45,2.45,0,0,0-1.48.41,1.26,1.26,0,0,0-.56,1.07,1.15,1.15,0,0,0,.46,1,4.92,4.92,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.37,2.37,0,0,1,.91.78,2.16,2.16,0,0,1-.57,2.83,3.64,3.64,0,0,1-2.25.64,3.76,3.76,0,0,1-2.39-.71,2.19,2.19,0,0,1-.92-1.81h.94a1.6,1.6,0,0,0,.69,1.28,2.83,2.83,0,0,0,1.68.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,435.67,244.63Z" />
              <path d="M248.24,270.78v-7.69h-1.4v-.77h1.4v-1a2.78,2.78,0,0,1,.66-2,2.43,2.43,0,0,1,1.84-.7,4,4,0,0,1,1,.13l-.07.78a3.82,3.82,0,0,0-.85-.09,1.55,1.55,0,0,0-1.21.48,2,2,0,0,0-.43,1.36v1.05h2v.77h-2v7.69Z" />
              <path d="M252.35,266.43a4.9,4.9,0,0,1,.47-2.2,3.59,3.59,0,0,1,1.33-1.52,3.63,3.63,0,0,1,2-.54,3.48,3.48,0,0,1,2.74,1.18,4.61,4.61,0,0,1,1,3.14v.19a5.08,5.08,0,0,1-.47,2.21,3.57,3.57,0,0,1-1.33,1.51,3.63,3.63,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.55,4.55,0,0,1-1.05-3.14Zm.93.25a3.81,3.81,0,0,0,.79,2.49,2.66,2.66,0,0,0,4.11,0,4,4,0,0,0,.78-2.57v-.17a4.32,4.32,0,0,0-.36-1.78,2.89,2.89,0,0,0-1-1.24,2.57,2.57,0,0,0-1.49-.45,2.52,2.52,0,0,0-2,1,4,4,0,0,0-.79,2.57Z" />
              <path d="M265.73,263.12a3.22,3.22,0,0,0-.63-.05,2.29,2.29,0,0,0-1.48.48,2.65,2.65,0,0,0-.87,1.42v5.81h-.93v-8.46h.92v1.35a2.49,2.49,0,0,1,2.38-1.5,1.48,1.48,0,0,1,.62.1Z" />
              <path d="M272,262.32l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.36,2.36,0,0,1,2.54,1.64,3.22,3.22,0,0,1,1.21-1.22,3.42,3.42,0,0,1,1.67-.42c1.82,0,2.74,1,2.78,3v5.64H282v-5.57a2.48,2.48,0,0,0-.49-1.68,2,2,0,0,0-1.56-.55,2.52,2.52,0,0,0-1.71.65,2.32,2.32,0,0,0-.77,1.55v5.6h-.94v-5.64a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.42,2.42,0,0,0-1.52.5A3,3,0,0,0,272,265v5.8h-.94v-8.46Z" />
              <path d="M288.55,270.93a3.61,3.61,0,0,1-1.95-.53,3.67,3.67,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08.95,3.05,3.05,0,0,0,1.32-.27,3,3,0,0,0,1-.87l.58.44A3.39,3.39,0,0,1,288.55,270.93Zm-.17-8a2.32,2.32,0,0,0-1.78.78,3.55,3.55,0,0,0-.88,2.08h5.07v-.11a3.16,3.16,0,0,0-.69-2A2.18,2.18,0,0,0,288.38,263Z" />
              <path d="M293.46,270.21a.64.64,0,0,1,.17-.46.73.73,0,0,1,1,0,.65.65,0,0,1,.18.46.58.58,0,0,1-.18.45.76.76,0,0,1-1,0A.57.57,0,0,1,293.46,270.21Z" />
              <path d="M309.68,269.43a3.17,3.17,0,0,1-1.56,1.1,6.73,6.73,0,0,1-2.39.4,4.44,4.44,0,0,1-2.38-.64,4.24,4.24,0,0,1-1.61-1.81,6.08,6.08,0,0,1-.57-2.69v-1.44a5.46,5.46,0,0,1,1.19-3.73,4,4,0,0,1,3.21-1.37,4.25,4.25,0,0,1,2.83.89,3.69,3.69,0,0,1,1.27,2.46h-1a3,3,0,0,0-1-1.9,3.28,3.28,0,0,0-2.11-.64,3.1,3.1,0,0,0-2.52,1.13,4.92,4.92,0,0,0-.93,3.2v1.34a5.55,5.55,0,0,0,.44,2.3,3.44,3.44,0,0,0,1.26,1.54,3.31,3.31,0,0,0,1.9.55,5.26,5.26,0,0,0,2.13-.39,2.25,2.25,0,0,0,.86-.6v-2.94h-3.05v-.81h4Z" />
              <path d="M312.13,259.88a.6.6,0,0,1,.18-.44.58.58,0,0,1,.47-.19.61.61,0,0,1,.47.19.6.6,0,0,1,.17.44.57.57,0,0,1-.17.44.64.64,0,0,1-.47.18.61.61,0,0,1-.47-.18A.57.57,0,0,1,312.13,259.88Zm1.11,10.9h-.94v-8.46h.94Z" />
              <path d="M318.41,269.49l2.57-7.17h1l-3.16,8.46H318l-3.18-8.46h1Z" />
              <path d="M326.64,270.93a3.6,3.6,0,0,1-3.3-2,4.52,4.52,0,0,1-.49-2.13v-.33a4.9,4.9,0,0,1,.48-2.2,3.7,3.7,0,0,1,1.31-1.53,3.21,3.21,0,0,1,1.83-.56,3,3,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.56,3.56,0,0,0,.83,2.4,2.61,2.61,0,0,0,2.07.95,3.13,3.13,0,0,0,1.33-.27,2.9,2.9,0,0,0,1-.87l.58.44A3.38,3.38,0,0,1,326.64,270.93Zm-.17-8a2.34,2.34,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.07v-.11a3,3,0,0,0-.69-2A2.16,2.16,0,0,0,326.47,263Z" />
              <path d="M336.38,262.32l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.36,2.36,0,0,1,2.54,1.64,3.22,3.22,0,0,1,1.21-1.22,3.42,3.42,0,0,1,1.67-.42c1.82,0,2.74,1,2.78,3v5.64h-.94v-5.57a2.48,2.48,0,0,0-.49-1.68,2,2,0,0,0-1.56-.55,2.52,2.52,0,0,0-1.71.65,2.32,2.32,0,0,0-.77,1.55v5.6h-.94v-5.64a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.42,2.42,0,0,0-1.52.5,3,3,0,0,0-.93,1.5v5.8h-.94v-8.46Z" />
              <path d="M353,270.93a3.61,3.61,0,0,1-2-.53,3.67,3.67,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08.95,3.05,3.05,0,0,0,1.32-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.39,3.39,0,0,1,353,270.93Zm-.17-8a2.32,2.32,0,0,0-1.78.78,3.55,3.55,0,0,0-.88,2.08h5.07v-.11a3.16,3.16,0,0,0-.69-2A2.18,2.18,0,0,0,352.81,263Z" />
              <path d="M367.2,270.78a4,4,0,0,1-.18-1.18,3.43,3.43,0,0,1-1.26,1,3.81,3.81,0,0,1-1.61.34,2.8,2.8,0,0,1-2-.68,2.18,2.18,0,0,1-.76-1.72,2.28,2.28,0,0,1,1-1.95,4.94,4.94,0,0,1,2.86-.72H367v-1a1.84,1.84,0,0,0-.56-1.42,2.28,2.28,0,0,0-1.63-.52,2.48,2.48,0,0,0-1.61.5,1.48,1.48,0,0,0-.65,1.2h-.93a2.17,2.17,0,0,1,.93-1.74,3.59,3.59,0,0,1,2.31-.74,3.29,3.29,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a4.93,4.93,0,0,0,.26,1.83v.1Zm-2.94-.68a3.2,3.2,0,0,0,1.68-.45,2.61,2.61,0,0,0,1.07-1.21v-1.86h-1.67a4.22,4.22,0,0,0-2.19.51,1.58,1.58,0,0,0-.26,2.55A2,2,0,0,0,364.26,270.1Z" />
              <path d="M375,270.78v-7.69H373.6v-.77H375v-1a2.78,2.78,0,0,1,.66-2,2.44,2.44,0,0,1,1.84-.7,4,4,0,0,1,1,.13l-.07.78a3.85,3.85,0,0,0-.86-.09,1.54,1.54,0,0,0-1.2.48,2,2,0,0,0-.43,1.36v1.05h2v.77h-2v7.69Z" />
              <path d="M383.53,263.12a3.26,3.26,0,0,0-.64-.05,2.33,2.33,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.91l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M385,259.88a.6.6,0,0,1,.17-.44.58.58,0,0,1,.47-.19.6.6,0,0,1,.47.19.6.6,0,0,1,.18.44.57.57,0,0,1-.18.44.63.63,0,0,1-.47.18.61.61,0,0,1-.47-.18A.57.57,0,0,1,385,259.88Zm1.1,10.9h-.94v-8.46h.94Z" />
              <path d="M391.71,270.14a2.46,2.46,0,0,0,1.64-.55,1.89,1.89,0,0,0,.71-1.42h.9a2.6,2.6,0,0,1-.48,1.41,3,3,0,0,1-1.19,1,3.52,3.52,0,0,1-1.58.36,3.24,3.24,0,0,1-2.63-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.44-2.21,3.35,3.35,0,0,1,1.25-1.49,3.5,3.5,0,0,1,1.92-.52A3.23,3.23,0,0,1,394,263a3,3,0,0,1,1,2.18h-.9a2.43,2.43,0,0,0-4.32-1.3,4.1,4.1,0,0,0-.7,2.56v.27a4,4,0,0,0,.7,2.53A2.35,2.35,0,0,0,391.71,270.14Z" />
              <path d="M398.28,260.16v2.16H400v.77h-1.75v5.64a1.76,1.76,0,0,0,.26,1,1,1,0,0,0,.84.34,4.72,4.72,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.66,1.66,0,0,1-1.4-.56,2.63,2.63,0,0,1-.43-1.64v-5.64h-1.56v-.77h1.56v-2.16Z" />
              <path d="M401.92,259.88a.6.6,0,0,1,.17-.44.58.58,0,0,1,.47-.19.61.61,0,0,1,.47.19.6.6,0,0,1,.18.44.57.57,0,0,1-.18.44.64.64,0,0,1-.47.18.61.61,0,0,1-.47-.18A.57.57,0,0,1,401.92,259.88Zm1.1,10.9h-.94v-8.46H403Z" />
              <path d="M405,266.43a5,5,0,0,1,.47-2.2,3.55,3.55,0,0,1,3.3-2.06,3.47,3.47,0,0,1,2.73,1.18,4.57,4.57,0,0,1,1,3.14v.19a5.08,5.08,0,0,1-.47,2.21,3.52,3.52,0,0,1-1.34,1.51,3.58,3.58,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.55,4.55,0,0,1-1-3.14Zm.94.25a3.86,3.86,0,0,0,.78,2.49,2.52,2.52,0,0,0,2.06,1,2.49,2.49,0,0,0,2.05-1,3.93,3.93,0,0,0,.79-2.57v-.17a4.32,4.32,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.54,2.54,0,0,0-1.48-.45,2.49,2.49,0,0,0-2,1,4,4,0,0,0-.79,2.57Z" />
              <path d="M415.41,262.32l0,1.45a3.31,3.31,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.53,2.53,0,0,1,2,.76,3.45,3.45,0,0,1,.67,2.3v5.55H420v-5.56a2.54,2.54,0,0,0-.48-1.69A1.91,1.91,0,0,0,418,263a2.35,2.35,0,0,0-1.53.54,3.15,3.15,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M424.35,270.78h-.93v-12h.93Z" />
              <path d="M430.17,270.93a3.63,3.63,0,0,1-3.31-2,4.64,4.64,0,0,1-.48-2.13v-.33a4.9,4.9,0,0,1,.47-2.2,3.73,3.73,0,0,1,1.32-1.53,3.17,3.17,0,0,1,1.82-.56,3.08,3.08,0,0,1,2.45,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08.95,3.05,3.05,0,0,0,1.32-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.4,3.4,0,0,1,430.17,270.93Zm-.18-8a2.31,2.31,0,0,0-1.77.78,3.55,3.55,0,0,0-.88,2.08h5.07v-.11a3.11,3.11,0,0,0-.7-2A2.15,2.15,0,0,0,430,263Z" />
              <path d="M440.22,268.63a1.35,1.35,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.56,7.56,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.84-1.65,3.39,3.39,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.22,2.22,0,0,1,.85,1.82h-.94a1.48,1.48,0,0,0-.62-1.23A2.41,2.41,0,0,0,438,263a2.45,2.45,0,0,0-1.48.41,1.26,1.26,0,0,0-.56,1.07,1.15,1.15,0,0,0,.46,1,4.92,4.92,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.37,2.37,0,0,1,.91.78,2.16,2.16,0,0,1-.57,2.83,3.64,3.64,0,0,1-2.25.64,3.82,3.82,0,0,1-2.39-.71,2.19,2.19,0,0,1-.92-1.81h.94a1.6,1.6,0,0,0,.69,1.28,2.83,2.83,0,0,0,1.68.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,440.22,268.63Z" />
              <path d="M448.32,268.63a1.32,1.32,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.56,7.56,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.84-1.65,3.39,3.39,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.22,2.22,0,0,1,.85,1.82h-.93a1.49,1.49,0,0,0-.63-1.23,2.41,2.41,0,0,0-1.56-.5,2.45,2.45,0,0,0-1.48.41,1.28,1.28,0,0,0-.56,1.07,1.15,1.15,0,0,0,.46,1,4.92,4.92,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.37,2.37,0,0,1,.91.78,2.17,2.17,0,0,1-.56,2.83,3.69,3.69,0,0,1-2.26.64,3.82,3.82,0,0,1-2.39-.71,2.21,2.21,0,0,1-.92-1.81h.94a1.6,1.6,0,0,0,.69,1.28,2.83,2.83,0,0,0,1.68.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,448.32,268.63Z" />
              <path d="M290,294.14a2.46,2.46,0,0,0,1.64-.55,1.93,1.93,0,0,0,.71-1.42h.9a2.69,2.69,0,0,1-.49,1.41,3,3,0,0,1-1.18,1,3.55,3.55,0,0,1-1.58.36,3.27,3.27,0,0,1-2.64-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.44-2.21,3.35,3.35,0,0,1,1.25-1.49,3.46,3.46,0,0,1,1.91-.52,3.21,3.21,0,0,1,2.29.83,3,3,0,0,1,1,2.18h-.9a2.32,2.32,0,0,0-.71-1.61A2.37,2.37,0,0,0,290,287a2.32,2.32,0,0,0-2,.92,4.1,4.1,0,0,0-.7,2.56v.27a4,4,0,0,0,.7,2.53A2.34,2.34,0,0,0,290,294.14Z" />
              <path d="M300.58,293.71a3,3,0,0,1-2.69,1.22,2.64,2.64,0,0,1-2.06-.78,3.5,3.5,0,0,1-.71-2.33v-5.5H296v5.39c0,1.61.66,2.41,2,2.41a2.4,2.4,0,0,0,2.57-1.68v-6.12h.94v8.46h-.91Z" />
              <path d="M309,292.63a1.35,1.35,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.4,7.4,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.94,3.94,0,0,1,4.41.05,2.23,2.23,0,0,1,.86,1.82H309a1.51,1.51,0,0,0-.62-1.23,2.44,2.44,0,0,0-1.57-.5,2.49,2.49,0,0,0-1.48.41,1.28,1.28,0,0,0-.56,1.07,1.16,1.16,0,0,0,.47,1,4.85,4.85,0,0,0,1.69.64,7.69,7.69,0,0,1,1.85.62,2.37,2.37,0,0,1,.91.78,2.16,2.16,0,0,1-.57,2.83,3.64,3.64,0,0,1-2.25.64,3.85,3.85,0,0,1-2.4-.71,2.2,2.2,0,0,1-.91-1.81h.93a1.66,1.66,0,0,0,.69,1.28,2.89,2.89,0,0,0,1.69.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,309,292.63Z" />
              <path d="M313.49,284.16v2.16h1.75v.77h-1.75v5.64a1.77,1.77,0,0,0,.25,1,1,1,0,0,0,.85.34,4.72,4.72,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.44-1.64v-5.64H311v-.77h1.55v-2.16Z" />
              <path d="M316.51,290.43a4.9,4.9,0,0,1,.47-2.2,3.59,3.59,0,0,1,1.33-1.52,3.63,3.63,0,0,1,2-.54,3.48,3.48,0,0,1,2.74,1.18,4.61,4.61,0,0,1,1,3.14v.19a4.94,4.94,0,0,1-.47,2.21,3.57,3.57,0,0,1-1.33,1.51,3.63,3.63,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.55,4.55,0,0,1-1.05-3.14Zm.93.25a3.87,3.87,0,0,0,.79,2.49,2.66,2.66,0,0,0,4.11,0,4,4,0,0,0,.78-2.57v-.17a4.32,4.32,0,0,0-.36-1.78,2.89,2.89,0,0,0-1-1.24,2.57,2.57,0,0,0-1.49-.45,2.52,2.52,0,0,0-2,1,4,4,0,0,0-.79,2.57Z" />
              <path d="M326.83,286.32l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.37,2.37,0,0,1,2.54,1.64,3.15,3.15,0,0,1,1.21-1.22,3.37,3.37,0,0,1,1.67-.42c1.81,0,2.74,1,2.77,3v5.64h-.93v-5.57a2.53,2.53,0,0,0-.49-1.68,2,2,0,0,0-1.57-.55,2.5,2.5,0,0,0-1.7.65,2.32,2.32,0,0,0-.77,1.55v5.6h-.94v-5.64a2.34,2.34,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.4,2.4,0,0,0-1.52.5,3.05,3.05,0,0,0-.94,1.5v5.8h-.93v-8.46Z" />
              <path d="M343.44,294.93a3.63,3.63,0,0,1-3.31-2,4.64,4.64,0,0,1-.48-2.13v-.33a4.9,4.9,0,0,1,.47-2.2,3.65,3.65,0,0,1,1.32-1.53,3.17,3.17,0,0,1,1.82-.56,3.08,3.08,0,0,1,2.45,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.07.95,3.1,3.1,0,0,0,1.33-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.4,3.4,0,0,1,343.44,294.93Zm-.18-8a2.31,2.31,0,0,0-1.77.78,3.49,3.49,0,0,0-.88,2.08h5.07v-.11a3.11,3.11,0,0,0-.7-2A2.16,2.16,0,0,0,343.26,287Z" />
              <path d="M352.33,287.12a3.16,3.16,0,0,0-.63-.05,2.31,2.31,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.91l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M358.71,286.32v9.46a2.58,2.58,0,0,1-.56,1.79,2.15,2.15,0,0,1-1.66.62,2.33,2.33,0,0,1-.82-.15l0-.76a2.75,2.75,0,0,0,.7.1c.93,0,1.39-.54,1.39-1.6v-9.46Zm-.46-3.07a.58.58,0,0,1,.47.19.6.6,0,0,1,.18.44.57.57,0,0,1-.18.44.61.61,0,0,1-.47.18.63.63,0,0,1-.47-.18.6.6,0,0,1-.16-.44.63.63,0,0,1,.16-.44A.6.6,0,0,1,358.25,283.25Z" />
              <path d="M360.83,290.43a4.9,4.9,0,0,1,.48-2.2,3.59,3.59,0,0,1,1.33-1.52,3.63,3.63,0,0,1,2-.54,3.46,3.46,0,0,1,2.73,1.18,4.57,4.57,0,0,1,1.05,3.14v.19a5.08,5.08,0,0,1-.47,2.21,3.57,3.57,0,0,1-1.33,1.51,3.63,3.63,0,0,1-2,.53,3.49,3.49,0,0,1-2.74-1.18,4.55,4.55,0,0,1-1.05-3.14Zm.94.25a3.81,3.81,0,0,0,.79,2.49,2.66,2.66,0,0,0,4.11,0,4,4,0,0,0,.78-2.57v-.17a4.32,4.32,0,0,0-.36-1.78,2.84,2.84,0,0,0-1-1.24,2.63,2.63,0,0,0-3.52.53,4,4,0,0,0-.79,2.57Z" />
              <path d="M375.75,293.71a3.05,3.05,0,0,1-2.7,1.22,2.62,2.62,0,0,1-2.05-.78,3.45,3.45,0,0,1-.72-2.33v-5.5h.93v5.39c0,1.61.65,2.41,2,2.41a2.39,2.39,0,0,0,2.57-1.68v-6.12h.94v8.46h-.91Z" />
              <path d="M383,287.12a3.26,3.26,0,0,0-.64-.05,2.33,2.33,0,0,0-1.48.48A2.7,2.7,0,0,0,380,289v5.81h-.93v-8.46H380l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M385.37,286.32l0,1.45a3.39,3.39,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.55,2.55,0,0,1,2,.76,3.5,3.5,0,0,1,.67,2.3v5.55h-.93v-5.56a2.66,2.66,0,0,0-.48-1.69,1.92,1.92,0,0,0-1.51-.55,2.39,2.39,0,0,0-1.54.54,3.31,3.31,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M396.54,294.93a3.6,3.6,0,0,1-1.94-.53,3.54,3.54,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a4.9,4.9,0,0,1,.47-2.2,3.7,3.7,0,0,1,1.31-1.53,3.23,3.23,0,0,1,1.83-.56,3,3,0,0,1,2.44,1.05,4.22,4.22,0,0,1,.91,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.07.95,3.1,3.1,0,0,0,1.33-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.41,3.41,0,0,1,396.54,294.93Zm-.17-8a2.31,2.31,0,0,0-1.77.78,3.49,3.49,0,0,0-.88,2.08h5.07v-.11a3.11,3.11,0,0,0-.7-2A2.16,2.16,0,0,0,396.37,287Z" />
              <path d="M404.12,293.43l2.46-7.11h1L404,296.21l-.19.44a2.25,2.25,0,0,1-2.14,1.54,2.68,2.68,0,0,1-.72-.11v-.77l.48,0a1.8,1.8,0,0,0,1.12-.33,2.67,2.67,0,0,0,.72-1.17l.41-1.14-3.18-8.39h1Z" />
              <path d="M408.12,294.21a.64.64,0,0,1,.17-.46.73.73,0,0,1,1,0,.65.65,0,0,1,.18.46.58.58,0,0,1-.18.45.76.76,0,0,1-1,0A.57.57,0,0,1,408.12,294.21Z" />
              <path d="M308.12,313.46h-5.31v5.32h-1V307.4h7v.81h-6.09v4.43h5.31Z" />
              <path d="M315.71,318.78a4,4,0,0,1-.18-1.18,3.43,3.43,0,0,1-1.26,1,3.85,3.85,0,0,1-1.61.34,2.8,2.8,0,0,1-2-.68,2.18,2.18,0,0,1-.76-1.72,2.25,2.25,0,0,1,1-1.95,4.92,4.92,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.3,2.3,0,0,0-1.63-.52,2.53,2.53,0,0,0-1.62.5,1.49,1.49,0,0,0-.64,1.2h-.94a2.18,2.18,0,0,1,.94-1.74,3.59,3.59,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a4.73,4.73,0,0,0,.26,1.83v.1Zm-2.94-.68a3.14,3.14,0,0,0,1.67-.45,2.63,2.63,0,0,0,1.08-1.21v-1.86h-1.67a4.17,4.17,0,0,0-2.19.51,1.54,1.54,0,0,0-.79,1.37,1.5,1.5,0,0,0,.53,1.18A2,2,0,0,0,312.77,318.1Z" />
              <path d="M324,316.63a1.32,1.32,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.56,7.56,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.84-1.65,3.39,3.39,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.22,2.22,0,0,1,.85,1.82H324a1.49,1.49,0,0,0-.63-1.23,2.41,2.41,0,0,0-1.56-.5,2.45,2.45,0,0,0-1.48.41,1.26,1.26,0,0,0-.56,1.07,1.15,1.15,0,0,0,.46,1,4.92,4.92,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.37,2.37,0,0,1,.91.78,2.17,2.17,0,0,1-.56,2.83,3.69,3.69,0,0,1-2.26.64,3.82,3.82,0,0,1-2.39-.71,2.21,2.21,0,0,1-.92-1.81h.94a1.6,1.6,0,0,0,.69,1.28,2.83,2.83,0,0,0,1.68.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,324,316.63Z" />
              <path d="M328.49,308.16v2.16h1.75v.77h-1.75v5.64a1.76,1.76,0,0,0,.26,1,1,1,0,0,0,.85.34,4.73,4.73,0,0,0,.75-.08l0,.76a3,3,0,0,1-1,.13,1.66,1.66,0,0,1-1.4-.56,2.63,2.63,0,0,1-.43-1.64v-5.64H326v-.77h1.56v-2.16Z" />
              <path d="M335.09,314.15h-3.74v-.79h3.74Z" />
              <path d="M338.21,308.16v2.16H340v.77h-1.75v5.64a1.76,1.76,0,0,0,.26,1,1,1,0,0,0,.84.34,4.72,4.72,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.43-1.64v-5.64h-1.56v-.77h1.56v-2.16Z" />
              <path d="M345.81,311.12a3.26,3.26,0,0,0-.64-.05,2.33,2.33,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.91l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M352.31,318.78a4,4,0,0,1-.18-1.18,3.43,3.43,0,0,1-1.26,1,3.81,3.81,0,0,1-1.61.34,2.8,2.8,0,0,1-2-.68,2.18,2.18,0,0,1-.76-1.72,2.28,2.28,0,0,1,1-1.95,4.94,4.94,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.28,2.28,0,0,0-1.63-.52,2.53,2.53,0,0,0-1.62.5,1.49,1.49,0,0,0-.64,1.2h-.93a2.17,2.17,0,0,1,.93-1.74,3.59,3.59,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a4.73,4.73,0,0,0,.26,1.83v.1Zm-2.94-.68a3.14,3.14,0,0,0,1.67-.45,2.58,2.58,0,0,0,1.08-1.21v-1.86h-1.67a4.19,4.19,0,0,0-2.19.51,1.58,1.58,0,0,0-.26,2.55A2,2,0,0,0,349.37,318.1Z" />
              <path d="M358.67,318.14a2.44,2.44,0,0,0,1.63-.55,1.94,1.94,0,0,0,.72-1.42h.9a2.69,2.69,0,0,1-.49,1.41,3.09,3.09,0,0,1-1.18,1,3.55,3.55,0,0,1-1.58.36,3.27,3.27,0,0,1-2.64-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.43-2.21,3.37,3.37,0,0,1,1.26-1.49,3.44,3.44,0,0,1,1.91-.52A3.23,3.23,0,0,1,361,311a3,3,0,0,1,1,2.18H361a2.29,2.29,0,0,0-.72-1.61,2.54,2.54,0,0,0-3.61.31,4.17,4.17,0,0,0-.7,2.56v.27a4.11,4.11,0,0,0,.7,2.53A2.37,2.37,0,0,0,358.67,318.14Z" />
              <path d="M365.79,314.52l-1.07,1v3.23h-.94v-12h.94v7.68l.88-1,3.24-3.18H370l-3.59,3.57,3.9,4.89h-1.12Z" />
              <path d="M376.36,310.32l0,1.38a3.06,3.06,0,0,1,2.74-1.53,2.37,2.37,0,0,1,2.54,1.64,3.15,3.15,0,0,1,1.21-1.22,3.37,3.37,0,0,1,1.67-.42c1.81,0,2.74,1,2.77,3v5.64h-.94v-5.57a2.48,2.48,0,0,0-.48-1.68,2,2,0,0,0-1.57-.55,2.5,2.5,0,0,0-1.7.65,2.33,2.33,0,0,0-.78,1.55v5.6h-.93v-5.64a2.3,2.3,0,0,0-.52-1.62,2,2,0,0,0-1.55-.54,2.37,2.37,0,0,0-1.51.5,3,3,0,0,0-.94,1.5v5.8h-.94v-8.46Z" />
              <path d="M392.37,317.43l2.47-7.11h1l-3.61,9.89-.19.44a2.27,2.27,0,0,1-2.15,1.54,2.8,2.8,0,0,1-.72-.11v-.77l.49,0a1.75,1.75,0,0,0,1.11-.33,2.5,2.5,0,0,0,.72-1.17l.42-1.14-3.19-8.39h1Z" />
              <path d="M297.2,338.64a5.1,5.1,0,0,1-.87,3.13,2.79,2.79,0,0,1-2.35,1.16,3.1,3.1,0,0,1-2.65-1.22V346h-.93V334.32h.86l.05,1.2a3,3,0,0,1,2.65-1.35,2.81,2.81,0,0,1,2.38,1.15,5.2,5.2,0,0,1,.86,3.18Zm-.94-.17a4.34,4.34,0,0,0-.66-2.55,2.13,2.13,0,0,0-1.85-.94,2.63,2.63,0,0,0-1.48.41,2.57,2.57,0,0,0-.94,1.21v4a2.39,2.39,0,0,0,1,1.11,2.71,2.71,0,0,0,1.47.38,2.11,2.11,0,0,0,1.84-.94A4.7,4.7,0,0,0,296.26,338.47Z" />
              <path d="M303.18,335.12a3.26,3.26,0,0,0-.64-.05,2.33,2.33,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.92v1.35a2.49,2.49,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M304,338.43a4.9,4.9,0,0,1,.48-2.2,3.59,3.59,0,0,1,1.33-1.52,3.63,3.63,0,0,1,2-.54,3.47,3.47,0,0,1,2.73,1.18,4.57,4.57,0,0,1,1,3.14v.19a5.08,5.08,0,0,1-.47,2.21,3.52,3.52,0,0,1-1.34,1.51,3.58,3.58,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.55,4.55,0,0,1-1-3.14Zm.94.25a3.81,3.81,0,0,0,.79,2.49,2.65,2.65,0,0,0,4.1,0,3.93,3.93,0,0,0,.79-2.57v-.17a4.32,4.32,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.63,2.63,0,0,0-3.52.53,4,4,0,0,0-.79,2.57Z" />
              <path d="M313.08,338.47a5.1,5.1,0,0,1,.88-3.13,2.83,2.83,0,0,1,2.39-1.17,2.94,2.94,0,0,1,2.61,1.35v-4.74h.93v12H319l0-1.13a3,3,0,0,1-2.64,1.28,2.81,2.81,0,0,1-2.35-1.17,5.11,5.11,0,0,1-.9-3.18Zm.95.17a4.46,4.46,0,0,0,.65,2.55,2.1,2.1,0,0,0,1.83.93A2.52,2.52,0,0,0,319,340.6v-4a2.51,2.51,0,0,0-2.44-1.65,2.1,2.1,0,0,0-1.83.93A4.66,4.66,0,0,0,314,338.64Z" />
              <path d="M327.79,341.71a3.06,3.06,0,0,1-2.7,1.22,2.62,2.62,0,0,1-2-.78,3.45,3.45,0,0,1-.72-2.33v-5.5h.93v5.39c0,1.61.65,2.41,1.95,2.41a2.39,2.39,0,0,0,2.57-1.68v-6.12h.94v8.46h-.91Z" />
              <path d="M334.26,342.14a2.46,2.46,0,0,0,1.64-.55,1.94,1.94,0,0,0,.72-1.42h.89a2.6,2.6,0,0,1-.48,1.41,3.09,3.09,0,0,1-1.18,1,3.6,3.6,0,0,1-1.59.36,3.25,3.25,0,0,1-2.63-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.44-2.21,3.31,3.31,0,0,1,1.26-1.49,3.44,3.44,0,0,1,1.91-.52,3.23,3.23,0,0,1,2.29.83,3,3,0,0,1,1,2.18h-.89a2.37,2.37,0,0,0-.72-1.61,2.54,2.54,0,0,0-3.61.31,4.1,4.1,0,0,0-.7,2.56v.27a4,4,0,0,0,.7,2.53A2.35,2.35,0,0,0,334.26,342.14Z" />
              <path d="M340.83,332.16v2.16h1.75v.77h-1.75v5.64a1.76,1.76,0,0,0,.26,1,1,1,0,0,0,.85.34,4.73,4.73,0,0,0,.75-.08l0,.76a3,3,0,0,1-1,.13,1.66,1.66,0,0,1-1.4-.56,2.63,2.63,0,0,1-.43-1.64v-5.64h-1.56v-.77h1.56v-2.16Z" />
              <path d="M353.75,342.78a4,4,0,0,1-.18-1.18,3.43,3.43,0,0,1-1.26,1,3.81,3.81,0,0,1-1.61.34,2.8,2.8,0,0,1-2-.68,2.22,2.22,0,0,1-.76-1.72,2.28,2.28,0,0,1,1-1.95,5,5,0,0,1,2.87-.72h1.69v-1a1.84,1.84,0,0,0-.56-1.42,2.28,2.28,0,0,0-1.63-.52,2.48,2.48,0,0,0-1.61.5,1.47,1.47,0,0,0-.64,1.2h-.94a2.15,2.15,0,0,1,.94-1.74,3.55,3.55,0,0,1,2.3-.74,3.29,3.29,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a4.93,4.93,0,0,0,.26,1.83v.1Zm-2.94-.68a3.18,3.18,0,0,0,1.68-.45,2.61,2.61,0,0,0,1.07-1.21v-1.86h-1.67a4.22,4.22,0,0,0-2.19.51,1.58,1.58,0,0,0-.26,2.55A2,2,0,0,0,350.81,342.1Z" />
              <path d="M363.78,338.64a5,5,0,0,1-.88,3.13,2.76,2.76,0,0,1-2.34,1.16,3.12,3.12,0,0,1-2.66-1.22V346H357V334.32h.87l.05,1.2a3,3,0,0,1,2.65-1.35,2.81,2.81,0,0,1,2.38,1.15,5.2,5.2,0,0,1,.86,3.18Zm-.94-.17a4.34,4.34,0,0,0-.66-2.55,2.13,2.13,0,0,0-1.85-.94,2.46,2.46,0,0,0-2.43,1.62v4a2.46,2.46,0,0,0,1,1.11,2.68,2.68,0,0,0,1.47.38,2.11,2.11,0,0,0,1.84-.94A4.7,4.7,0,0,0,362.84,338.47Z" />
              <path d="M372.65,338.64a5.1,5.1,0,0,1-.88,3.13,2.76,2.76,0,0,1-2.34,1.16,3.12,3.12,0,0,1-2.66-1.22V346h-.93V334.32h.87l.05,1.2a3,3,0,0,1,2.64-1.35,2.79,2.79,0,0,1,2.38,1.15,5.14,5.14,0,0,1,.87,3.18Zm-.94-.17a4.26,4.26,0,0,0-.67-2.55,2.12,2.12,0,0,0-1.85-.94,2.61,2.61,0,0,0-1.47.41,2.65,2.65,0,0,0-1,1.21v4a2.5,2.5,0,0,0,1,1.11,2.74,2.74,0,0,0,1.48.38,2.11,2.11,0,0,0,1.84-.94A4.7,4.7,0,0,0,371.71,338.47Z" />
              <path d="M378.62,335.12a3.22,3.22,0,0,0-.63-.05,2.31,2.31,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.91l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M379.43,338.43a4.9,4.9,0,0,1,.47-2.2,3.61,3.61,0,0,1,1.34-1.52,3.57,3.57,0,0,1,1.95-.54,3.48,3.48,0,0,1,2.74,1.18,4.56,4.56,0,0,1,1,3.14v.19a4.94,4.94,0,0,1-.47,2.21,3.5,3.5,0,0,1-1.33,1.51,3.6,3.6,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.55,4.55,0,0,1-1.05-3.14Zm.94.25a3.86,3.86,0,0,0,.78,2.49,2.66,2.66,0,0,0,4.11,0,4,4,0,0,0,.78-2.57v-.17a4.49,4.49,0,0,0-.35-1.78,2.91,2.91,0,0,0-1-1.24,2.55,2.55,0,0,0-1.49-.45,2.52,2.52,0,0,0-2,1,4,4,0,0,0-.78,2.57Z" />
              <path d="M391.42,341.49l2.57-7.17h1l-3.16,8.46H391l-3.17-8.46h1Z" />
              <path d="M401.7,342.78a4.81,4.81,0,0,1-.18-1.18,3.4,3.4,0,0,1-1.25,1,3.89,3.89,0,0,1-1.62.34,2.8,2.8,0,0,1-2-.68,2.21,2.21,0,0,1-.75-1.72,2.25,2.25,0,0,1,1-1.95,4.9,4.9,0,0,1,2.86-.72h1.69v-1a1.84,1.84,0,0,0-.55-1.42,2.31,2.31,0,0,0-1.63-.52,2.51,2.51,0,0,0-1.62.5,1.47,1.47,0,0,0-.64,1.2h-.94a2.15,2.15,0,0,1,.94-1.74,3.55,3.55,0,0,1,2.3-.74,3.28,3.28,0,0,1,2.23.7,2.52,2.52,0,0,1,.84,2v4a4.93,4.93,0,0,0,.26,1.83v.1Zm-2.94-.68a3.15,3.15,0,0,0,1.68-.45,2.61,2.61,0,0,0,1.07-1.21v-1.86h-1.67a4.22,4.22,0,0,0-2.19.51,1.55,1.55,0,0,0-.78,1.37,1.53,1.53,0,0,0,.52,1.18A2,2,0,0,0,398.76,342.1Z" />
              <path d="M406,342.78H405v-12H406Z" />
            </g>
          </HoverCircle>
          <HoverCircle className="hover-circle left-hover">
            <circle
              id="left-hover"
              cx="348.76"
              cy="263.58"
              r="126.93"
              fill="#fff"
            />
            <g id="left-copy">
              <path
                d="M269.75,204.79v4.49h-2.1V197.19h4.62a4.65,4.65,0,0,1,3.22,1.06,3.52,3.52,0,0,1,1.19,2.79,3.46,3.46,0,0,1-1.16,2.77,5,5,0,0,1-3.27,1Zm0-1.69h2.52a2.43,2.43,0,0,0,1.71-.53,1.91,1.91,0,0,0,.59-1.52,2.11,2.11,0,0,0-.59-1.56,2.36,2.36,0,0,0-1.65-.61h-2.58Z"
                fill="#8e74bf"
              />
              <path
                d="M283,202.14a4.39,4.39,0,0,0-.82-.07,1.88,1.88,0,0,0-1.87,1.06v6.15h-2v-9h1.92l.05,1a2.29,2.29,0,0,1,2-1.17,1.81,1.81,0,0,1,.71.12Z"
                fill="#8e74bf"
              />
              <path
                d="M283.62,204.7a5.43,5.43,0,0,1,.52-2.38,3.85,3.85,0,0,1,1.47-1.62,4.15,4.15,0,0,1,2.18-.57,3.93,3.93,0,0,1,2.95,1.17,4.56,4.56,0,0,1,1.22,3.1v.48a5.35,5.35,0,0,1-.51,2.37,3.77,3.77,0,0,1-1.46,1.62,4.16,4.16,0,0,1-2.19.57,3.92,3.92,0,0,1-3.05-1.26,4.87,4.87,0,0,1-1.14-3.38Zm2,.18a3.65,3.65,0,0,0,.57,2.17,1.86,1.86,0,0,0,1.6.78,1.81,1.81,0,0,0,1.58-.79,4,4,0,0,0,.57-2.34,3.61,3.61,0,0,0-.58-2.16,2,2,0,0,0-3.16,0A3.94,3.94,0,0,0,285.64,204.88Z"
                fill="#8e74bf"
              />
              <path
                d="M299,209.28a3.62,3.62,0,0,1-.23-.84,3.4,3.4,0,0,1-4.56.23,2.47,2.47,0,0,1-.86-1.91,2.54,2.54,0,0,1,1.07-2.2,5.18,5.18,0,0,1,3.05-.77h1.24v-.59a1.59,1.59,0,0,0-.39-1.12,1.55,1.55,0,0,0-1.19-.41A1.77,1.77,0,0,0,296,202a1.08,1.08,0,0,0-.44.88h-2a2.29,2.29,0,0,1,.49-1.39,3.34,3.34,0,0,1,1.33-1,4.77,4.77,0,0,1,1.88-.36,3.77,3.77,0,0,1,2.52.79,2.79,2.79,0,0,1,1,2.23v4a4.75,4.75,0,0,0,.34,1.94v.14Zm-2.21-1.46a2.2,2.2,0,0,0,1.12-.29,1.94,1.94,0,0,0,.8-.78v-1.69h-1.09a3,3,0,0,0-1.69.39,1.27,1.27,0,0,0-.56,1.1,1.21,1.21,0,0,0,.38.93A1.52,1.52,0,0,0,296.83,207.82Z"
                fill="#8e74bf"
              />
              <path
                d="M306.56,207.83a1.79,1.79,0,0,0,1.26-.44,1.48,1.48,0,0,0,.53-1.08h1.9a3,3,0,0,1-.52,1.56A3.47,3.47,0,0,1,308.4,209a4,4,0,0,1-1.81.42,3.81,3.81,0,0,1-3-1.22,4.85,4.85,0,0,1-1.1-3.37v-.2a4.77,4.77,0,0,1,1.09-3.29,3.8,3.8,0,0,1,3-1.23,3.69,3.69,0,0,1,2.61.93,3.36,3.36,0,0,1,1.06,2.46h-1.9a1.82,1.82,0,0,0-.53-1.27,1.67,1.67,0,0,0-1.26-.5,1.77,1.77,0,0,0-1.51.71,3.67,3.67,0,0,0-.54,2.15v.33a3.65,3.65,0,0,0,.53,2.17A1.77,1.77,0,0,0,306.56,207.83Z"
                fill="#8e74bf"
              />
              <path
                d="M314.3,198.11v2.19h1.59v1.49H314.3v5a1.16,1.16,0,0,0,.2.75,1,1,0,0,0,.73.22,2.92,2.92,0,0,0,.71-.08v1.56a4.91,4.91,0,0,1-1.33.19c-1.55,0-2.33-.85-2.33-2.56v-5.09h-1.47V200.3h1.47v-2.19Z"
                fill="#8e74bf"
              />
              <path
                d="M317.43,198a1.06,1.06,0,0,1,.3-.77,1.11,1.11,0,0,1,.84-.31,1.12,1.12,0,0,1,.84.31,1.06,1.06,0,0,1,.3.77,1,1,0,0,1-.3.76,1.12,1.12,0,0,1-.84.31,1.11,1.11,0,0,1-.84-.31A1,1,0,0,1,317.43,198Zm2.14,11.32h-2v-9h2Z"
                fill="#8e74bf"
              />
              <path
                d="M324.93,206.72l1.91-6.42h2.08l-3.11,9h-1.76l-3.13-9H323Z"
                fill="#8e74bf"
              />
              <path
                d="M334,209.44a4.14,4.14,0,0,1-3.1-1.21,4.34,4.34,0,0,1-1.2-3.21v-.25a5.43,5.43,0,0,1,.52-2.4,3.88,3.88,0,0,1,3.55-2.24,3.55,3.55,0,0,1,2.84,1.17,5,5,0,0,1,1,3.31v.82h-5.87a2.74,2.74,0,0,0,.74,1.76,2.24,2.24,0,0,0,1.64.64,2.73,2.73,0,0,0,2.26-1.12l1.09,1A3.61,3.61,0,0,1,336,209,4.57,4.57,0,0,1,334,209.44Zm-.24-7.69a1.69,1.69,0,0,0-1.34.58,3.08,3.08,0,0,0-.65,1.62h3.84v-.15a2.37,2.37,0,0,0-.54-1.53A1.66,1.66,0,0,0,333.78,201.75Z"
                fill="#8e74bf"
              />
              <path d="M341.34,209.28h-2V196.53h2Z" fill="#8e74bf" />
              <path
                d="M346.67,206.39l1.83-6.09h2.15l-3.57,10.34a2.9,2.9,0,0,1-2.79,2.26,3.59,3.59,0,0,1-1-.15v-1.56l.38,0a1.93,1.93,0,0,0,1.15-.28,1.78,1.78,0,0,0,.61-.93l.29-.77-3.15-8.93h2.17Z"
                fill="#8e74bf"
              />
              <path
                d="M358.57,198.11v2.19h1.58v1.49h-1.58v5a1.16,1.16,0,0,0,.2.75,1,1,0,0,0,.73.22,2.79,2.79,0,0,0,.7-.08v1.56a4.79,4.79,0,0,1-1.32.19c-1.55,0-2.33-.85-2.33-2.56v-5.09h-1.48V200.3h1.48v-2.19Z"
                fill="#8e74bf"
              />
              <path
                d="M367.06,209.28a2.83,2.83,0,0,1-.23-.84,3.41,3.41,0,0,1-4.57.23,2.46,2.46,0,0,1-.85-1.91,2.54,2.54,0,0,1,1.06-2.2,5.22,5.22,0,0,1,3.06-.77h1.23v-.59a1.59,1.59,0,0,0-.39-1.12,1.54,1.54,0,0,0-1.19-.41,1.76,1.76,0,0,0-1.12.34,1,1,0,0,0-.44.88h-2a2.23,2.23,0,0,1,.49-1.39,3.34,3.34,0,0,1,1.33-1,4.74,4.74,0,0,1,1.88-.36,3.78,3.78,0,0,1,2.52.79,2.85,2.85,0,0,1,1,2.23v4a4.59,4.59,0,0,0,.34,1.94v.14Zm-2.22-1.46a2.24,2.24,0,0,0,1.13-.29,1.92,1.92,0,0,0,.79-.78v-1.69h-1.09a3,3,0,0,0-1.68.39,1.26,1.26,0,0,0-.57,1.1,1.18,1.18,0,0,0,.39.93A1.49,1.49,0,0,0,364.84,207.82Z"
                fill="#8e74bf"
              />
              <path
                d="M370.9,198a1.06,1.06,0,0,1,.29-.77,1.31,1.31,0,0,1,1.69,0,1.06,1.06,0,0,1,.3.77,1,1,0,0,1-.3.76,1.31,1.31,0,0,1-1.69,0A1,1,0,0,1,370.9,198ZM373,209.28h-2v-9h2Z"
                fill="#8e74bf"
              />
              <path d="M377.38,209.28h-2V196.53h2Z" fill="#8e74bf" />
              <path
                d="M379.2,204.7a5.3,5.3,0,0,1,.52-2.38,3.85,3.85,0,0,1,1.47-1.62,4.11,4.11,0,0,1,2.17-.57,3.9,3.9,0,0,1,2.95,1.17,4.57,4.57,0,0,1,1.23,3.1v.48a5.35,5.35,0,0,1-.51,2.37,3.77,3.77,0,0,1-1.46,1.62,4.22,4.22,0,0,1-2.2.57,3.89,3.89,0,0,1-3-1.26,4.87,4.87,0,0,1-1.14-3.38Zm2,.18a3.58,3.58,0,0,0,.58,2.17,2,2,0,0,0,3.18,0,4,4,0,0,0,.57-2.34,3.61,3.61,0,0,0-.59-2.16,2,2,0,0,0-3.16,0A4,4,0,0,0,381.21,204.88Z"
                fill="#8e74bf"
              />
              <path
                d="M394,202.14a4.5,4.5,0,0,0-.83-.07,1.85,1.85,0,0,0-1.86,1.06v6.15h-2v-9h1.92l.05,1a2.29,2.29,0,0,1,2-1.17,1.78,1.78,0,0,1,.71.12Z"
                fill="#8e74bf"
              />
              <path
                d="M402.6,206.39l1.83-6.09h2.15L403,210.64a2.9,2.9,0,0,1-2.79,2.26,3.65,3.65,0,0,1-1-.15v-1.56l.38,0a1.91,1.91,0,0,0,1.15-.28,1.78,1.78,0,0,0,.61-.93l.29-.77-3.15-8.93h2.17Z"
                fill="#8e74bf"
              />
              <path
                d="M407.24,204.7a5.3,5.3,0,0,1,.52-2.38,3.85,3.85,0,0,1,1.47-1.62,4.11,4.11,0,0,1,2.17-.57,3.9,3.9,0,0,1,3,1.17,4.57,4.57,0,0,1,1.23,3.1v.48a5.35,5.35,0,0,1-.51,2.37,3.77,3.77,0,0,1-1.46,1.62,4.22,4.22,0,0,1-2.2.57,3.89,3.89,0,0,1-3-1.26,4.87,4.87,0,0,1-1.14-3.38Zm2,.18a3.58,3.58,0,0,0,.58,2.17,2,2,0,0,0,3.18,0,4,4,0,0,0,.57-2.34,3.61,3.61,0,0,0-.59-2.16,2,2,0,0,0-3.16,0A4,4,0,0,0,409.25,204.88Z"
                fill="#8e74bf"
              />
              <path
                d="M422.73,208.4a3.14,3.14,0,0,1-2.52,1,2.79,2.79,0,0,1-2.21-.85,3.66,3.66,0,0,1-.75-2.48V200.3h2v5.79c0,1.14.48,1.71,1.42,1.71a2,2,0,0,0,2-1.05V200.3h2v9h-1.9Z"
                fill="#8e74bf"
              />
              <path
                d="M431.44,202.14a4.39,4.39,0,0,0-.82-.07,1.87,1.87,0,0,0-1.87,1.06v6.15h-2v-9h1.92l0,1a2.28,2.28,0,0,1,2-1.17,1.85,1.85,0,0,1,.71.12Z"
                fill="#8e74bf"
              />
              <path
                d="M303.64,229.68a2.83,2.83,0,0,1-.23-.84,3.41,3.41,0,0,1-4.57.23,2.46,2.46,0,0,1-.85-1.91,2.54,2.54,0,0,1,1.06-2.2,5.18,5.18,0,0,1,3.05-.77h1.24v-.59a1.59,1.59,0,0,0-.39-1.12,1.55,1.55,0,0,0-1.19-.42,1.79,1.79,0,0,0-1.13.35,1.08,1.08,0,0,0-.44.88h-2a2.23,2.23,0,0,1,.49-1.39,3.27,3.27,0,0,1,1.33-1,4.74,4.74,0,0,1,1.88-.36,3.74,3.74,0,0,1,2.51.79,2.83,2.83,0,0,1,1,2.23v4a4.59,4.59,0,0,0,.34,1.94v.14Zm-2.22-1.46a2.24,2.24,0,0,0,1.13-.29,1.92,1.92,0,0,0,.79-.78v-1.69h-1.09a3,3,0,0,0-1.68.39A1.26,1.26,0,0,0,300,227a1.18,1.18,0,0,0,.39.93A1.49,1.49,0,0,0,301.42,228.22Z"
                fill="#8e74bf"
              />
              <path
                d="M307.09,225.12a5.27,5.27,0,0,1,1-3.33,3.08,3.08,0,0,1,2.58-1.26,2.89,2.89,0,0,1,2.3,1v-4.6h2v12.75h-1.82l-.1-.93a3,3,0,0,1-2.42,1.09,3.06,3.06,0,0,1-2.55-1.27A5.51,5.51,0,0,1,307.09,225.12Zm2,.17a3.74,3.74,0,0,0,.53,2.14,1.71,1.71,0,0,0,1.49.77,1.9,1.9,0,0,0,1.81-1.1v-3.84a1.87,1.87,0,0,0-1.79-1.08,1.72,1.72,0,0,0-1.51.78A4.16,4.16,0,0,0,309.11,225.29Z"
                fill="#8e74bf"
              />
              <path
                d="M320.23,227.12l1.9-6.42h2.09l-3.12,9h-1.75l-3.14-9h2.1Z"
                fill="#8e74bf"
              />
              <path
                d="M325.48,218.36a1.1,1.1,0,0,1,.29-.77,1.31,1.31,0,0,1,1.69,0,1.06,1.06,0,0,1,.3.77,1,1,0,0,1-.3.76,1.31,1.31,0,0,1-1.69,0A1.06,1.06,0,0,1,325.48,218.36Zm2.14,11.32h-2v-9h2Z"
                fill="#8e74bf"
              />
              <path
                d="M333.51,228.23a1.79,1.79,0,0,0,1.25-.44,1.48,1.48,0,0,0,.53-1.08h1.9a3,3,0,0,1-.52,1.56,3.47,3.47,0,0,1-1.33,1.15,4,4,0,0,1-1.81.42,3.81,3.81,0,0,1-3-1.22,4.85,4.85,0,0,1-1.1-3.37V225a4.76,4.76,0,0,1,1.09-3.28,3.8,3.8,0,0,1,3-1.23,3.69,3.69,0,0,1,2.61.93,3.36,3.36,0,0,1,1.06,2.46h-1.9a1.82,1.82,0,0,0-.53-1.27,1.67,1.67,0,0,0-1.25-.5,1.77,1.77,0,0,0-1.52.71,3.6,3.6,0,0,0-.53,2.15v.33a3.65,3.65,0,0,0,.52,2.17A1.78,1.78,0,0,0,333.51,228.23Z"
                fill="#8e74bf"
              />
              <path
                d="M342.67,229.84a4.15,4.15,0,0,1-3.11-1.21,4.37,4.37,0,0,1-1.19-3.21v-.25a5.29,5.29,0,0,1,.52-2.4,3.88,3.88,0,0,1,3.55-2.24,3.52,3.52,0,0,1,2.83,1.17,4.92,4.92,0,0,1,1,3.31v.82H340.4a2.64,2.64,0,0,0,.75,1.75,2.19,2.19,0,0,0,1.64.65,2.71,2.71,0,0,0,2.25-1.12l1.09,1a3.64,3.64,0,0,1-1.44,1.25A4.57,4.57,0,0,1,342.67,229.84Zm-.24-7.69a1.69,1.69,0,0,0-1.34.58,3,3,0,0,0-.65,1.62h3.84v-.15a2.37,2.37,0,0,0-.54-1.53A1.67,1.67,0,0,0,342.43,222.15Z"
                fill="#8e74bf"
              />
              <path
                d="M352.78,229.68v-7.49h-1.37V220.7h1.37v-.83a3.09,3.09,0,0,1,.83-2.3,3.14,3.14,0,0,1,2.32-.82,4.63,4.63,0,0,1,1.13.15l-.05,1.58a3.55,3.55,0,0,0-.77-.07,1.3,1.3,0,0,0-1.44,1.49v.8h1.82v1.49H354.8v7.49Z"
                fill="#8e74bf"
              />
              <path
                d="M357.72,225.1a5.3,5.3,0,0,1,.52-2.38,3.85,3.85,0,0,1,1.47-1.62,4.15,4.15,0,0,1,2.18-.57,3.93,3.93,0,0,1,2.95,1.17,4.61,4.61,0,0,1,1.22,3.1v.48a5.35,5.35,0,0,1-.51,2.37,3.77,3.77,0,0,1-1.46,1.62,4.22,4.22,0,0,1-2.2.57,3.89,3.89,0,0,1-3-1.26,4.87,4.87,0,0,1-1.14-3.38Zm2,.18a3.65,3.65,0,0,0,.57,2.17,2,2,0,0,0,3.18,0,4,4,0,0,0,.57-2.34,3.54,3.54,0,0,0-.59-2.16,2,2,0,0,0-3.15,0A3.94,3.94,0,0,0,359.74,225.28Z"
                fill="#8e74bf"
              />
              <path
                d="M372.48,222.54a4.47,4.47,0,0,0-.82-.07,1.86,1.86,0,0,0-1.87,1.06v6.15h-2v-9h1.93l.05,1a2.26,2.26,0,0,1,2-1.17,1.87,1.87,0,0,1,.72.12Z"
                fill="#8e74bf"
              />
              <path
                d="M379.88,220.7l.06.93a3.24,3.24,0,0,1,2.59-1.1,2.5,2.5,0,0,1,2.47,1.38,3.18,3.18,0,0,1,2.75-1.38,2.78,2.78,0,0,1,2.21.82,3.64,3.64,0,0,1,.75,2.42v5.91h-2v-5.85a1.83,1.83,0,0,0-.37-1.25,1.64,1.64,0,0,0-1.24-.4,1.67,1.67,0,0,0-1.13.37,2,2,0,0,0-.61,1v6.16h-2v-5.91a1.43,1.43,0,0,0-1.61-1.59,1.77,1.77,0,0,0-1.72,1v6.51h-2v-9Z"
                fill="#8e74bf"
              />
              <path
                d="M396.73,229.84a4.17,4.17,0,0,1-3.11-1.21,4.37,4.37,0,0,1-1.19-3.21v-.25a5.43,5.43,0,0,1,.52-2.4,3.91,3.91,0,0,1,1.46-1.65,3.86,3.86,0,0,1,2.09-.59,3.51,3.51,0,0,1,2.83,1.17,4.92,4.92,0,0,1,1,3.31v.82h-5.86a2.64,2.64,0,0,0,.74,1.75,2.19,2.19,0,0,0,1.64.65,2.73,2.73,0,0,0,2.26-1.12l1.08,1a3.64,3.64,0,0,1-1.44,1.25A4.57,4.57,0,0,1,396.73,229.84Zm-.24-7.69a1.69,1.69,0,0,0-1.34.58,3,3,0,0,0-.65,1.62h3.84v-.15a2.37,2.37,0,0,0-.54-1.53A1.66,1.66,0,0,0,396.49,222.15Z"
                fill="#8e74bf"
              />
              <path d="M264.72,250.48h-5.16l-1.18,3.2h-1l4.3-11.38h.92l4.3,11.38h-1Zm-4.87-.81h4.57l-2.29-6.2Z" />
              <path d="M269.24,245.22l0,1.45a3.28,3.28,0,0,1,1.18-1.19,3,3,0,0,1,1.57-.41,2.55,2.55,0,0,1,2,.76,3.5,3.5,0,0,1,.67,2.3v5.55h-.93v-5.56a2.66,2.66,0,0,0-.48-1.69,1.89,1.89,0,0,0-1.51-.55,2.39,2.39,0,0,0-1.54.54,3.31,3.31,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M278.6,243.06v2.16h1.75V246H278.6v5.64a1.77,1.77,0,0,0,.25,1.05,1,1,0,0,0,.85.34,6.5,6.5,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.63,1.63,0,0,1-1.39-.56,2.57,2.57,0,0,1-.44-1.64V246H276.1v-.77h1.56v-2.16Z" />
              <path d="M282.23,242.78a.64.64,0,0,1,.17-.44.58.58,0,0,1,.47-.19.6.6,0,0,1,.47.19.6.6,0,0,1,.18.44.57.57,0,0,1-.18.44.63.63,0,0,1-.47.18.61.61,0,0,1-.47-.18A.6.6,0,0,1,282.23,242.78Zm1.1,10.9h-.94v-8.46h.94Z" />
              <path d="M289,253a2.45,2.45,0,0,0,1.64-.55,1.94,1.94,0,0,0,.72-1.42h.89a2.6,2.6,0,0,1-.48,1.41,3.09,3.09,0,0,1-1.18,1,3.6,3.6,0,0,1-1.59.36,3.25,3.25,0,0,1-2.63-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.44-2.21,3.31,3.31,0,0,1,1.26-1.49,3.44,3.44,0,0,1,1.91-.52,3.23,3.23,0,0,1,2.29.83,3,3,0,0,1,1,2.18h-.89a2.43,2.43,0,0,0-4.33-1.3,4.17,4.17,0,0,0-.7,2.56v.27a4.11,4.11,0,0,0,.7,2.53A2.36,2.36,0,0,0,289,253Z" />
              <path d="M294.06,242.78a.6.6,0,0,1,.17-.44.58.58,0,0,1,.47-.19.6.6,0,0,1,.47.19.6.6,0,0,1,.18.44.57.57,0,0,1-.18.44.63.63,0,0,1-.47.18.61.61,0,0,1-.47-.18A.57.57,0,0,1,294.06,242.78Zm1.1,10.9h-.94v-8.46h.94Z" />
              <path d="M304.49,249.54a5.1,5.1,0,0,1-.87,3.13,2.77,2.77,0,0,1-2.34,1.16,3.11,3.11,0,0,1-2.66-1.22v4.32h-.93V245.22h.87l0,1.2a3,3,0,0,1,2.65-1.35,2.8,2.8,0,0,1,2.38,1.15,5.14,5.14,0,0,1,.86,3.18Zm-.93-.17a4.34,4.34,0,0,0-.67-2.55,2.13,2.13,0,0,0-1.85-.94,2.67,2.67,0,0,0-1.48.41,2.63,2.63,0,0,0-.94,1.21v4.05a2.39,2.39,0,0,0,1,1.11,2.74,2.74,0,0,0,1.48.38,2.12,2.12,0,0,0,1.84-.94A4.72,4.72,0,0,0,303.56,249.37Z" />
              <path d="M311.9,253.68a4.81,4.81,0,0,1-.18-1.18,3.48,3.48,0,0,1-1.25,1,3.89,3.89,0,0,1-1.62.34,2.8,2.8,0,0,1-2-.68,2.2,2.2,0,0,1-.75-1.71,2.27,2.27,0,0,1,1-2,5,5,0,0,1,2.87-.72h1.69v-1a1.81,1.81,0,0,0-.56-1.42,2.27,2.27,0,0,0-1.62-.52,2.49,2.49,0,0,0-1.62.5,1.47,1.47,0,0,0-.64,1.2h-.94a2.15,2.15,0,0,1,.94-1.74,3.55,3.55,0,0,1,2.3-.74,3.29,3.29,0,0,1,2.23.7,2.52,2.52,0,0,1,.84,2v4a5,5,0,0,0,.26,1.83v.1ZM309,253a3.16,3.16,0,0,0,1.68-.46,2.61,2.61,0,0,0,1.07-1.21v-1.86H310a4.22,4.22,0,0,0-2.19.51,1.56,1.56,0,0,0-.79,1.37,1.54,1.54,0,0,0,.53,1.18A2,2,0,0,0,309,253Z" />
              <path d="M316.59,243.06v2.16h1.75V246h-1.75v5.64a1.77,1.77,0,0,0,.25,1.05,1,1,0,0,0,.85.34,6.29,6.29,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.44-1.64V246H314.1v-.77h1.55v-2.16Z" />
              <path d="M323.55,253.83a3.61,3.61,0,0,1-1.95-.53,3.67,3.67,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08.95,3.05,3.05,0,0,0,1.32-.27,3,3,0,0,0,1-.87l.58.44A3.39,3.39,0,0,1,323.55,253.83Zm-.17-8a2.32,2.32,0,0,0-1.78.78,3.55,3.55,0,0,0-.88,2.08h5.07v-.11a3.16,3.16,0,0,0-.69-2A2.18,2.18,0,0,0,323.38,245.86Z" />
              <path d="M337.77,253.68a4,4,0,0,1-.18-1.18,3.43,3.43,0,0,1-1.26,1,3.81,3.81,0,0,1-1.61.34,2.8,2.8,0,0,1-2-.68,2.21,2.21,0,0,1-.76-1.71,2.28,2.28,0,0,1,1-2,4.94,4.94,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.28,2.28,0,0,0-1.63-.52,2.48,2.48,0,0,0-1.61.5,1.48,1.48,0,0,0-.65,1.2h-.93a2.17,2.17,0,0,1,.93-1.74,3.59,3.59,0,0,1,2.31-.74,3.29,3.29,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a5,5,0,0,0,.26,1.83v.1Zm-2.94-.67a3.18,3.18,0,0,0,1.68-.46,2.61,2.61,0,0,0,1.07-1.21v-1.86h-1.67a4.22,4.22,0,0,0-2.19.51,1.58,1.58,0,0,0-.26,2.55A2,2,0,0,0,334.83,253Z" />
              <path d="M341.89,245.22l0,1.45a3.39,3.39,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.55,2.55,0,0,1,2,.76,3.5,3.5,0,0,1,.67,2.3v5.55h-.93v-5.56a2.66,2.66,0,0,0-.48-1.69,1.89,1.89,0,0,0-1.51-.55,2.39,2.39,0,0,0-1.54.54,3.23,3.23,0,0,0-1,1.47v5.79H341v-8.46Z" />
              <path d="M349.42,249.37a5.1,5.1,0,0,1,.87-3.13,2.84,2.84,0,0,1,2.39-1.17,2.94,2.94,0,0,1,2.61,1.35v-4.74h.93v12h-.87l0-1.13a3,3,0,0,1-2.64,1.28,2.83,2.83,0,0,1-2.36-1.17,5.13,5.13,0,0,1-.89-3.18Zm.94.17a4.44,4.44,0,0,0,.65,2.55,2.1,2.1,0,0,0,1.83.93,2.51,2.51,0,0,0,2.45-1.52v-4a2.5,2.5,0,0,0-2.44-1.65,2.12,2.12,0,0,0-1.83.93A4.66,4.66,0,0,0,350.36,249.54Z" />
              <path d="M368,252.61a3.05,3.05,0,0,1-2.7,1.22,2.62,2.62,0,0,1-2.05-.78,3.45,3.45,0,0,1-.72-2.33v-5.5h.93v5.39c0,1.61.65,2.41,1.95,2.41a2.39,2.39,0,0,0,2.57-1.68v-6.12h.94v8.46H368Z" />
              <path d="M372.25,245.22l0,1.45a3.39,3.39,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.52,2.52,0,0,1,2,.76,3.45,3.45,0,0,1,.68,2.3v5.55h-.93v-5.56a2.66,2.66,0,0,0-.48-1.69,1.9,1.9,0,0,0-1.52-.55,2.36,2.36,0,0,0-1.53.54,3.15,3.15,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M379.78,249.37a5.1,5.1,0,0,1,.87-3.13,2.84,2.84,0,0,1,2.39-1.17,2.94,2.94,0,0,1,2.61,1.35v-4.74h.93v12h-.87l0-1.13a3,3,0,0,1-2.64,1.28,2.83,2.83,0,0,1-2.36-1.17,5.13,5.13,0,0,1-.89-3.18Zm.94.17a4.44,4.44,0,0,0,.65,2.55,2.1,2.1,0,0,0,1.83.93,2.51,2.51,0,0,0,2.45-1.52v-4a2.5,2.5,0,0,0-2.44-1.65,2.11,2.11,0,0,0-1.83.93A4.66,4.66,0,0,0,380.72,249.54Z" />
              <path d="M392.31,253.83a3.6,3.6,0,0,1-1.94-.53,3.54,3.54,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a4.9,4.9,0,0,1,.47-2.2,3.7,3.7,0,0,1,1.31-1.53,3.23,3.23,0,0,1,1.83-.56,3,3,0,0,1,2.44,1.05,4.22,4.22,0,0,1,.91,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.07.95,3.13,3.13,0,0,0,1.33-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.41,3.41,0,0,1,392.31,253.83Zm-.17-8a2.31,2.31,0,0,0-1.77.78,3.49,3.49,0,0,0-.88,2.08h5.07v-.11a3.11,3.11,0,0,0-.7-2A2.16,2.16,0,0,0,392.14,245.86Z" />
              <path d="M401.21,246a3.22,3.22,0,0,0-.63-.05,2.31,2.31,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.91l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.55,1.55,0,0,1,.62.1Z" />
              <path d="M407.75,251.53a1.31,1.31,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.56,7.56,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.84-1.65,3.39,3.39,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.22,2.22,0,0,1,.85,1.82h-.93a1.49,1.49,0,0,0-.63-1.23,2.41,2.41,0,0,0-1.56-.5,2.45,2.45,0,0,0-1.48.41,1.26,1.26,0,0,0-.56,1.07,1.15,1.15,0,0,0,.46,1,4.92,4.92,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.37,2.37,0,0,1,.91.78,2.17,2.17,0,0,1-.56,2.83,3.69,3.69,0,0,1-2.26.64,3.76,3.76,0,0,1-2.39-.71,2.21,2.21,0,0,1-.92-1.81h.94a1.6,1.6,0,0,0,.69,1.28,2.83,2.83,0,0,0,1.68.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,407.75,251.53Z" />
              <path d="M412.24,243.06v2.16H414V246h-1.75v5.64a1.76,1.76,0,0,0,.26,1.05,1,1,0,0,0,.85.34,6.38,6.38,0,0,0,.75-.08l0,.76a3,3,0,0,1-1,.13,1.66,1.66,0,0,1-1.4-.56,2.63,2.63,0,0,1-.43-1.64V246h-1.56v-.77h1.56v-2.16Z" />
              <path d="M421.27,253.68a4,4,0,0,1-.18-1.18,3.43,3.43,0,0,1-1.26,1,3.81,3.81,0,0,1-1.61.34,2.8,2.8,0,0,1-2-.68,2.21,2.21,0,0,1-.76-1.71,2.28,2.28,0,0,1,1-2,4.94,4.94,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.28,2.28,0,0,0-1.63-.52,2.48,2.48,0,0,0-1.61.5,1.48,1.48,0,0,0-.65,1.2h-.93a2.17,2.17,0,0,1,.93-1.74,3.59,3.59,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a5,5,0,0,0,.26,1.83v.1Zm-2.94-.67a3.18,3.18,0,0,0,1.68-.46,2.61,2.61,0,0,0,1.07-1.21v-1.86h-1.67a4.22,4.22,0,0,0-2.19.51,1.58,1.58,0,0,0-.26,2.55A2,2,0,0,0,418.33,253Z" />
              <path d="M425.39,245.22l0,1.45a3.39,3.39,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.55,2.55,0,0,1,2,.76,3.5,3.5,0,0,1,.67,2.3v5.55H430v-5.56a2.66,2.66,0,0,0-.48-1.69,1.89,1.89,0,0,0-1.51-.55,2.39,2.39,0,0,0-1.54.54,3.15,3.15,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M432.92,249.37a5.1,5.1,0,0,1,.87-3.13,2.84,2.84,0,0,1,2.39-1.17,2.94,2.94,0,0,1,2.61,1.35v-4.74h.93v12h-.87l0-1.13a3,3,0,0,1-2.64,1.28,2.83,2.83,0,0,1-2.36-1.17,5.13,5.13,0,0,1-.89-3.18Zm.94.17a4.44,4.44,0,0,0,.65,2.55,2.1,2.1,0,0,0,1.83.93,2.51,2.51,0,0,0,2.45-1.52v-4a2.5,2.5,0,0,0-2.44-1.65,2.12,2.12,0,0,0-1.83.93A4.66,4.66,0,0,0,433.86,249.54Z" />
              <path d="M253.42,269.22l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.37,2.37,0,0,1,2.54,1.64,3.15,3.15,0,0,1,1.21-1.22,3.37,3.37,0,0,1,1.67-.42c1.81,0,2.74,1,2.77,3v5.64h-.93v-5.57a2.53,2.53,0,0,0-.49-1.68,2,2,0,0,0-1.57-.55,2.5,2.5,0,0,0-1.7.65,2.32,2.32,0,0,0-.77,1.55v5.6H258V272a2.34,2.34,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.4,2.4,0,0,0-1.52.5,3.05,3.05,0,0,0-.94,1.5v5.8h-.93v-8.46Z" />
              <path d="M269.43,276.33l2.47-7.11h1l-3.61,9.89-.19.44a2.26,2.26,0,0,1-2.15,1.54,2.8,2.8,0,0,1-.72-.11v-.77l.48.05a1.75,1.75,0,0,0,1.11-.34,2.59,2.59,0,0,0,.73-1.17l.41-1.14-3.19-8.39h1Z" />
              <path d="M279.13,269.22l0,1.45a3.28,3.28,0,0,1,1.18-1.19,3,3,0,0,1,1.57-.41,2.55,2.55,0,0,1,2,.76,3.5,3.5,0,0,1,.67,2.3v5.55h-.93v-5.56a2.63,2.63,0,0,0-.48-1.68,1.86,1.86,0,0,0-1.51-.56,2.39,2.39,0,0,0-1.54.54,3.31,3.31,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M290.31,277.83a3.63,3.63,0,0,1-3.31-2,4.64,4.64,0,0,1-.48-2.13v-.33a4.9,4.9,0,0,1,.47-2.2,3.73,3.73,0,0,1,1.32-1.53,3.17,3.17,0,0,1,1.82-.56,3.08,3.08,0,0,1,2.45,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08,1,3.05,3.05,0,0,0,1.32-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.4,3.4,0,0,1,290.31,277.83Zm-.18-8a2.31,2.31,0,0,0-1.77.78,3.55,3.55,0,0,0-.88,2.08h5.07v-.11a3.11,3.11,0,0,0-.7-2A2.15,2.15,0,0,0,290.13,269.86Z" />
              <path d="M298.57,277.83a3.6,3.6,0,0,1-3.3-2,4.52,4.52,0,0,1-.49-2.13v-.33a4.9,4.9,0,0,1,.48-2.2,3.7,3.7,0,0,1,1.31-1.53,3.18,3.18,0,0,1,1.83-.56,3,3,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.56,3.56,0,0,0,.83,2.4,2.61,2.61,0,0,0,2.07,1,3.13,3.13,0,0,0,1.33-.27,2.9,2.9,0,0,0,1-.87l.58.44A3.38,3.38,0,0,1,298.57,277.83Zm-.17-8a2.34,2.34,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.07v-.11a3,3,0,0,0-.69-2A2.16,2.16,0,0,0,298.4,269.86Z" />
              <path d="M303.19,273.37a5,5,0,0,1,.88-3.13,2.82,2.82,0,0,1,2.39-1.17,2.92,2.92,0,0,1,2.6,1.35v-4.74H310v12h-.87l0-1.13a3,3,0,0,1-2.64,1.28,2.84,2.84,0,0,1-2.36-1.17,5.19,5.19,0,0,1-.89-3.18Zm.94.17a4.44,4.44,0,0,0,.65,2.55,2.11,2.11,0,0,0,1.83.93,2.51,2.51,0,0,0,2.45-1.52v-4a2.48,2.48,0,0,0-2.43-1.65,2.13,2.13,0,0,0-1.84.93A4.66,4.66,0,0,0,304.13,273.54Z" />
              <path d="M317.52,275.53a1.33,1.33,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.4,7.4,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.94,3.94,0,0,1,4.41,0,2.23,2.23,0,0,1,.86,1.82h-.94a1.52,1.52,0,0,0-.62-1.23,2.44,2.44,0,0,0-1.57-.5,2.47,2.47,0,0,0-1.48.41,1.28,1.28,0,0,0-.56,1.07,1.16,1.16,0,0,0,.47,1,4.85,4.85,0,0,0,1.69.64,7.86,7.86,0,0,1,1.85.62,2.37,2.37,0,0,1,.91.78,2.16,2.16,0,0,1-.57,2.83,3.66,3.66,0,0,1-2.25.64,3.79,3.79,0,0,1-2.4-.71,2.2,2.2,0,0,1-.91-1.81H313a1.66,1.66,0,0,0,.69,1.28,2.89,2.89,0,0,0,1.69.45,2.66,2.66,0,0,0,1.58-.43A1.27,1.27,0,0,0,317.52,275.53Z" />
              <path d="M320.47,277.11a.65.65,0,0,1,.18-.46.6.6,0,0,1,.48-.19.63.63,0,0,1,.68.65.58.58,0,0,1-.18.45.68.68,0,0,1-.5.18.63.63,0,0,1-.48-.18A.58.58,0,0,1,320.47,277.11Z" />
              <path d="M336.89,272.61a6.58,6.58,0,0,1-.54,2.75,4.06,4.06,0,0,1-1.55,1.83,4.22,4.22,0,0,1-2.33.64,4,4,0,0,1-3.22-1.42,5.7,5.7,0,0,1-1.22-3.85v-1.17a6.51,6.51,0,0,1,.55-2.76,4.14,4.14,0,0,1,1.55-1.84,4.56,4.56,0,0,1,4.64,0,4.12,4.12,0,0,1,1.55,1.79,6.25,6.25,0,0,1,.57,2.68Zm-1-1.24a5,5,0,0,0-.93-3.22,3.34,3.34,0,0,0-5.07,0,5,5,0,0,0-.94,3.26v1.19a5,5,0,0,0,.93,3.2,3.09,3.09,0,0,0,2.55,1.19,3.05,3.05,0,0,0,2.55-1.17,5.15,5.15,0,0,0,.91-3.25Z" />
              <path d="M340,269.22l0,1.45a3.23,3.23,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.53,2.53,0,0,1,2,.76,3.45,3.45,0,0,1,.67,2.3v5.55h-.93v-5.56a2.51,2.51,0,0,0-.48-1.68,1.84,1.84,0,0,0-1.51-.56,2.39,2.39,0,0,0-1.54.54,3.21,3.21,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M348.93,277.68H348v-12h.94Z" />
              <path d="M354.15,276.33l2.47-7.11h1L354,279.11l-.19.44a2.26,2.26,0,0,1-2.15,1.54A2.8,2.8,0,0,1,351,281v-.77l.48.05a1.75,1.75,0,0,0,1.11-.34,2.59,2.59,0,0,0,.73-1.17l.41-1.14-3.19-8.39h1Z" />
              <path d="M366.87,270a3.22,3.22,0,0,0-.63,0,2.29,2.29,0,0,0-1.48.48,2.65,2.65,0,0,0-.87,1.42v5.81H363v-8.46h.92v1.35a2.49,2.49,0,0,1,2.39-1.5,1.46,1.46,0,0,1,.61.1Z" />
              <path d="M371.47,277.83a3.6,3.6,0,0,1-3.3-2,4.52,4.52,0,0,1-.49-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.56,3.56,0,0,0,.83,2.4,2.59,2.59,0,0,0,2.07,1,3.05,3.05,0,0,0,1.32-.27,2.93,2.93,0,0,0,1.05-.87l.58.44A3.38,3.38,0,0,1,371.47,277.83Zm-.17-8a2.34,2.34,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.07v-.11a3,3,0,0,0-.69-2A2.16,2.16,0,0,0,371.3,269.86Z" />
              <path d="M379.59,277a2.44,2.44,0,0,0,1.63-.55,1.94,1.94,0,0,0,.72-1.42h.9a2.69,2.69,0,0,1-.49,1.41,3,3,0,0,1-1.18,1,3.55,3.55,0,0,1-1.58.36,3.27,3.27,0,0,1-2.64-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.13,5.13,0,0,1,.44-2.21,3.34,3.34,0,0,1,1.25-1.49,3.44,3.44,0,0,1,1.91-.52,3.21,3.21,0,0,1,2.29.83,3,3,0,0,1,1,2.18h-.9a2.29,2.29,0,0,0-.72-1.61,2.53,2.53,0,0,0-3.6.31,4.1,4.1,0,0,0-.7,2.56v.27a4,4,0,0,0,.7,2.53A2.34,2.34,0,0,0,379.59,277Z" />
              <path d="M384.19,273.33a5,5,0,0,1,.47-2.2,3.55,3.55,0,0,1,3.3-2.06,3.47,3.47,0,0,1,2.73,1.18,4.57,4.57,0,0,1,1.05,3.14v.19a5,5,0,0,1-.48,2.21,3.5,3.5,0,0,1-1.33,1.51,3.58,3.58,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.57,4.57,0,0,1-1.05-3.14Zm.94.25a3.86,3.86,0,0,0,.78,2.49,2.66,2.66,0,0,0,4.11,0,4,4,0,0,0,.79-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.54,2.54,0,0,0-1.48-.45,2.47,2.47,0,0,0-2,1,3.92,3.92,0,0,0-.79,2.56Z" />
              <path d="M394.52,269.22l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.36,2.36,0,0,1,2.54,1.64,3.22,3.22,0,0,1,1.21-1.22,3.43,3.43,0,0,1,1.68-.42c1.81,0,2.73,1,2.77,3v5.64h-.94v-5.57a2.48,2.48,0,0,0-.49-1.68,2,2,0,0,0-1.56-.55,2.52,2.52,0,0,0-1.71.65,2.32,2.32,0,0,0-.77,1.55v5.6h-.94V272a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.42,2.42,0,0,0-1.52.5,3,3,0,0,0-.93,1.5v5.8h-.94v-8.46Z" />
              <path d="M408.7,269.22l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.36,2.36,0,0,1,2.54,1.64,3.22,3.22,0,0,1,1.21-1.22,3.42,3.42,0,0,1,1.67-.42c1.82,0,2.74,1,2.78,3v5.64h-.94v-5.57a2.53,2.53,0,0,0-.49-1.68,2,2,0,0,0-1.56-.55,2.52,2.52,0,0,0-1.71.65,2.32,2.32,0,0,0-.77,1.55v5.6h-.94V272a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.42,2.42,0,0,0-1.52.5,3,3,0,0,0-.93,1.5v5.8h-.94v-8.46Z" />
              <path d="M425.3,277.83a3.61,3.61,0,0,1-1.95-.53,3.67,3.67,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08,1,3.05,3.05,0,0,0,1.32-.27,3,3,0,0,0,1-.87l.58.44A3.39,3.39,0,0,1,425.3,277.83Zm-.17-8a2.32,2.32,0,0,0-1.78.78,3.55,3.55,0,0,0-.88,2.08h5.07v-.11a3.16,3.16,0,0,0-.69-2A2.18,2.18,0,0,0,425.13,269.86Z" />
              <path d="M431.18,269.22l0,1.45a3.39,3.39,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.52,2.52,0,0,1,2,.76,3.45,3.45,0,0,1,.68,2.3v5.55h-.93v-5.56a2.63,2.63,0,0,0-.48-1.68,1.87,1.87,0,0,0-1.52-.56,2.36,2.36,0,0,0-1.53.54,3.15,3.15,0,0,0-1,1.47v5.79h-.93v-8.46Z" />
              <path d="M438.71,273.37a5.1,5.1,0,0,1,.87-3.13,2.83,2.83,0,0,1,2.39-1.17,2.94,2.94,0,0,1,2.61,1.35v-4.74h.93v12h-.88l0-1.13a3,3,0,0,1-2.64,1.28,2.83,2.83,0,0,1-2.36-1.17,5.13,5.13,0,0,1-.89-3.18Zm.94.17a4.44,4.44,0,0,0,.65,2.55,2.1,2.1,0,0,0,1.83.93,2.51,2.51,0,0,0,2.45-1.52v-4a2.5,2.5,0,0,0-2.44-1.65,2.11,2.11,0,0,0-1.83.93A4.66,4.66,0,0,0,439.65,273.54Z" />
              <path d="M267.52,294a3.26,3.26,0,0,0-.64,0,2.33,2.33,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.92v1.35a2.49,2.49,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M272.12,301.83a3.61,3.61,0,0,1-1.95-.53,3.67,3.67,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08,1,3.05,3.05,0,0,0,1.32-.27,2.93,2.93,0,0,0,1-.87l.58.44A3.39,3.39,0,0,1,272.12,301.83Zm-.17-8a2.32,2.32,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.07v-.11a3.16,3.16,0,0,0-.69-2A2.16,2.16,0,0,0,272,293.86Z" />
              <path d="M278.16,301.68h-.94v-12h.94Z" />
              <path d="M284,301.83a3.6,3.6,0,0,1-3.3-2,4.52,4.52,0,0,1-.49-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.56,3.56,0,0,0,.83,2.4,2.59,2.59,0,0,0,2.07,1,3.05,3.05,0,0,0,1.32-.27,2.93,2.93,0,0,0,1.05-.87l.58.44A3.38,3.38,0,0,1,284,301.83Zm-.17-8a2.34,2.34,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.07v-.11a3,3,0,0,0-.69-2A2.16,2.16,0,0,0,283.8,293.86Z" />
              <path d="M291.49,300.39l2.57-7.17h1l-3.16,8.46h-.76l-3.17-8.46h1Z" />
              <path d="M301.78,301.68a4,4,0,0,1-.18-1.18,3.43,3.43,0,0,1-1.26,1,3.85,3.85,0,0,1-1.61.34,2.8,2.8,0,0,1-2-.68,2.18,2.18,0,0,1-.76-1.71,2.26,2.26,0,0,1,1-2,4.92,4.92,0,0,1,2.86-.72h1.7v-1a1.84,1.84,0,0,0-.56-1.42,2.3,2.3,0,0,0-1.63-.52,2.53,2.53,0,0,0-1.62.5,1.49,1.49,0,0,0-.64,1.2h-.93a2.17,2.17,0,0,1,.93-1.74,3.59,3.59,0,0,1,2.31-.74,3.31,3.31,0,0,1,2.23.7,2.59,2.59,0,0,1,.84,2v4a4.76,4.76,0,0,0,.26,1.83v.1Zm-2.94-.67a3.15,3.15,0,0,0,1.67-.46,2.63,2.63,0,0,0,1.08-1.21v-1.86h-1.67a4.17,4.17,0,0,0-2.19.51,1.58,1.58,0,0,0-.26,2.55A2,2,0,0,0,298.84,301Z" />
              <path d="M305.9,293.22l0,1.45a3.39,3.39,0,0,1,1.19-1.19,3,3,0,0,1,1.57-.41,2.52,2.52,0,0,1,2,.76,3.4,3.4,0,0,1,.67,2.3v5.55h-.92v-5.56a2.57,2.57,0,0,0-.49-1.68,1.83,1.83,0,0,0-1.51-.56,2.35,2.35,0,0,0-1.53.54,3.15,3.15,0,0,0-1,1.47v5.79H305v-8.46Z" />
              <path d="M315.25,291.06v2.16H317V294h-1.75v5.64a1.76,1.76,0,0,0,.26,1.05,1,1,0,0,0,.84.34,6.29,6.29,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.44-1.64V294h-1.55v-.77h1.55v-2.16Z" />
              <path d="M329.63,297.54a5.1,5.1,0,0,1-.88,3.13,2.76,2.76,0,0,1-2.34,1.16,3.12,3.12,0,0,1-2.66-1.22v4.32h-.93V293.22h.87l.05,1.2a3,3,0,0,1,2.64-1.35,2.79,2.79,0,0,1,2.38,1.15,5.14,5.14,0,0,1,.87,3.18Zm-.94-.17a4.34,4.34,0,0,0-.66-2.55,2.15,2.15,0,0,0-1.86-.94,2.61,2.61,0,0,0-1.47.41,2.65,2.65,0,0,0-.95,1.21v4.05a2.5,2.5,0,0,0,1,1.11,2.74,2.74,0,0,0,1.48.38,2.11,2.11,0,0,0,1.84-.94A4.72,4.72,0,0,0,328.69,297.37Z" />
              <path d="M335.6,294a3.16,3.16,0,0,0-.63,0,2.31,2.31,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46h.91l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M336.41,297.33a5,5,0,0,1,.47-2.2,3.61,3.61,0,0,1,1.34-1.52,3.57,3.57,0,0,1,1.95-.54,3.48,3.48,0,0,1,2.74,1.18,4.57,4.57,0,0,1,1,3.14v.19a5,5,0,0,1-.48,2.21,3.5,3.5,0,0,1-1.33,1.51,3.6,3.6,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.57,4.57,0,0,1-1-3.14Zm.94.25a3.86,3.86,0,0,0,.78,2.49,2.66,2.66,0,0,0,4.11,0,4,4,0,0,0,.79-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.55,2.55,0,0,0-1.49-.45,2.5,2.5,0,0,0-2,1,4,4,0,0,0-.78,2.56Z" />
              <path d="M345.51,297.37a5,5,0,0,1,.88-3.13,2.82,2.82,0,0,1,2.39-1.17,2.92,2.92,0,0,1,2.6,1.35v-4.74h.93v12h-.87l0-1.13a3,3,0,0,1-2.64,1.28,2.84,2.84,0,0,1-2.36-1.17,5.19,5.19,0,0,1-.89-3.18Zm.95.17a4.44,4.44,0,0,0,.64,2.55,2.12,2.12,0,0,0,1.83.93,2.51,2.51,0,0,0,2.45-1.52v-4a2.48,2.48,0,0,0-2.43-1.65,2.13,2.13,0,0,0-1.84.93A4.66,4.66,0,0,0,346.46,297.54Z" />
              <path d="M360.21,300.61a3,3,0,0,1-2.69,1.22,2.64,2.64,0,0,1-2.06-.78,3.45,3.45,0,0,1-.72-2.33v-5.5h.93v5.39c0,1.61.65,2.41,2,2.41a2.4,2.4,0,0,0,2.57-1.68v-6.12h.93v8.46h-.9Z" />
              <path d="M366.69,301a2.46,2.46,0,0,0,1.64-.55,1.93,1.93,0,0,0,.71-1.42h.9a2.77,2.77,0,0,1-.48,1.41,3.12,3.12,0,0,1-1.19,1,3.55,3.55,0,0,1-1.58.36,3.27,3.27,0,0,1-2.64-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.44-2.21,3.41,3.41,0,0,1,1.25-1.49,3.46,3.46,0,0,1,1.91-.52,3.21,3.21,0,0,1,2.29.83,3,3,0,0,1,1,2.18H369a2.32,2.32,0,0,0-.71-1.61,2.37,2.37,0,0,0-1.65-.61,2.32,2.32,0,0,0-2,.92,4.1,4.1,0,0,0-.7,2.56v.27a4,4,0,0,0,.7,2.53A2.34,2.34,0,0,0,366.69,301Z" />
              <path d="M373.26,291.06v2.16H375V294h-1.75v5.64a1.77,1.77,0,0,0,.25,1.05,1,1,0,0,0,.85.34,6.29,6.29,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.44-1.64V294h-1.55v-.77h1.55v-2.16Z" />
              <path d="M382,299.53a1.33,1.33,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.56,7.56,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.94,3.94,0,0,1,4.41,0,2.2,2.2,0,0,1,.86,1.82H382a1.5,1.5,0,0,0-.62-1.23,2.41,2.41,0,0,0-1.56-.5,2.5,2.5,0,0,0-1.49.41,1.27,1.27,0,0,0-.55,1.07,1.15,1.15,0,0,0,.46,1,4.92,4.92,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.37,2.37,0,0,1,.91.78,2.16,2.16,0,0,1-.57,2.83,3.64,3.64,0,0,1-2.25.64,3.76,3.76,0,0,1-2.39-.71,2.19,2.19,0,0,1-.92-1.81h.94a1.6,1.6,0,0,0,.69,1.28,2.83,2.83,0,0,0,1.68.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,382,299.53Z" />
              <path d="M390.39,291.06v2.16h1.75V294h-1.75v5.64a1.76,1.76,0,0,0,.26,1.05,1,1,0,0,0,.84.34,6.29,6.29,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.43-1.64V294H387.9v-.77h1.56v-2.16Z" />
              <path d="M393.41,297.33a5,5,0,0,1,.47-2.2,3.61,3.61,0,0,1,1.34-1.52,3.57,3.57,0,0,1,1.95-.54,3.48,3.48,0,0,1,2.74,1.18,4.57,4.57,0,0,1,1,3.14v.19a5,5,0,0,1-.48,2.21,3.5,3.5,0,0,1-1.33,1.51,3.6,3.6,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.57,4.57,0,0,1-1-3.14Zm.94.25a3.86,3.86,0,0,0,.78,2.49,2.66,2.66,0,0,0,4.11,0,4,4,0,0,0,.79-2.56v-.18a4.32,4.32,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.55,2.55,0,0,0-1.49-.45,2.5,2.5,0,0,0-2,1,4,4,0,0,0-.78,2.56Z" />
              <path d="M407.63,293.22l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.27,3.27,0,0,1,1.56-.38,2.36,2.36,0,0,1,2.54,1.64,3.22,3.22,0,0,1,1.21-1.22,3.42,3.42,0,0,1,1.67-.42c1.82,0,2.74,1,2.78,3v5.64h-.94v-5.57a2.53,2.53,0,0,0-.49-1.68,2,2,0,0,0-1.56-.55,2.52,2.52,0,0,0-1.71.65,2.32,2.32,0,0,0-.77,1.55v5.6h-.94V296a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.42,2.42,0,0,0-1.52.5,3,3,0,0,0-.93,1.5v5.8h-.94v-8.46Z" />
              <path d="M424.23,301.83a3.61,3.61,0,0,1-2-.53,3.67,3.67,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08,1,3.05,3.05,0,0,0,1.32-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.39,3.39,0,0,1,424.23,301.83Zm-.17-8a2.32,2.32,0,0,0-1.78.78,3.55,3.55,0,0,0-.88,2.08h5.07v-.11a3.16,3.16,0,0,0-.69-2A2.18,2.18,0,0,0,424.06,293.86Z" />
              <path d="M429.13,301.11a.65.65,0,0,1,.18-.46.73.73,0,0,1,1,0,.65.65,0,0,1,.18.46.58.58,0,0,1-.18.45.76.76,0,0,1-1,0A.58.58,0,0,1,429.13,301.11Z" />
              <path d="M298.22,324.33a3.24,3.24,0,0,1-1.56,1.11,6.93,6.93,0,0,1-2.39.39,4.38,4.38,0,0,1-2.37-.64,4.18,4.18,0,0,1-1.61-1.81,6.08,6.08,0,0,1-.58-2.69v-1.43a5.52,5.52,0,0,1,1.19-3.74,4,4,0,0,1,3.22-1.37,4.22,4.22,0,0,1,2.82.89,3.7,3.7,0,0,1,1.28,2.47h-1a3,3,0,0,0-1-1.91,3.31,3.31,0,0,0-2.12-.64,3.1,3.1,0,0,0-2.52,1.13,4.92,4.92,0,0,0-.93,3.2v1.34a5.68,5.68,0,0,0,.44,2.3,3.53,3.53,0,0,0,1.27,1.54,3.3,3.3,0,0,0,1.89.55,5.26,5.26,0,0,0,2.13-.39,2.18,2.18,0,0,0,.86-.6v-2.94h-3.05v-.81h4Z" />
              <path d="M300.68,314.78a.6.6,0,0,1,.17-.44.58.58,0,0,1,.47-.19.6.6,0,0,1,.47.19.6.6,0,0,1,.18.44.61.61,0,0,1-.18.45.66.66,0,0,1-.47.17.64.64,0,0,1-.47-.17A.6.6,0,0,1,300.68,314.78Zm1.1,10.9h-.94v-8.46h.94Z" />
              <path d="M307,324.39l2.57-7.17h1l-3.15,8.46h-.76l-3.17-8.46h1Z" />
              <path d="M315.19,325.83a3.63,3.63,0,0,1-3.31-2,4.64,4.64,0,0,1-.48-2.13v-.33a4.9,4.9,0,0,1,.47-2.2,3.65,3.65,0,0,1,1.32-1.53,3.17,3.17,0,0,1,1.82-.56,3.08,3.08,0,0,1,2.45,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.07,1,3.1,3.1,0,0,0,1.33-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.4,3.4,0,0,1,315.19,325.83Zm-.18-8a2.31,2.31,0,0,0-1.77.78,3.49,3.49,0,0,0-.88,2.08h5.07v-.11a3.11,3.11,0,0,0-.7-2A2.16,2.16,0,0,0,315,317.86Z" />
              <path d="M324.92,317.22l0,1.38a3.14,3.14,0,0,1,1.17-1.15,3.28,3.28,0,0,1,1.57-.38,2.35,2.35,0,0,1,2.53,1.64,3.25,3.25,0,0,1,1.22-1.22,3.37,3.37,0,0,1,1.67-.42c1.81,0,2.73,1,2.77,3v5.64h-.94v-5.57a2.48,2.48,0,0,0-.48-1.68,2,2,0,0,0-1.57-.55,2.5,2.5,0,0,0-1.7.65,2.29,2.29,0,0,0-.78,1.55v5.6h-.94V320a2.25,2.25,0,0,0-.51-1.62,2,2,0,0,0-1.55-.54,2.37,2.37,0,0,0-1.51.5,2.92,2.92,0,0,0-.94,1.5v5.8H324v-8.46Z" />
              <path d="M341.52,325.83a3.6,3.6,0,0,1-3.3-2,4.52,4.52,0,0,1-.49-2.13v-.33a4.9,4.9,0,0,1,.48-2.2,3.7,3.7,0,0,1,1.31-1.53,3.18,3.18,0,0,1,1.83-.56,3,3,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.56,3.56,0,0,0,.83,2.4,2.61,2.61,0,0,0,2.07,1,3.05,3.05,0,0,0,1.32-.27,2.85,2.85,0,0,0,1.05-.87l.58.44A3.38,3.38,0,0,1,341.52,325.83Zm-.17-8a2.34,2.34,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.07v-.11a3,3,0,0,0-.69-2A2.16,2.16,0,0,0,341.35,317.86Z" />
              <path d="M351.86,315.06v2.16h1.75V318h-1.75v5.64a1.77,1.77,0,0,0,.25,1.05,1,1,0,0,0,.85.34,6.29,6.29,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.44-1.64V318h-1.55v-.77h1.55v-2.16Z" />
              <path d="M356.47,318.65a3.39,3.39,0,0,1,1.18-1.17,3.17,3.17,0,0,1,1.57-.41,2.55,2.55,0,0,1,2,.76,3.5,3.5,0,0,1,.67,2.3v5.55H361v-5.56a2.63,2.63,0,0,0-.48-1.68,1.86,1.86,0,0,0-1.51-.56,2.39,2.39,0,0,0-1.54.54,3.31,3.31,0,0,0-1,1.47v5.79h-.93v-12h.93Z" />
              <path d="M367.6,325.83a3.61,3.61,0,0,1-2-.53,3.67,3.67,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.08,1,3.05,3.05,0,0,0,1.32-.27,2.93,2.93,0,0,0,1.05-.87l.58.44A3.39,3.39,0,0,1,367.6,325.83Zm-.17-8a2.32,2.32,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.07v-.11a3.1,3.1,0,0,0-.69-2A2.16,2.16,0,0,0,367.43,317.86Z" />
              <path d="M383.29,321.54a5,5,0,0,1-.88,3.13,2.78,2.78,0,0,1-2.35,1.16,3,3,0,0,1-2.67-1.31l0,1.16h-.88v-12h.93v4.77a3,3,0,0,1,2.64-1.38,2.85,2.85,0,0,1,2.38,1.14,5.19,5.19,0,0,1,.87,3.19Zm-.94-.17a4.43,4.43,0,0,0-.65-2.58,2.1,2.1,0,0,0-1.83-.91,2.6,2.6,0,0,0-1.52.44,2.77,2.77,0,0,0-1,1.29v3.82a2.51,2.51,0,0,0,2.49,1.59,2.1,2.1,0,0,0,1.81-.91A4.75,4.75,0,0,0,382.35,321.37Z" />
              <path d="M388.62,325.83a3.6,3.6,0,0,1-3.3-2,4.64,4.64,0,0,1-.49-2.13v-.33a4.9,4.9,0,0,1,.48-2.2,3.7,3.7,0,0,1,1.31-1.53,3.21,3.21,0,0,1,1.83-.56,3,3,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.56,3.56,0,0,0,.83,2.4,2.61,2.61,0,0,0,2.07,1,3.13,3.13,0,0,0,1.33-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.41,3.41,0,0,1,388.62,325.83Zm-.17-8a2.35,2.35,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.08v-.11a3.11,3.11,0,0,0-.7-2A2.16,2.16,0,0,0,388.45,317.86Z" />
              <path d="M398.68,323.53a1.33,1.33,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.4,7.4,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.94,3.94,0,0,1,4.41,0,2.23,2.23,0,0,1,.86,1.82h-.94a1.52,1.52,0,0,0-.62-1.23,2.44,2.44,0,0,0-1.57-.5,2.47,2.47,0,0,0-1.48.41,1.28,1.28,0,0,0-.56,1.07,1.16,1.16,0,0,0,.47,1,4.85,4.85,0,0,0,1.69.64,7.86,7.86,0,0,1,1.85.62,2.37,2.37,0,0,1,.91.78,2.16,2.16,0,0,1-.57,2.83,3.66,3.66,0,0,1-2.25.64,3.77,3.77,0,0,1-2.4-.71,2.2,2.2,0,0,1-.91-1.81h.93a1.66,1.66,0,0,0,.69,1.28,2.89,2.89,0,0,0,1.69.45,2.66,2.66,0,0,0,1.58-.43A1.27,1.27,0,0,0,398.68,323.53Z" />
              <path d="M403.17,315.06v2.16h1.75V318h-1.75v5.64a1.77,1.77,0,0,0,.25,1.05,1,1,0,0,0,.85.34,6.29,6.29,0,0,0,.76-.08l0,.76a3,3,0,0,1-1,.13,1.65,1.65,0,0,1-1.4-.56,2.63,2.63,0,0,1-.44-1.64V318h-1.55v-.77h1.55v-2.16Z" />
              <path d="M308.78,345.54a5.1,5.1,0,0,1-.87,3.13,2.79,2.79,0,0,1-2.35,1.16,3.1,3.1,0,0,1-2.65-1.22v4.32H302V341.22h.87l0,1.2a3,3,0,0,1,2.65-1.35,2.81,2.81,0,0,1,2.38,1.15,5.14,5.14,0,0,1,.86,3.18Zm-.93-.17a4.34,4.34,0,0,0-.67-2.55,2.13,2.13,0,0,0-1.85-.94,2.44,2.44,0,0,0-2.42,1.62v4.05a2.39,2.39,0,0,0,1,1.11,2.74,2.74,0,0,0,1.48.38,2.12,2.12,0,0,0,1.84-.94A4.72,4.72,0,0,0,307.85,345.37Z" />
              <path d="M310.34,345.33a5,5,0,0,1,.47-2.2,3.61,3.61,0,0,1,1.34-1.52,3.57,3.57,0,0,1,2-.54,3.48,3.48,0,0,1,2.74,1.18,4.56,4.56,0,0,1,1,3.14v.19a4.94,4.94,0,0,1-.47,2.21,3.5,3.5,0,0,1-1.33,1.51,3.6,3.6,0,0,1-2,.53,3.47,3.47,0,0,1-2.73-1.18,4.57,4.57,0,0,1-1.05-3.14Zm.94.25a3.86,3.86,0,0,0,.78,2.49,2.66,2.66,0,0,0,4.11,0,4,4,0,0,0,.79-2.56v-.18a4.49,4.49,0,0,0-.36-1.78,2.91,2.91,0,0,0-1-1.24,2.55,2.55,0,0,0-1.49-.45,2.5,2.5,0,0,0-2,1,4,4,0,0,0-.78,2.56Z" />
              <path d="M324.88,347.53a1.33,1.33,0,0,0-.56-1.12,4.36,4.36,0,0,0-1.69-.66,7.3,7.3,0,0,1-1.74-.55,2.2,2.2,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.41,3.41,0,0,1,2.14-.65,3.46,3.46,0,0,1,2.27.7,2.23,2.23,0,0,1,.86,1.82h-.94a1.52,1.52,0,0,0-.62-1.23,2.79,2.79,0,0,0-3-.09,1.28,1.28,0,0,0-.56,1.07,1.16,1.16,0,0,0,.47,1,4.85,4.85,0,0,0,1.69.64,7.86,7.86,0,0,1,1.85.62,2.37,2.37,0,0,1,.91.78,2.19,2.19,0,0,1-.57,2.83,3.66,3.66,0,0,1-2.25.64,3.77,3.77,0,0,1-2.4-.71,2.21,2.21,0,0,1-.92-1.81h.94a1.66,1.66,0,0,0,.69,1.28,2.89,2.89,0,0,0,1.69.45,2.66,2.66,0,0,0,1.58-.43A1.27,1.27,0,0,0,324.88,347.53Z" />
              <path d="M333,347.53a1.33,1.33,0,0,0-.56-1.12,4.31,4.31,0,0,0-1.68-.66,7.4,7.4,0,0,1-1.75-.55,2.27,2.27,0,0,1-.92-.75,1.9,1.9,0,0,1-.3-1.08,2,2,0,0,1,.83-1.65,3.94,3.94,0,0,1,4.41,0,2.23,2.23,0,0,1,.86,1.82h-.94a1.52,1.52,0,0,0-.62-1.23,2.44,2.44,0,0,0-1.57-.5,2.49,2.49,0,0,0-1.48.41,1.27,1.27,0,0,0-.55,1.07,1.15,1.15,0,0,0,.46,1,4.84,4.84,0,0,0,1.7.64,7.75,7.75,0,0,1,1.84.62,2.37,2.37,0,0,1,.91.78,2.16,2.16,0,0,1-.57,2.83,3.64,3.64,0,0,1-2.25.64,3.79,3.79,0,0,1-2.4-.71,2.2,2.2,0,0,1-.91-1.81h.93a1.64,1.64,0,0,0,.7,1.28,2.83,2.83,0,0,0,1.68.45,2.64,2.64,0,0,0,1.58-.43A1.27,1.27,0,0,0,333,347.53Z" />
              <path d="M336,338.78a.64.64,0,0,1,.17-.44.61.61,0,0,1,.47-.19.62.62,0,0,1,.48.19.64.64,0,0,1,.17.44.64.64,0,0,1-.17.45.69.69,0,0,1-.48.17.68.68,0,0,1-.47-.17A.64.64,0,0,1,336,338.78Zm1.1,10.9h-.93v-8.46h.93Z" />
              <path d="M346.41,345.54a5,5,0,0,1-.88,3.13,2.76,2.76,0,0,1-2.35,1.16,3,3,0,0,1-2.67-1.31l0,1.16h-.87v-12h.93v4.77a2.93,2.93,0,0,1,2.64-1.38,2.82,2.82,0,0,1,2.37,1.14,5.19,5.19,0,0,1,.87,3.19Zm-.94-.17a4.43,4.43,0,0,0-.65-2.58,2.1,2.1,0,0,0-1.83-.91,2.62,2.62,0,0,0-1.52.44,2.69,2.69,0,0,0-.94,1.29v3.82A2.49,2.49,0,0,0,343,349a2.1,2.1,0,0,0,1.81-.91A4.75,4.75,0,0,0,345.47,345.37Z" />
              <path d="M349.52,349.68h-.94v-12h.94Z" />
              <path d="M355.33,349.83a3.6,3.6,0,0,1-3.3-2,4.52,4.52,0,0,1-.49-2.13v-.33a5,5,0,0,1,.47-2.2,3.8,3.8,0,0,1,1.32-1.53,3.18,3.18,0,0,1,1.83-.56,3.06,3.06,0,0,1,2.44,1.05,4.27,4.27,0,0,1,.9,2.87v.52h-6v.18a3.6,3.6,0,0,0,.82,2.4,2.63,2.63,0,0,0,2.08,1,3.05,3.05,0,0,0,1.32-.27,2.93,2.93,0,0,0,1.05-.87l.58.44A3.39,3.39,0,0,1,355.33,349.83Zm-.17-8a2.34,2.34,0,0,0-1.78.78,3.61,3.61,0,0,0-.88,2.08h5.07v-.11a3.1,3.1,0,0,0-.69-2A2.16,2.16,0,0,0,355.16,341.86Z" />
              <path d="M371,345.54a5,5,0,0,1-.88,3.13,2.76,2.76,0,0,1-2.34,1.16,3.12,3.12,0,0,1-2.66-1.22v4.32h-.92V341.22h.86l.05,1.2a3,3,0,0,1,2.65-1.35,2.81,2.81,0,0,1,2.38,1.15,5.2,5.2,0,0,1,.86,3.18Zm-.94-.17a4.34,4.34,0,0,0-.66-2.55,2.14,2.14,0,0,0-1.85-.94,2.46,2.46,0,0,0-2.43,1.62v4.05a2.46,2.46,0,0,0,1,1.11,2.68,2.68,0,0,0,1.47.38,2.11,2.11,0,0,0,1.84-.94A4.72,4.72,0,0,0,370.07,345.37Z" />
              <path d="M377,342a3.26,3.26,0,0,0-.64,0,2.33,2.33,0,0,0-1.48.48,2.7,2.7,0,0,0-.86,1.42v5.81h-.93v-8.46H374l0,1.35a2.48,2.48,0,0,1,2.38-1.5,1.51,1.51,0,0,1,.62.1Z" />
              <path d="M378.41,338.78a.6.6,0,0,1,.17-.44.58.58,0,0,1,.47-.19.6.6,0,0,1,.47.19.6.6,0,0,1,.18.44.61.61,0,0,1-.18.45.66.66,0,0,1-.47.17.64.64,0,0,1-.47-.17A.6.6,0,0,1,378.41,338.78Zm1.1,10.9h-.94v-8.46h.94Z" />
              <path d="M385.17,349a2.46,2.46,0,0,0,1.64-.55,1.94,1.94,0,0,0,.72-1.42h.89a2.6,2.6,0,0,1-.48,1.41,3.09,3.09,0,0,1-1.18,1,3.6,3.6,0,0,1-1.59.36,3.25,3.25,0,0,1-2.63-1.15,4.67,4.67,0,0,1-1-3.11v-.28a5.28,5.28,0,0,1,.44-2.21,3.36,3.36,0,0,1,1.26-1.49,3.44,3.44,0,0,1,1.91-.52,3.23,3.23,0,0,1,2.29.83,3,3,0,0,1,1,2.18h-.89a2.43,2.43,0,0,0-4.33-1.3,4.1,4.1,0,0,0-.7,2.56v.27a4,4,0,0,0,.7,2.53A2.35,2.35,0,0,0,385.17,349Z" />
              <path d="M393.56,349.83a3.6,3.6,0,0,1-1.94-.53,3.54,3.54,0,0,1-1.36-1.48,4.64,4.64,0,0,1-.48-2.13v-.33a4.9,4.9,0,0,1,.47-2.2,3.7,3.7,0,0,1,1.31-1.53,3.23,3.23,0,0,1,1.83-.56,3,3,0,0,1,2.44,1.05,4.22,4.22,0,0,1,.91,2.87v.52h-6v.18a3.55,3.55,0,0,0,.82,2.4,2.61,2.61,0,0,0,2.07,1,3.13,3.13,0,0,0,1.33-.27,2.9,2.9,0,0,0,1-.87l.59.44A3.41,3.41,0,0,1,393.56,349.83Zm-.17-8a2.31,2.31,0,0,0-1.77.78,3.49,3.49,0,0,0-.88,2.08h5.07v-.11a3.11,3.11,0,0,0-.7-2A2.16,2.16,0,0,0,393.39,341.86Z" />
            </g>
          </HoverCircle>
        </SVG>
      </InteractionContainer>
    )
  }
}
